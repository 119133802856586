import * as i0 from "@angular/core";
var ScrollService = /** @class */ (function () {
    function ScrollService() {
    }
    ScrollService.prototype.goToSection = function (sectionName) {
        var position = $('#' + sectionName).offset().top;
        $('body, html').animate({
            scrollTop: position
        } /* speed */);
    };
    ScrollService.prototype.scrollToTop = function () {
        $('body, html').animate({
            scrollTop: 0
        } /* speed */);
    };
    ScrollService.ngInjectableDef = i0.defineInjectable({ factory: function ScrollService_Factory() { return new ScrollService(); }, token: ScrollService, providedIn: "root" });
    return ScrollService;
}());
export { ScrollService };

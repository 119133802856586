<ng-template #resetPassTemplate>
  <div class="modal-header">
    <h5 class="modal-title">Cambiar contraseña</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12 form-content">
          <form [formGroup]="formPassword" id="formPassword" accept-charset="UTF-8" novalidate>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="newPassword">Nueva contraseña *</label>
                  <input type="password" id="newPassword" formControlName="password" class="form-control"
                    required="required" [ngClass]="{ 'is-invalid': passFormIsInvalid('password')}" autocomplete="off" />

                  <div class="error-message" *ngIf="passFormHasError('required', 'password')">
                    Debe ingresar una nueva contraseña!
                  </div>
                  <div class="error-message"
                    *ngIf="!passFormHasError('required', 'password') && passFormHasError('minlength', 'password')">
                    Las contraseñas debe tener al menos 6 caracteres!
                  </div>
                </div>
              </div>
            </div><!-- Nueva contraseña -->

            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label for="repeatPassword">Repetir contraseña *</label>
                  <input type="password" id="repeatPassword" formControlName="passwordConfirm" class="form-control"
                    required="required" [ngClass]="{ 'is-invalid': passFormIsInvalid('passwordConfirm')}"
                    autocomplete="off" />

                  <div class="error-message" *ngIf="passFormHasError('required', 'passwordConfirm')">
                    Debe confirmar la nueva contraseña!
                  </div>
                  <div class="error-message"
                    *ngIf="!passFormHasError('required', 'passwordConfirm') && passFormHasError('passwordsNotMatching', 'passwordConfirm')">
                    Las contraseñas no coinciden!
                  </div>
                </div>
              </div>
            </div><!-- Contraseña actual -->
          </form>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-right">
          <a class="btn btn-primary" data-toggle="collapse" href="#collapseTwo" role="button" aria-expanded="false"
            aria-controls="Actualizar" (click)="changePassword()">
            Actualizar
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="modal-footer">
    <button class="btn btn-secondary" (click)="bsModalRef.hide()">Aceptar</button>
  </div> -->
</ng-template>

<ng-template #centrosTemplate>
  <div class="modal-header">
    <h5 class="modal-title">Centros XPres</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-lista-centros></app-lista-centros>
  </div>
</ng-template>

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./lista-centros.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./lista-centros.component";
import * as i3 from "@angular/forms";
import * as i4 from "../service/pickup-centers.service";
import * as i5 from "../../../../shared/services/responsive.service";
import * as i6 from "../../../../shared/services/notification.service";
import * as i7 from "../../../envio/envio.service";
var styles_ListaCentrosComponent = [i0.styles];
var RenderType_ListaCentrosComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ListaCentrosComponent, data: {} });
export { RenderType_ListaCentrosComponent as RenderType_ListaCentrosComponent };
export function View_ListaCentrosComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_ListaCentrosComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-lista-centros", [], null, null, null, View_ListaCentrosComponent_0, RenderType_ListaCentrosComponent)), i1.ɵdid(1, 245760, null, 0, i2.ListaCentrosComponent, [i3.FormBuilder, i4.PickupCentersService, i5.ResponsiveService, i6.NotificationService, i7.EnvioService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ListaCentrosComponentNgFactory = i1.ɵccf("app-lista-centros", i2.ListaCentrosComponent, View_ListaCentrosComponent_Host_0, {}, {}, []);
export { ListaCentrosComponentNgFactory as ListaCentrosComponentNgFactory };

import isUUID from 'is-uuid';
var XpresUtils = /** @class */ (function () {
    function XpresUtils() {
    }
    XpresUtils.isPrivateIP = function (ip) {
        var parts = ip.split('.');
        var re = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        if (!re.test(ip)) {
            return false;
        }
        return parts[0] === '10' ||
            (parts[0] === '172' && (parseInt(parts[1], 10) >= 16 && parseInt(parts[1], 10) <= 31)) ||
            (parts[0] === '192' && parts[1] === '168');
    };
    XpresUtils.isValidUuid = function (uuid) {
        return isUUID['anyNonNil'](uuid);
    };
    XpresUtils.generateGUID = function () {
        function S4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return S4() + S4();
    };
    return XpresUtils;
}());
export default XpresUtils;

<ng-template #usuarioTemplate>
  <div class="modal-header">
    <h5 class="modal-title">Usuario</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-profile class="w-100"></app-profile>
  </div>
  <!-- <div class="modal-footer">
    <button class="btn btn-secondary" (click)="bsModalRef.hide()">Aceptar</button>
  </div> -->
</ng-template>

export interface IShipment {
  sender: {
    name: string;
    email: string;
    phoneNumber: string;
    state: {
      id: string;
      description?: string;
    } | null;
    city: {
      id: string;
      description?: string;
    } | null;
  };
  recipient: {
    name: string;
    phoneNumber?: string;
    email: string;
    state: {
      id: string;
      description?: string;
    } | null;
    city: {
      id: string;
      description?: string;
    } | null;
    pickupCenter: {
      id: string;
      description?: string
    } | null;
    street?: string;
    doorNumber?: string;
    otherInfo?: string;
    comments?: string;
    saveCurrentAddress?: boolean;
    saveAsName?: string;
  };
  shipmentDetails: {
    type: {
      id: string;
      description: string;
      nombre?: string;
    };
    reference?: string;
    service?: {
      id: string;
      description?: string;
      nombre?: string;
    } | null;
    category: {
      id: string;
      description?: string;
      nombre?: string;
    } | null;
    comments: string;
  };
  payment?: {
    name?: string;
    surname?: string;
    email?: string;
    amount?: number;
    couponCode?: string;
    //TODO pongo lo del rut aca
    rut?: string;
  };
}

export class Shipment implements IShipment {
  sender;
  recipient;
  shipmentDetails;
  payment;

  constructor(envio = { sender: null, recipient: null, shipmentDetails: null, payment: null }) {
    this.sender = {
      name: envio.sender ? envio.sender.name : null,
      email: envio.sender ? envio.sender.email : null,
      phoneNumber: envio.sender ? envio.sender.phoneNumber : null,
      state: envio.sender ? envio.sender.state : null,
      city: envio.sender ? envio.sender.city : null,
    };

    this.recipient = {
      name: envio.recipient ? envio.recipient.name : null,
      phoneNumber: envio.recipient ? envio.recipient.phoneNumber : null,
      email: envio.recipient ? envio.recipient.email : '',
      state: envio.recipient ? envio.recipient.state : null,
      city: envio.recipient ? envio.recipient.city : null,
      pickupCenter: envio.recipient ? envio.recipient.pickupCenter : null,
      street: envio.recipient ? envio.recipient.street : null,
      doorNumber: envio.recipient ? envio.recipient.doorNumber : null,
      otherInfo: envio.recipient ? envio.recipient.otherInfo : null,
      comments: envio.recipient ? envio.recipient.comments : null,
      saveCurrentAddress: envio.recipient ? envio.recipient.saveCurrentAddress : false,
      saveAsName: envio.recipient ? envio.recipient.saveAsName : null
    };

    this.shipmentDetails = {
      type: envio.shipmentDetails ? envio.shipmentDetails.type : null,
      reference: envio.shipmentDetails ? envio.shipmentDetails.reference : null,
      service: envio.shipmentDetails ? envio.shipmentDetails.service : null,
      category: envio.shipmentDetails ? envio.shipmentDetails.category : null,
      comments: envio.shipmentDetails ? envio.shipmentDetails.comments : null
    };

    this.payment = {
      name: envio.payment ? envio.payment.name : null,
      surname: envio.payment ? envio.payment.surname : null,
      email: envio.payment ? envio.payment.email : null,
      amount: envio.payment ? envio.payment.amount : 0
      //TODO ver si ponerlo en el constructor
    };
  }
}

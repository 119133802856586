<div class="row py-5">
  <div class="col-12 mb-5">
      <h3 class="title text-center text-uppercase text-primary font-weight-bold mb-4">CONTACTO</h3>
      <p class="text-center mb-0">Estamos para ayudarte. Contactanos por cualquier consulta o sugerencia.</p>
  </div>
  <div class="col-md-8 col-lg-6 mx-auto" id="contact_form_modal">

      <form id="contact-form" (submit)="submit()" class="pl-5 pr-5 pt-3 pb-3 bg-white" [formGroup]="form">
        <div class="row form-group">
          <div class="col-md-12 mb-6 mb-md-0">
            <label class="text-black" for="fname">Motivo *</label>
            <select  id="fmotivo" class="form-control" formControlName="motivo">
              <option value="Información Comercial">Información Comercial</option>
              <option value="Otros">Otros</option>
            </select>
            <div *ngIf="form.controls.motivo.invalid && (form.controls.motivo.dirty || form.controls.motivo.touched) ">
              <div class="error-message" *ngIf="form.controls.motivo.errors && form.controls.motivo.errors.required">Seleccione un motivo</div>
            </div>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-md-6 mb-3 mb-md-0">
            <label class="text-black" for="fname">Nombre *</label>
            <input type="text" id="fname" class="form-control" formControlName="nombre">
            <div *ngIf="form.controls.nombre.invalid && (form.controls.nombre.dirty || form.controls.nombre.touched) ">
              <div class="error-message" *ngIf="form.controls.nombre.errors && form.controls.nombre.errors.required">Ingrese su nombre</div>
            </div>
          </div>
          <div class="col-md-6">
            <label class="text-black" for="lname">Apellido *</label>
            <input type="text" id="lname" class="form-control"  formControlName="apellido">
            <div *ngIf="form.controls.apellido.invalid && (form.controls.apellido.dirty || form.controls.apellido.touched) ">
              <div class="error-message" *ngIf="form.controls.apellido.errors && form.controls.apellido.errors.required">Ingrese su apellido</div>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-md-6 mb-3 mb-md-0">
            <label class="text-black" for="email"  >Email *</label>
            <input type="email" id="email" class="form-control" formControlName="email">
            <div *ngIf="form.controls.email.invalid && (form.controls.email.dirty || form.controls.email.touched) ">
              <div class="error-message" *ngIf="form.controls.email.errors && form.controls.email.errors.required">Ingrese su email</div>
              <div class="error-message" *ngIf="form.controls.email.errors && form.controls.email.errors.email">Email no válido</div>
            </div>
          </div>
          <div class="col-md-6">
            <label class="text-black" for="telefono">Teléfono</label>
            <input type="text" id="telefono" class="form-control" formControlName="telefono">
   
          </div>
        </div>

        <div class="row form-group">
          <div class="col-md-12">
            <label class="text-black" for="subject">Asunto *</label>
            <input type="subject" id="subject" class="form-control" formControlName="asunto">
            <div *ngIf="form.controls.asunto.invalid && (form.controls.asunto.dirty || form.controls.asunto.touched) ">
              <div class="error-message" *ngIf="form.controls.asunto.errors && form.controls.asunto.errors.required">Ingrese el asunto</div>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-md-12">
            <label class="text-black" for="message">Mensaje *</label>
            <textarea name="message" id="message" cols="30" rows="4" class="form-control" formControlName="mensaje" required></textarea>
            <div *ngIf="form.controls.mensaje.invalid && (form.controls.mensaje.dirty || form.controls.mensaje.touched) ">
              <div class="error-message" *ngIf="form.controls.mensaje.errors && form.controls.mensaje.errors.required">El mensaje está vacío</div>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-md-12 text-right">
            <input type="submit" value="Enviar" class="btn btn-primary py-2 px-4 text-white" [disabled]="form.invalid">
          </div>
        </div>


      </form>
    </div>
</div>

                            <form [formGroup]="form" id="senderForm" accept-charset="UTF-8" novalidate>
                              <div class="input_search input-group input-group-lg mb-3" id="input_rastreo">
                                  <input  class="form-control border-0 tracking-input" placeholder="Número de rastreo..."
                                  aria-label="Número de rastreo..." aria-describedby="basic-addon2" id="trackingNumber" #trackingNumber
                                  formControlName="trackingNumber" autofocus>
                                  <div class="input-group-append">
                                      <button class="btn btn-primary border-0 px-4 px-md-5 " type="button" id="buscar" [disabled]="!isValid()"
                                      (click)="onButtonClick($event)">Buscar</button>
                                  </div>

                              </div>

                            </form>

<ng-template #template>
  <div class="modal-header">
    <h5 class="modal-title">Movimientos del envío: {{searchText}}</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-tracking-history [data]="searchText"></app-tracking-history>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="bsModalRef.hide()">Aceptar</button>
  </div>
</ng-template>

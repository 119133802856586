/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./localidades.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./lista-localidades/lista-localidades.component.ngfactory";
import * as i3 from "./lista-localidades/lista-localidades.component";
import * as i4 from "@angular/forms";
import * as i5 from "./service/localidades.service";
import * as i6 from "../../user/user.service";
import * as i7 from "../../../shared/services/responsive.service";
import * as i8 from "../../../shared/services/notification.service";
import * as i9 from "./localidades.component";
import * as i10 from "../../../shared/auth/auth.service";
import * as i11 from "ngx-bootstrap/modal";
var styles_LocalidadesComponent = [i0.styles];
var RenderType_LocalidadesComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LocalidadesComponent, data: {} });
export { RenderType_LocalidadesComponent as RenderType_LocalidadesComponent };
function View_LocalidadesComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Localidades"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-lista-localidades", [], null, null, null, i2.View_ListaLocalidadesComponent_0, i2.RenderType_ListaLocalidadesComponent)), i1.ɵdid(8, 245760, null, 0, i3.ListaLocalidadesComponent, [i4.FormBuilder, i5.LocalidadesService, i6.UsuarioService, i7.ResponsiveService, i8.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 8, 0); }, null); }
export function View_LocalidadesComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { elementView: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["localidadesTemplate", 2]], null, 0, null, View_LocalidadesComponent_1))], null, null); }
export function View_LocalidadesComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-localidades", [], null, null, null, View_LocalidadesComponent_0, RenderType_LocalidadesComponent)), i1.ɵdid(1, 245760, null, 0, i9.LocalidadesComponent, [i10.AuthService, i11.BsModalService, i7.ResponsiveService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LocalidadesComponentNgFactory = i1.ɵccf("app-localidades", i9.LocalidadesComponent, View_LocalidadesComponent_Host_0, {}, { open: "open", close: "close" }, []);
export { LocalidadesComponentNgFactory as LocalidadesComponentNgFactory };

import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { WINDOW } from 'src/app/shared/services/window.provider';
import { environment } from '../../../environments/environment';
import XpresUtils from '../../shared/xpres.util';
import { IShipment, Shipment } from './shipment.model';
import { NotificationService } from 'src/app/shared/services/notification.service';


interface IEnvioReturn {
  departamentos: any[];
  localidadesOrigen: any[];
  localidadesDestino: any[];
  tipos: any[];
  categorias: any[];
  centros: any[];
  servicios: any[];
}

interface Coupon {
  code: string;
  discount: string;
  description: string;
}

@Injectable()
export class EnvioService {

  routeParams: any;
  envio: IShipment;
  onEnvioChanged: BehaviorSubject<IShipment>;
  backendUrl: string = null;

  constructor(
    private http: HttpClient,
    private _notificationService: NotificationService,
    @Inject(WINDOW) private window: Window
  ) {
    // Set the defaults
    this.onEnvioChanged = new BehaviorSubject(new Shipment());
    this.backendUrl = (!XpresUtils.isPrivateIP(location.host) ? environment.endpoint : environment.privateEndpoint);
  }

  cargarEnvio(params: any): Observable<any> {
    if (params.status === -1 || params.status === 0 || params.status === 1) {
      if (XpresUtils.isValidUuid(params.envioId)) {
        return this._recuperarEnvio(params.envioId, params);
      }
    }

    return of(null);
  }

  integrarEnvio(params: any): Observable<any> {
    if (params.status === 0) {  // Success
      if (XpresUtils.isValidUuid(params.envioId)) {
        return this._integrarEnvio(params.envioId, params);
      }
    }

    return of(null);
  }

  getData(): Observable<IEnvioReturn> {
    const observers: Observable<any>[] = [
      this.getDepartamentos(),
      this.getLocalidades(),
      this.getLocalidades(),
      this.getTypes(),
      this.getCategories(),
      this.getPickupCenters(),
      this.getServicios()
    ];

    return forkJoin(observers)
      .pipe(
        map(results => ({
          departamentos: (<any[]>(results[0].body)),
          localidadesOrigen: (<any[]>(results[1].body)),
          localidadesDestino: (<any[]>(results[2].body)),
          tipos: (<any[]>(results[3].body)),
          categorias: (<any[]>(results[4].body)),
          centros: (<any[]>(results[5].body)),
          servicios: (<any[]>(results[6].body))
        }),
          catchError(err => throwError(err))
        ));
  }

  private _recuperarEnvio(envioId: string, mpParams: any): Observable<any> {
    if (envioId) {
      const status = mpParams.status === 0 ? 'success' : mpParams.status === 1 ? 'pending' : 'failure';

      const body = mpParams.params;
      const params = new HttpParams();

      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
      });

      return this.http.post(`${this.backendUrl}/mercadopago/payment/${envioId}/${status}`,
        body, {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        params: params
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          const shipment = <Shipment>(res.body);
          this.envio = this._serializeShipment(shipment);

          this.onEnvioChanged.next(this.envio);
          return of(this.envio);
        }),
        catchError(err => {
          return throwError(err);
        })
      );

    } else {
      return of(null);
    }
  }

  private _integrarEnvio(envioId: string, mpParams: any): Observable<any> {
    if (envioId) {

      const body = {};
      const params = new HttpParams();

      const httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json'
      });

      return this.http.post(`${this.backendUrl}/envios/integrate/${envioId}`,
        body, {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        params: params
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          const integrationInfo = res.body;
          return of(integrationInfo);
        }),
        catchError(err => {
          return throwError(err);
        })
      );

    } else {
      return of(null);
    }
  }

  _serializeShipment(shipment: any): any {
    return {
      sender: {
        name: shipment['nombreRemitente'],
        email: shipment['emailRemitente'],
        phoneNumber: shipment['telefonoRemitente'],
        state: {
          id: shipment['localidadRemitente']['departamento']['id'],
          description: shipment['localidadRemitente']['departamento']['nombre']
        },
        city: {
          id: shipment['localidadRemitente']['id'],
          description: shipment['localidadRemitente']['nombre']
        },
      },
      recipient: {
        name: shipment['nombreDestinatario'],
        phoneNumber: shipment['telefonoDestinatario'],
        email: shipment['emailDestinatario'],
        state: {
          id: shipment['localidadDestinatario']['departamento']['id'],
          description: shipment['localidadDestinatario']['departamento']['nombre']
        },
        city: {
          id: shipment['localidadDestinatario']['id'],
          description: shipment['localidadDestinatario']['nombre']
        },
        pickupCenter: shipment['puntoDeEntrega'] ? { id: shipment['puntoDeEntrega']['id'] } : null,
        street: shipment['streetDestinatario'],
        doorNumber: shipment['doorNumberDestinatario'],
        otherInfo: shipment['otherInfoDestinatario'],
        comments: shipment['observaciones']
      },
      shipmentDetails: {
        type: shipment['tipo'],
        reference: shipment['envioReferencia'],
        service: shipment['servicio'],
        category: shipment['categoria'],
        comments: shipment['envioObservaciones']
      },
      payment: {
        name: shipment['nombrePagador'],
        surname: shipment['apellidoPagador'],
        email: shipment['emailPagador'],
        amount: shipment['importe']
      }
    };
  }

  guardarEnvio(shipment: IShipment): Observable<any> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const body = {
      nombreRemitente: shipment.sender.name,
      emailRemitente: shipment.sender.email,
      telefonoRemitente: shipment.sender.phoneNumber,
      localidadRemitente: { id: shipment.sender.city.id },

      nombreDestinatario: shipment.recipient.name,
      emailDestinatario: shipment.recipient.email,
      telefonoDestinatario: shipment.recipient.phoneNumber,
      localidadDestinatario: { id: shipment.recipient.city.id },
      streetDestinatario: shipment.recipient.street,
      doorNumberDestinatario: shipment.recipient.doorNumber,
      otherInfoDestinatario: shipment.recipient.otherInfo,
      observaciones: shipment.recipient.comments,
      puntoEntregaId: (shipment.recipient.pickupCenter ? shipment.recipient.pickupCenter.id : null),
      saveCurrentAddress: shipment.recipient.saveCurrentAddress,
      saveAsName: shipment.recipient.saveAsName,

      tipoId: (shipment.shipmentDetails.type ? shipment.shipmentDetails.type.id : null),
      categoriaId: shipment.shipmentDetails.category.id,
      envioReferencia: shipment.shipmentDetails.reference,
      envioObservaciones: shipment.shipmentDetails.comments,
      servicioId: shipment.shipmentDetails.service.id,

      nombrePagador: shipment.payment.name,
      apellidoPagador: shipment.payment.surname,
      emailPagador: shipment.payment.email,
      importe: shipment.payment.amount,
      codigoCupon: shipment.payment.couponCode,

      backendUrl: `${this.getHostname()}/home`
    };

    return this.http.request<Observable<HttpResponse<Object>>>('post',
      `${this.backendUrl}/envios/saveAndRequestPayment`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        body: body
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          const savedShip = res.body;
          return of(savedShip);
        })
      );
  }

  getHostname(): string {
    return this.window.location.protocol + '//' + this.window.location.host;
  }

  getDepartamentos(): Observable<Array<any>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const params = new HttpParams()
      .set('page', '1')
      .set('size', '10000');

    return this.http.get<Observable<Array<any>>>(
      `${this.backendUrl}/departamentos`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        params: params
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          const rows = <any[]>(res.body);
          const departamentos = rows.map(item => ({ 'id': item['id'], 'nombre': item['nombre'] }));

          return of(departamentos);
        })
      );
  }

  getLocalidades(id: string = '-1'): Observable<Array<any>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const params = new HttpParams()
      .set('page', '1')
      .set('size', '10000');

    if (XpresUtils.isValidUuid(id)) {
      return this.http.get<Observable<Array<any>>>(
        `${this.backendUrl}/localidades?where[departamentoId]=${id}`,
        {
          headers: httpHeaders,
          observe: 'response',
          responseType: 'json',
          params: params
        }).pipe(
          switchMap((res: HttpResponse<Object>) => {
            const rows = <any[]>(res.body);

            const localidades = rows.map(item => ({ 'id': item['id'], 'nombre': item['nombre'] }));
            return of(localidades);
          })
        );
    } else {
      return of([]);
    }
  }

  getTypes(): Observable<Array<any>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const params = new HttpParams()
      .set('page', '1')
      .set('size', '10000');

    return this.http.get<Observable<Array<any>>>(
      `${this.backendUrl}/tipos`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        params: params
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          const rows = <any[]>(res.body);

          const tipos = rows.map(item => ({
            'id': item['id'],
            'nombre': item['nombre'],
            'codigo': item['codigo']
          }));

          return of(tipos);
        })
      );
  }

  getCategories(): Observable<Array<any>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const params = new HttpParams()
      .set('page', '1')
      .set('size', '10000');

    return this.http.get<Observable<Array<any>>>(
      `${this.backendUrl}/categorias?sort[codigo]=1`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        params: params
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          const rows = <any[]>(res.body);

          const categorias = rows.map(item => ({ 'id': item['id'], 'nombre': item['nombre'] }));

          return of(categorias);
        })
      );
  }

  getPickupCenters(id: string = '-1'): Observable<Array<any>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    // aca se levantan para la parte de crear envio
    const params = new HttpParams()
      .set('page', '1')
      .set('size', '10000');

    if (XpresUtils.isValidUuid(id)) {
      return this.http.get<Observable<Array<any>>>(
        `${this.backendUrl}/centros?departamentoId=${id}`,

        {
          headers: httpHeaders,
          observe: 'response',
          responseType: 'json',
          params: params
        }).pipe(
          switchMap((res: HttpResponse<Object>) => {
            const rows = <any[]>(res.body);
            if (rows.length == 0)
              this._notificationService.showInfo('El departamento seleccionado no tiene pickups asignados.')
            const centros = rows.map(item => ({
              'id': item.id,
              'nombre': item.localidad.nombre + ' - ' + (item.nombre || '') + ': ' +
                ((item.calle || '') + ' ' + (item.doorNumber || '') + ' ' + (item.otherInfo || '')).trim()
            }));

            return of(centros);
          }),
          catchError(err => {
            return throwError(err);
          })
        );
    } else {
      return of([]);
    }
  }

  getServicios(origenId: string = '-1', destinoId: string = '-1'): Observable<Array<any>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const params = new HttpParams()
      .set('page', '1')
      .set('size', '10000');

    if (XpresUtils.isValidUuid(origenId) && XpresUtils.isValidUuid(destinoId)) {
      return this.http.get<Observable<Array<any>>>(
        `${this.backendUrl}/servicios?origenId=${origenId}&destinoId=${destinoId}`,
        {
          headers: httpHeaders,
          observe: 'response',
          responseType: 'json',
          params: params
        }).pipe(
          switchMap((res: HttpResponse<Object>) => {
            const rows = <any[]>(res.body);
            return of(rows);
          })
        );
    } else {
      return of([]);
    }
  }

  trackPackage(trackingNumber: any): Observable<Array<any>> {
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    const params = new HttpParams()
      .set('page', '1')
      .set('size', '10000');

    return this.http.get<Observable<Array<any>>>(
      `${this.backendUrl}/track?n=${trackingNumber}`,
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'json',
        params: params
      }).pipe(
        switchMap((res: HttpResponse<Object>) => {
          const rows = <any[]>(res.body);
          return of(rows);
        }),
        catchError(err => {
          console.log(err);
          return of([]);
        })
      );
  }

  redeemCoupon(code, userId) {
        return this.http
          .get<Coupon>(
            `${this.backendUrl}/cupones/canjear?code=${code}&userId=${userId}`
          )
          .pipe(
            catchError((error: HttpErrorResponse) => {
              let errorMessage;

              if (error.status === 0) {
                // A client-side or network error occurred.
                errorMessage = `Error: ${error.error.message}`;
              } else {
                // The backend returned an unsuccessful response code.
                errorMessage = this.getServerErrorMessage(error);
              }

              // Return an observable with a user-facing error message.
              return throwError(new Error(errorMessage));
            })
          );
  }

  private getServerErrorMessage(error: HttpErrorResponse): string {
    const body = error.error;

    if (body.error.code) {
      switch (body.error.code) {
        case "COUPON_EXPIRED":
          return "El cupón ha expirado";
        case "COUPON_REDEEMED_TOO_MANY_TIMES":
        case "COUPON_REDEEMED_TOO_MANY_TIMES_BY_USER":
          return "El cupón se usó demasiadas veces"
        default:
          return "El código no es válido";
      }
    }
  }
}

import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var HomeService = /** @class */ (function () {
    function HomeService() {
        this.focusSearchSubject = new Subject();
    }
    HomeService.prototype.focusSearchInput = function () {
        this.focusSearchSubject.next();
    };
    HomeService.prototype.observeFocusSearchInput = function () {
        return this.focusSearchSubject.asObservable();
    };
    HomeService.ngInjectableDef = i0.defineInjectable({ factory: function HomeService_Factory() { return new HomeService(); }, token: HomeService, providedIn: "root" });
    return HomeService;
}());
export { HomeService };

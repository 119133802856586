import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponsiveService } from './responsive.service';
import * as i0 from "@angular/core";
import * as i1 from "./responsive.service";
var HeaderMenuService = /** @class */ (function () {
    function HeaderMenuService(responsive) {
        var _this = this;
        this.responsive = responsive;
        this.unsubscribeAll = new Subject();
        this.mobileMenuState = new BehaviorSubject(false);
        this.breakpoint = responsive.getBreakpoint();
        this.breakpoint
            .subscribe(function (breakpoint) {
            _this.isMobile = breakpoint !== 'xl' && breakpoint !== 'lg';
        });
        responsive.check();
    }
    HeaderMenuService.prototype.getMobileMenuDisplayed = function () {
        return combineLatest([this.mobileMenuState.asObservable(), this.breakpoint])
            .pipe(map(function (_a) {
            var disp = _a[0], bp = _a[1];
            if (bp === 'xl' || bp === 'lg') {
                return false;
            }
            else {
                return disp;
            }
        }));
        // return this.mobileMenuState.asObservable()
        // .pipe(
        //   switchMap(b => {
        //     if (this.isMobile) {
        //       return of(b);
        //     }
        //     return of(false);
        //   })
        // );
    };
    HeaderMenuService.prototype.setMobileMenuDisplayed = function (v) {
        this.mobileMenuState.next(v);
    };
    HeaderMenuService.ngInjectableDef = i0.defineInjectable({ factory: function HeaderMenuService_Factory() { return new HeaderMenuService(i0.inject(i1.ResponsiveService)); }, token: HeaderMenuService, providedIn: "root" });
    return HeaderMenuService;
}());
export { HeaderMenuService };

<div class="list-container">
  <div class="slider">
    <div class="row">
      <div class="col-md-6">
        <div class="row info-container" *ngIf="envio.sender.state">
          <div class="col">
            <h5>
              <span class="section-title">Remitente: </span>
              <span class="name">{{envio.sender.name}}</span>
            </h5>
            <div class="text-container pl-5">
              <i class="fa fa-envelope"></i>
              <div>{{envio.sender.email}}</div>
            </div>
            <div class="text-container pl-5">
              <i class="fa fa-phone"></i>
              <div>{{envio.sender.phoneNumber}}</div>
            </div>
            <div class="text-container pl-5">
              <i class="fa fa-map-marker"></i>
              <div>{{envio.sender.state.description}} - {{envio.sender.city.description}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row info-container pt-3" *ngIf="envio.recipient.state">
          <div class="col">
            <h5>
              <span class="section-title">Destinatario: </span>
              <span class="name">{{envio.recipient.name}}</span>
            </h5>
            <div class="text-container pl-5">
              <i class="fa fa-envelope"></i>
              <div>{{envio.recipient.email}}</div>
            </div>
            <div class="text-container pl-5">
              <i class="fa fa-phone"></i>
              <div>{{envio.recipient.phoneNumber}}</div>
            </div>
            <div class="text-container pl-5">
              <i class="fa fa-map-marker"></i>
              <div>
                <ng-container *ngIf="envio.recipient.pickupCenter">
                  {{envio.recipient.pickupCenter.description}}<br>
                </ng-container>
                <ng-container *ngIf="!envio.recipient.pickupCenter">
                  {{envio.recipient.street}} {{envio.recipient.doorNumber}} {{envio.recipient.otherInfo}}<br>
                </ng-container>
                {{envio.recipient.state.description}} - {{envio.recipient.city.description}}
                <ng-container *ngIf="!envio.recipient.comments">
                  <br>{{envio.recipient.comments}}
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row info-container pt-3" *ngIf="envio.shipmentDetails.service">
          <div class="col">
            <h5>
              <span class="section-title">Envío </span>
            </h5>
            <div class="text-container pl-5">
              <i class="fa fa-archive"></i>
              <div>
                {{envio.shipmentDetails.category.nombre}}<br>
                {{envio.shipmentDetails.service.nombre}}
                <ng-container *ngIf="envio.shipmentDetails.comments">
                  <br>{{envio.shipmentDetails.comments}}
                </ng-container>
                <ng-container *ngIf="envio.shipmentDetails.reference">
                  <br>Referencia: {{envio.shipmentDetails.reference}}
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="row info-container pt-3" *ngIf="envio.payment">
          <div class="col">
            <h5>
              <span class="section-title pago_mobile">Pago: </span>
              <span class="section-title pago_desktop">Información de pago: </span>
              <span class="name">{{envio.payment.name}} {{envio.payment.surname}}</span>
            </h5>
            <div class="text-container pl-5">
              <i class="fa fa-envelope"></i>
              <div>
                {{envio.payment.email}}
              </div>
            </div>
            <div class="text-container pl-5">
              <i class="fa fa-credit-card"></i>
              <div>
                <span class="importe">$U {{envio.payment.amount | number:'1.2-2'}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 pb-4 d-flex flex-column">
        <div class="row flex-grow-1 align-items-end">
          <div class="col text-center pt-4">
            <a class="btn btn-sm btn-outline-primary" href="javascript:void(0);" (click)="hideDetail()">Regresar...</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="destktop-view" *ngIf="!_isMobile">
    <table class="table table-sm">
      <thead>
        <tr>
          <th scope="col">Fecha</th>
          <th scope="col">Estado</th>
          <th scope="col">Destinatario</th>
          <th scope="col">Departamento</th>
          <th scope="col">Localidad</th>
          <th scope="col">Referencia</th>
          <th scope="col">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of misPaquetes$ | async | paginate: { id: 'server', itemsPerPage: size, currentPage: p, totalItems: total }">
          <td (click)="showDetail(item.id)">{{item.fecha | date:'dd/MM/yyyy'}}</td>
          <td (click)="showDetail(item.id)"><span class="status" [id]="'ST-' + (item.clientReference || item.id)">{{item.estadoNetSuit}}</span></td>
          <td (click)="showDetail(item.id)">{{item.nombreDestinatario}}</td>
          <td (click)="showDetail(item.id)">{{item.localidadDestinatario.departamento.nombre}}</td>
          <td (click)="showDetail(item.id)">{{item.localidadDestinatario.nombre}}</td>
          <td (click)="showDetail(item.id)">{{item.clientReference}}</td>
          <td><a target="_blank" [href]="item.netSuitResponse.etiquetaURL">Etiqueta...</a></td>
        </tr>
      </tbody>
    </table>

    <pagination-controls class="text-right" (pageChange)="getPage($event)" id="server"
      maxSize="5"
      directionLinks="false"
      autoHide="true"
      responsive="true"
      previousLabel="Anterior"
      nextLabel="Siguiente"
      screenReaderPaginationLabel="Paginación"
      screenReaderPageLabel="página"
      screenReaderCurrentLabel="Está en la página"></pagination-controls>
  </div>

  <div class="mobile-view" *ngIf="_isMobile">
    <table class="table table-sm">
      <thead></thead>
      <tbody>
        <tr *ngFor="let item of misPaquetes$ | async | paginate: { id: 'server1', itemsPerPage: 3, currentPage: p, totalItems: total }">
          <td>
            <div><span>Fecha: </span><span class="item-data">{{item.fecha | date:'dd/MM/yyyy'}}</span></div>
            <div><span>Estado: </span>
              <span class="item-data" [id]="'ST-' + (item.clientReference || item.id)">{{item.estadoNetSuit}}</span>
            </div>
            <div><span>Destinatario: </span><span class="item-data">{{item.nombreDestinatario}}</span></div>
            <div><span>Departamento: </span><span class="item-data">{{item.localidadDestinatario.departamento.nombre}}</span></div>
            <div><span>Localidad: </span><span class="item-data">{{item.localidadDestinatario.nombre}}</span></div>
            <div><span>Referencia: </span><span class="item-data">{{item.clientReference}}</span></div>
            <div class="text-right"><a class="btn btn-sm btn-outline-primary" target="_blank" [href]="item.netSuitResponse.etiquetaURL">Etiqueta...</a></div>
          </td>
        </tr>
      </tbody>
    </table>

    <pagination-controls class="text-right" (pageChange)="getPage($event)" id="server1"
      maxSize="3"
      directionLinks="false"
      autoHide="true"
      responsive="false"
      previousLabel="Anterior"
      nextLabel="Siguiente"
      screenReaderPaginationLabel="Paginación"
      screenReaderPageLabel="página"
      screenReaderCurrentLabel="Está en la página">
    </pagination-controls>
  </div>
</div>

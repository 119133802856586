/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./asistente.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../envio/envio.component.ngfactory";
import * as i3 from "../envio/envio.component";
import * as i4 from "@angular/forms";
import * as i5 from "../envio/envio.service";
import * as i6 from "../../shared/services/bs-events.service";
import * as i7 from "../../shared/services/responsive.service";
import * as i8 from "@angular/router";
import * as i9 from "../../shared/auth/auth.service";
import * as i10 from "../user/addresses/address.service";
import * as i11 from "../../shared/services/notification.service";
import * as i12 from "../tarifas/tarifas.service";
import * as i13 from "../../shared/services/modal-data.service";
import * as i14 from "@angular/common";
import * as i15 from "./asistente.component";
import * as i16 from "ngx-bootstrap/modal";
import * as i17 from "./asistente.service";
import * as i18 from "../auth/login/login.service";
var styles_AsistenteComponent = [i0.styles];
var RenderType_AsistenteComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AsistenteComponent, data: {} });
export { RenderType_AsistenteComponent as RenderType_AsistenteComponent };
function View_AsistenteComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Enviar paquete"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cerrarAsistente() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-envio", [], null, null, null, i2.View_EnvioComponent_0, i2.RenderType_EnvioComponent)), i1.ɵdid(8, 245760, [[2, 4], ["envioComponent", 4]], 0, i3.EnvioComponent, [i4.FormBuilder, i5.EnvioService, i6.BsEventsService, i7.ResponsiveService, i8.ActivatedRoute, i8.Router, i9.AuthService, i10.AddressService, i11.NotificationService, i12.TarifasService, i13.ModalDataService, i14.DOCUMENT], null, null)], function (_ck, _v) { _ck(_v, 8, 0); }, null); }
export function View_AsistenteComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { elementView: 0 }), i1.ɵqud(671088640, 2, { envioComponent: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["envioTemplate", 2]], null, 0, null, View_AsistenteComponent_1))], null, null); }
export function View_AsistenteComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-asistente-envio", [], null, null, null, View_AsistenteComponent_0, RenderType_AsistenteComponent)), i1.ɵdid(1, 245760, null, 0, i15.AsistenteComponent, [i9.AuthService, i16.BsModalService, i13.ModalDataService, i5.EnvioService, i8.Router, i7.ResponsiveService, i17.AsistenteService, i18.LoginService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AsistenteComponentNgFactory = i1.ɵccf("app-asistente-envio", i15.AsistenteComponent, View_AsistenteComponent_Host_0, {}, { open: "open", close: "close" }, []);
export { AsistenteComponentNgFactory as AsistenteComponentNgFactory };

import { EnvioService } from './../../envio/envio.service';
import { ElementRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { LoginService } from './login.service';
import { UsuarioService } from '../../user/user.service';
import { environment } from '../../../../environments/environment';
export var confirmPasswordValidator = function (control) {
    if (!control.parent || !control) {
        return null;
    }
    var password = control.parent.get('password');
    var passwordConfirm = control.parent.get('passwordConfirm');
    if (!password || !passwordConfirm) {
        return null;
    }
    if (!passwordConfirm.value) {
        return null;
    }
    if (password.value === passwordConfirm.value) {
        return null;
    }
    return { 'passwordsNotMatching': true };
};
var LoginComponent = /** @class */ (function () {
    function LoginComponent(_formBuilder, _auth, _notificationService, _userService, _loginService, _envioService) {
        this._formBuilder = _formBuilder;
        this._auth = _auth;
        this._notificationService = _notificationService;
        this._userService = _userService;
        this._loginService = _loginService;
        this._envioService = _envioService;
        this.loginSubmitted = false;
        this.registerSubmitted = false;
        this.forgotSubmitted = false;
        this.loading = false;
        this.XPRES_SITE_KEY = "" + environment.reCaptchaKey;
        this.login = new EventEmitter();
        this.mostrarDialogoRegistroCompleto = false;
        this._unsubscribeAll = new Subject();
        this.departamentos$ = this._envioService.getDepartamentos();
        this.localidades$ = this._envioService.getLocalidades('-1');
    }
    LoginComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.tiposFiscales$ = this._userService.getTiposFiscales();
        this._buildForms();
        // Update the validity of the 'passwordConfirm' field
        // when the 'password' field changes
        this.registerForm.get('password').valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function () {
            _this.registerForm.get('passwordConfirm').updateValueAndValidity();
        });
        this._loginService.onLoginChanged.asObservable()
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(function (l) {
            switch (l) {
                case 'open':
                    _this.show();
                    break;
                case 'close':
                    _this.onClose();
                    break;
                default: break;
            }
        });
    };
    LoginComponent.prototype.ngOnDestroy = function () {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    };
    LoginComponent.prototype._buildForms = function () {
        this.loginForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]]
        });
        this.registerForm = this._formBuilder.group({
            name: [null, Validators.required],
            lastName: [null],
            email: [null, [Validators.required, Validators.email]],
            password: [null, [Validators.required, Validators.pattern(/^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/)]],
            passwordConfirm: [null, [Validators.required, confirmPasswordValidator]],
            phone: [null, Validators.required],
            rut: [null, Validators.required],
            fiscalType: [null, Validators.required],
            state: [null, Validators.required],
            city: [null, Validators.required],
            address: [null, Validators.required],
            recaptchaReactive: new FormControl(null, Validators.required)
        });
        this.forgotForm = this._formBuilder.group({
            email: ['', [Validators.required, Validators.email]]
        });
        this.registerForm.controls['state'].reset();
        this.registerForm.controls['city'].reset();
    };
    Object.defineProperty(LoginComponent.prototype, "lf", {
        // Convenience getters for easy access from the template to form fields.
        get: function () {
            return this.loginForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "rf", {
        get: function () {
            return this.registerForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LoginComponent.prototype, "ff", {
        get: function () {
            return this.forgotForm.controls;
        },
        enumerable: true,
        configurable: true
    });
    LoginComponent.prototype.onLoginSubmit = function () {
        var _this = this;
        this.loginSubmitted = true;
        if (!this.loginForm.invalid) {
            var inputValue = this.loginForm.value;
            this._auth.login(inputValue.email, inputValue.password)
                .subscribe(function (loggedUser) {
                var name = ((loggedUser['firstName'] || '').trim() + ' ' + (loggedUser['lastName'] || '').trim()).trim();
                _this._loginService.loggedIn();
                if (!name) {
                    name = loggedUser['email'].split('@')[0];
                }
                _this.close.nativeElement.click();
                _this._notificationService.showInfo("Bienvenido " + name + "!")
                    .then(function () {
                    console.log(loggedUser);
                });
            }, function (err) {
                if (err.error && err.error.message) {
                    throw (new Error(err.error.message));
                }
                throw err;
            });
        }
    };
    LoginComponent.prototype.onClose = function () {
        // this._loginService.close();
        this.loginSubmitted = false;
        this.registerSubmitted = false;
        this.forgotSubmitted = false;
        var forms = [this.loginForm, this.registerForm, this.forgotForm];
        forms.forEach(function (form) {
            Object.keys(form.controls).forEach(function (key) {
                form.controls[key].reset();
                // form.controls[key].markAsTouched();
                form.controls[key].updateValueAndValidity();
            });
            // form.markAsTouched();
            form.updateValueAndValidity();
        });
    };
    LoginComponent.prototype.onRegisterSubmit = function () {
        var _this = this;
        this.registerSubmitted = true;
        if (!this.registerForm.invalid) {
            var inputValue = this.registerForm.value;
            this._auth.register(inputValue)
                .subscribe(function (loggedUser) {
                /* let name = ((loggedUser['firstName'] || '').trim() + ' ' + (loggedUser['lastName'] || '').trim()).trim();
    
                if (!name) {
                  name = loggedUser['email'].split('@')[0];
                } */
                _this.close.nativeElement.click();
                /* this._notificationService.showInfo(`Bienvenido ${name}!`)
                  .then(() => {
                    console.log(loggedUser);
                  }); */
                // this._notificationService.showInfo('Se ha enviado un email de verificación a la dirección de email proporcionada.');
                _this.mostrarDialogoRegistroCompleto = true;
            }, function (err) {
                throw (err);
            });
        }
    };
    LoginComponent.prototype.onForgotSubmit = function () {
        var _this = this;
        this.forgotSubmitted = true;
        if (!this.forgotForm.invalid) {
            var inputValue = this.forgotForm.value;
            this._auth.forgot(inputValue.email)
                .subscribe(function (loggedUser) {
                _this.close.nativeElement.click();
                _this._notificationService.showInfo('Verifique su casilla de correo!')
                    .then(function () {
                    // email enviado!
                });
            }, function (err) {
                throw (err);
            });
        }
    };
    LoginComponent.prototype.show = function (showModal) {
        if (showModal === void 0) { showModal = null; }
        // this._loginService.open();
        window['openLoginModal']();
    };
    LoginComponent.prototype.onChangeDep = function (depId) {
        this.registerForm.controls['city'].reset();
        this.localidades$ = this._envioService.getLocalidades(depId);
    };
    LoginComponent.prototype.isInvalid = function (name) {
        if (this.registerForm.controls && this.registerForm.controls[name]) {
            if (this.registerForm.controls[name]['errors'] && !this.registerForm.controls[name]['untouched']) {
                return true;
            }
        }
        return false;
    };
    //TODO agrego esto
    LoginComponent.prototype.registerHasError = function (error, fieldName) {
        return this._formHasError(this.registerForm, error, fieldName);
    };
    LoginComponent.prototype.loginHasError = function (error, fieldName) {
        return this._formHasError(this.loginForm, error, fieldName);
    };
    LoginComponent.prototype._formHasError = function (form, error, fieldName) {
        if (form.controls && form.controls[fieldName]) {
            if (form.controls[fieldName].hasError(error) &&
                !form.controls[fieldName].untouched) {
                return true;
            }
        }
        return false;
    };
    LoginComponent.prototype.hasError = function (error, fieldName) {
        if (this.registerForm.controls && this.registerForm.controls[fieldName]) {
            if (this.registerForm.controls[fieldName].hasError(error) && !this.registerForm.controls[fieldName].untouched) {
                return true;
            }
        }
        return false;
    };
    return LoginComponent;
}());
export { LoginComponent };

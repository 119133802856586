/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page404.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./page404.component";
var styles_Page404Component = [i0.styles];
var RenderType_Page404Component = i1.ɵcrt({ encapsulation: 0, styles: styles_Page404Component, data: {} });
export { RenderType_Page404Component as RenderType_Page404Component };
export function View_Page404Component_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" page404 works!\n"]))], null, null); }
export function View_Page404Component_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page404", [], null, null, null, View_Page404Component_0, RenderType_Page404Component)), i1.ɵdid(1, 114688, null, 0, i2.Page404Component, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var Page404ComponentNgFactory = i1.ɵccf("app-page404", i2.Page404Component, View_Page404Component_Host_0, {}, {}, []);
export { Page404ComponentNgFactory as Page404ComponentNgFactory };

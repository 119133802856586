<header class="px-0 position-absolute w-100 " data-wow-delay="0.5s" data-wow-duration="1s">
    <nav class="navbar navbar-expand-xl pt-3 navbar-xpres" [class.sticky-nav]="stickyHeader" [class.navbar-light]="stickyHeader" [class.navbar-dark]="!stickyHeader">
        <a class="navbar-brand ml-xl-5" routerLink="/">
            <img *ngIf="!stickyHeader" src="assets/img/xpres.png" alt="UES S.A">
            <img *ngIf="stickyHeader" src="assets/img/logo-color-xpres.png" alt="UES S.A">
        </a>

        <button type="button" class="navbar-toggler collapsed" (click)="toggleMenu()" aria-controls="navbarXpres" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
      </button>

        <div @openClose class=" navbar-collapse" *ngIf="!_isMobile || mobileMenuDisplayed">
            <ul class="navbar-nav p-3 p-xl-0 mx-auto">
                <li appNavLink class="nav-item mx-3"><a class="nav-link" routerLink="/como-funciona">CÓMO FUNCIONA</a></li>
                <li appNavLink class="nav-item mx-3"><a class="nav-link" routerLink="/section-puntos-xpres">PUNTOS XPRES!</a></li>
                <li appNavLink class="nav-item mx-3"><a class="nav-link" routerLink="/section-servicios">TARIFAS</a></li>
                <li appNavLink class="nav-item mx-3"><a class="nav-link" routerLink="/faq">FAQ</a></li>
                <!-- <li appNavLink class="nav-item mx-3"><a class="nav-link" routerLink="/section-contact">CONTACTO</a></li> -->
                <li appNavLink class="nav-item mx-3"><a class="nav-link" routerLink="/crear-envio">CREAR ENVÍO</a></li>
                <li appNavLink class="nav-item mx-3"><a class="nav-link" routerLink="/" (click)="focusSearch()" (touchstart)="focusSearch()">RASTREAR ENVÍO</a></li>
            </ul>
            <ul class="navbar-nav p-3  ml-auto">

                <ng-container>
                    <li *ngIf="!currentUser">
                        <button class="btn btn-primary" data-toggle="modal" (click)="login.emit()">
                    <svg focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512" class="mr-2" style="width: 20px"><path fill="currentColor" d="M248 104c-53 0-96 43-96 96s43 96 96 96 96-43 96-96-43-96-96-96zm0 144c-26.5 0-48-21.5-48-48s21.5-48 48-48 48 21.5 48 48-21.5 48-48 48zm0-240C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 448c-49.7 0-95.1-18.3-130.1-48.4 14.9-23 40.4-38.6 69.6-39.5 20.8 6.4 40.6 9.6 60.5 9.6s39.7-3.1 60.5-9.6c29.2 1 54.7 16.5 69.6 39.5-35 30.1-80.4 48.4-130.1 48.4zm162.7-84.1c-24.4-31.4-62.1-51.9-105.1-51.9-10.2 0-26 9.6-57.6 9.6-31.5 0-47.4-9.6-57.6-9.6-42.9 0-80.6 20.5-105.1 51.9C61.9 339.2 48 299.2 48 256c0-110.3 89.7-200 200-200s200 89.7 200 200c0 43.2-13.9 83.2-37.3 115.9z" class=""></path></svg>
                    INGRESAR
                  </button>
                    </li>

                    <li class="nav-item dropdown" *ngIf="currentUser">
                        <a class="nav-link text-white dropdown-toggle fb" href="javascript:void(0)" id="dropdown01" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{getUserName()}}</a>

                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdown01" id="userMenu">
                            <a appNavLink class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" pageScroll href="javascript:void(0)" id='link-user' (click)="showProfile.emit()">
                                <span>Mi perfil</span>
                            </a>
                            <a appNavLink class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" pageScroll href="javascript:void(0)" id='link-packages' (click)="showPackages.emit()">
                                <span>Mis envíos</span>
                            </a>
                            <div class="dropdown-divider"></div>
                            <a appNavLink class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" pageScroll href="javascript:void(0)" id='link-adm-localidades' (click)="showAdminLocalidades.emit()" *ngIf="isAdmin()">
                                <span>Localidades</span>
                            </a>
                            <!-- TODO esto es lo que ya no tiene sentido en el admin -->
                            <!-- <a appNavLink class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" pageScroll href="javascript:void(0)" id='link-adm-puntos' (click)="showAdminPuntos.emit()" *ngIf="isAdmin()">
                                <span>Puntos XPres!</span>
                            </a> -->
                            <div class="dropdown-divider" *ngIf="isAdmin()"></div>
                            <a appNavLink class="dropdown-item" data-toggle="collapse" data-target=".navbar-collapse.show" href="javascript:void(0)" onclick="hideMenu()" (click)="logout()">Salir</a>
                        </div>
                    </li>
                </ng-container>

            </ul>
        </div>
    </nav>



</header>
<span id="mq-detector">
  <span class="visible-xs d-block d-sm-none"></span>
<span class="visible-sm d-none d-sm-block d-md-none"></span>
<span class="visible-md d-none d-md-block d-lg-none"></span>
<span class="visible-lg d-none d-lg-block d-xl-none"></span>
<span class="visible-xl d-none d-xl-block"></span>
</span>
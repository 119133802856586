<ng-template #misPaquetesTemplate>
  <div class="modal-header">
    <h5 class="modal-title">Mis envíos</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-lista-paquetes></app-lista-paquetes>
  </div>
</ng-template>

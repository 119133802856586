<ng-template #termsTemplate>
  <div class="modal-header">
    <h5 class="modal-title">Términos y condiciones</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <perfect-scrollbar [config]="config" class="tyc-body">
      <p>
        <span class="tyc-subtitle text-primary">Peso y dimensiones</span>
        El peso máximo a transportar es hasta 30 Kg, con dimensiones máximas de 100 cm x 60 cm x 60 cm, salvo
        contratación
        específica de productos fuera de medida.</p>

      <p>
        <span class="tyc-subtitle text-primary">Gestión de entrega a domicilio</span>
        Se realizarán hasta 2 visitas al domicilio indicado (sea particular o laboral) del destinatario en el plazo indicado de acuerdo al servicio
        contratado. La entrega se realiza de lunes a viernes en el horario de 9 a 19 horas y sábado de 9 a 14 horas.

        Aquellos envíos no entregados, permanecerán a disposición del destinatario para su retiro en el pickup más cercano por un plazo de 7
         (siete) días hábiles.
      </p>

      <p>
        <span class="tyc-subtitle text-primary">Gestión de entrega en pickup</span>
        Los envíos quedarán disponibles a las 24 horas de despachados y permanecerán a disposición del destinatario para
        su
        retiro en el pickup por un plazo de 7 (siete) días hábiles.</p>

      <p>
        <span class="tyc-subtitle text-primary">Rendición y comprobante de entrega</span>
        El cliente podrá consultar en todo momento el centro de clientes para conocer el estado de sus envíos. En caso
        de
        necesitar el comprobante de entrega (firma y aclaración de receptor), se debe solicitar el mismo al departamento
        de
        servicio al cliente. UES guarda dicha información por un plazo máximo de 6 (seis) meses.</p>

      <p>
        <span class="tyc-subtitle text-primary">Imposibles de entrega</span>
        En caso de devolución al cliente, ya sea por direcciones erróneas o vencido el plazo en pickup, UES gestionará
        las mismas
        en un plazo máximo de 30 días.</p>

      <p>
        <span class="tyc-subtitle text-primary">Condiciones de transporte</span>
        Se encuentra prohibido enviar o requerir el envío de objetos inflamables, corrosivos, 
		explosivos, perecederos, dinero en efectivo, valores, 
		billetes de lotería y/o cualquier otro tipo de comprobantes de juegos de azar, sustancias de carácter infeccioso y/o peligroso, 
		material magnético, materiales radioactivos, animales, estupefacientes o cualquier otra sustancia y/o material cuyo uso o 
		tenencia esté penado legalmente o que se hubieran obtenido en violación a normas del código aduanero y/o de cualquier otra 
		normativa vigente, como también publicaciones cuya circulación esté prohibida en el país. 
		Siendo que UES desconoce el contenido del envío, el mismo resultará de exclusiva responsabilidad de los clientes.</p>

      <p>
        <span class="tyc-subtitle text-primary">Condiciones de embalaje y productos frágiles</span>
        El envío deberá estar debidamente cerrado, etiquetado y proporcionar condiciones que aseguren la protección del
        contenido, pudiendo UES rechazar el despacho del mismo si considera que el embalaje no está en condiciones
        óptimas. UES
        no dispone de un servicio especial para realizar la admisión y el transporte de envíos frágiles (vidrios,
        cerámicas,
        cristales y similar), no siendo responsable ante casos de daños o roturas.</p>

      <p>
        <span class="tyc-subtitle text-primary">Límite de responsabilidad:</span>
        Ante casos de siniestros que provoquen daños parciales y/o totales sobre los productos despachados donde nuestro
        cliente
        no ha contratado el servicio de seguro, UES se hará responsable por el valor de compra del bien según factura
        correspondiente con un tope de USD 50 (dólares americanos cincuenta).</p>
      
      <p>
        <span class="tyc-subtitle text-primary">Reglamentación de reclamaciones e indemnizaciones postales:</span>
        <a href="https://www.gub.uy/unidad-reguladora-servicios-comunicaciones/institucional/normativa/resolucion-n-185016-reglamentacion-reclamaciones-indemnizaciones-postales"
          target="_blank"> Resolución N° 185/2016 URSEC</a>
      </p>
    </perfect-scrollbar>
  </div>
  <div class="modal-footer">
    <button class="btn btn-secondary" (click)="bsModalRef.hide()">Aceptar</button>
  </div>
</ng-template>
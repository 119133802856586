<section class="faq-page position-relative d-flex flex-column pb-5 overflow-hidden" id="paqueteria">
    <div class="container-xl pt-5 mt-5 pt-md-0 mt-md-0">
        <div class="row pt-5 mt-5 pt-md-0 mt-md-0">
            <div class="col">
                <h2 class="title text-uppercase mb-5 text-white text-center">PREGUNTAS FRECUENTES</h2>
                ' <div class="row">
                    <div class="col-md-10 mx-auto">
                        <div class="card border-0 shadow-lg">
                            <div class="card-body p-md-5 accordion" id="faq-accordion">
                                <div class="container-fluid" style="text-align: left;">
                                  <ol>
                                    <li class="mt-3">
                                        <div id="heading-01">

                                          <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-01" aria-expanded="true" aria-controls="collapse-01">
                                            ¿Cómo hago un envío?
                                            </button>
                                        </div>
                                        <div id="collapse-01" class="collapse" aria-labelledby="heading-01" data-parent="#faq-accordion">
                                         
                                                <p> <br>  <a class="text-black" href="javascript:window.openRegisterForm();" >Regístrate en xpres!<br></a>
                                                <br>Una vez registrado crea un envió, completando los datos requeridos del destinatario y
                                                servicio seleccionado.<br> Realiza tu pago por mercado pago.<br><br> El pago
                                                podrá efectuarse únicamente por mercado pago y se ingresa en el momento
                                                de solicitar el envío (No se cobra al destinatario en el momento de la
                                                entrega)<br>
                                                Lleva tu paquete al xpres! más cercano (no se retira mercadería a domicilio – el
                                                remitente deberá acercarse
                                                a un punto xpres!)<br> Ver lista <a
                                                    href="https://xpres.com.uy/section-puntos-xpres">
                                                    Puntos xpres!</a><br><br> Todos los envíos para
                                                su procesamiento deben estar
                                                correctamente embalados y rotulados con la etiqueta que se genera en el momento
                                                de solicitar el mismo.<br> Imprimirla y pégala en el paquete.<br></p>
                                        </div>
                                    </li>  
                                    <li class="mt-3">
                                        <div id="heading-02">

                                          <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-02" aria-expanded="true" aria-controls="collapse-02">
                                            Datos de destinatario
                                        </button>
                                        </div>
                                        <div id="collapse-02" class="collapse" aria-labelledby="heading-02" data-parent="#faq-accordion">
                                            <p class="text-black"><b> Envío a domicilio</b> Es importante cargar
                                                correctamente todos los datos solicitados, aclarando en observaciones
                                                información adicional de la dirección,
                                                para asegurar la correcta entrega de tu paquete. <br><br> Una
                                                vez solicitado el envío no se podrán hacer modificaciones en la dirección de
                                                envío. <br> Es importante verificar que la dirección ingresada sea correcta
                                                previo a la
                                                confirmación del envío.<br><br></p>
                                          
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-03">
                                          <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-03" aria-expanded="true" aria-controls="collapse-03">
                                            ¿Qué pasa si me doy cuenta de que ingrese mal la dirección
                                            o
                                            faltan datos importantes?
                                        </button>
                                        </div>
                                        <div id="collapse-03" class="collapse" aria-labelledby="heading-02" data-parent="#faq-accordion">
                                            <p class="text-black"> En este caso y previo a que el paquete sea despachado en un
                                                punto
                                                xpres! podrás generar un nuevo envió con los datos correctos. <br> Una vez
                                                despachado
                                                en el punto xpres! no se podrán hacer modificaciones a la dirección.<br> xpres!
                                                no se
                                                hará responsable de devolución del costo de envío en estos casos (errores de
                                                dirección o datos del
                                                destinatario)<br> Se harán hasta dos intentos de entrega y si no es posible
                                                entregar
                                                el paquete quedara en el xpres! más próximo al domicilio del destinatario, por
                                                un período de siete días
                                                esperando ser retirado.<br> Cada envío tendrá un costo asociado de acuerdo con
                                                el
                                                servicio seleccionado, ver <a
                                                    href="https://xpres.com.uy/section-servicios">aqui</a><br>
                                            </p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-04">
                                          <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-04" aria-expanded="true" aria-controls="collapse-04">
                                            Etiqueta
                                           </button>
                                        </div>
                                        <div id="collapse-04" class="collapse" aria-labelledby="heading-04" data-parent="#faq-accordion">
                                            <p class="text-black"> Al crear tu envió se generará una etiqueta, archivo pdf, con
                                                todos
                                                los datos necesario para que tu paquete llegue a destino.<br> Imprimí la
                                                etiqueta y
                                                pégala en el paquete en un lugar visible, antes de llevarla a una red
                                                xpres!.<br> El
                                                número de envío que se encuentra en la etiqueta y comienza con UES000 , le da
                                                trazabilidad a tu envío y de
                                                esta forma podrás conocer paso a paso la ubicación y hacer seguimiento al
                                                mismo.<br><br></p>
                                            <div class="align-items-center text-center"><img alt="etiqueta"
                                                    class="img-thumbnail" src="assets/images/etiqueta.png"></div>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-05">
                                          <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-05" aria-expanded="true" aria-controls="collapse-05">
                                            ¿Envían a todas las localidades, en todo el país?
                                         </button>
                                        </div>
                                        <div id="collapse-05" class="collapse" aria-labelledby="heading-02" data-parent="#faq-accordion">
                                            <p class="text-black"> Sí, enviamos a todas las localidades del país. En el caso de
                                                que en
                                                tu localidad no haya un xpres! disponible, deberás seleccionar envío a domicilio
                                                y despachar tu paquete en
                                                el Xpres de la localidad más cercana. </p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-06">
                                          <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-06" aria-expanded="true" aria-controls="collapse-06">
                                            ¿Cuánto me sale mandar un envío?
                                        </button>
                                        </div>
                                        <div id="collapse-06" class="collapse" aria-labelledby="heading-02" data-parent="#faq-accordion">
                                            <p class="text-black"> Los precios de nuestros servicios están publicados <a
                                                href="https://xpres.com.uy/section-servicios">
                                                aqui</a></p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-07">

                                          <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-07" aria-expanded="true" aria-controls="collapse-07">
                                            ¿Cuánto tiempo tengo desde que creo el envío y lo despacho
                                            en un
                                            xpres!? 
                                        </button>
                                        </div>
                                        <div id="collapse-07" class="collapse" aria-labelledby="heading-02" data-parent="#faq-accordion">
                                            <p class="text-black"> El envío tendrá una validez o vigencia después de abonado, de
                                                hasta
                                                60 días corridos. En caso de no despachar el paquete en un xpres! dentro de este
                                                período, el mismo será dado
                                                de baja del sistema, quedando sin efecto ni validez xpres! no devolverá el
                                                importe cobrado por dicho
                                                servicio. </p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-08">
                                          <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-08" aria-expanded="true" aria-controls="collapse-08">
                                            Visitas
                                        </button>
                                        </div>
                                        <div id="collapse-08" class="collapse" aria-labelledby="heading-02" data-parent="#faq-accordion">
                                            <p class="text-black"><br> • En caso de elegir una dirección de
                                                entrega: visitaremos al destinatario hasta en dos oportunidades. <br><br>
                                                Recordar que se visitará en el rango horario entre 09.00 y 19.00 horas de lunes 
												a viernes, sábados entre 09.00 y 14.00 horas.<br> No se harán envios en fechas u
                                                horarios coordinados, ni feriados o festivos.<br> La primera visita será en el
                                                tiempo
                                                pactado y la segunda visita en las 24 - 48 horas siguientes de realizada la
                                                primera. <br> En caso de no concretar la entrega en ninguna de las dos visitas,
                                                se enviará tú
                                                paquete al Pick Up o xpres! más cercano al domicilio del destinatario. Donde
                                                permanecerá allí por 7 días
                                                hábiles esperando a ser retirado.<br> Te recordamos que, en la dirección de
                                                envío, lo
                                                puede recibir cualquier persona mayor a 18 años sin ser necesario que se
                                                encuentre el destinatario original
                                                del mismo.<br> • En caso de que hayas elegido que el destinatario retire en un
                                                xpres!:<br><br> El paquete permanecerá allí 7 días hábiles
                                                esperando a ser retirado.<br> Se le solicitará a la persona que se presenta
                                                código de
                                                rastreo o seguimiento, nombre y cédula de quien retira y del destinatario del
                                                paquete.<br></p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-09">
                                          <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-09" aria-expanded="true" aria-controls="collapse-09">
                                            ¿Puedo coordinar un horario de entrega?
                                        </button>
                                        </div>
                                        <div id="collapse-09" class="collapse" aria-labelledby="heading-02" data-parent="#faq-accordion">
                                            <p class="text-black"> El horario de entrega e s09.00 y 19.00 horas de lunes a viernes , sábados entre 09.00 y 14.00 horas, 
											no pudiendo coordinar un horario específico dentro de ese rango.</p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-10">
                                          <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-10" aria-expanded="true" aria-controls="collapse-10">
                                            ¿Me llaman cuando estén afuera de mi domicilio con el
                                            paquete?
                                        </button>
                                        </div>
                                        <div id="collapse-10" class="collapse" aria-labelledby="heading-02" data-parent="#faq-accordion">
                                            <p class="text-black"> Sabrás que tú paquete está en tránsito, próximo a llegar, a
                                                través
                                                de las notificaciones automáticas que recibirás en tú correo electrónico o vía
                                                SMS, además podrás hacer el
                                                seguimiento de tu envío en: <a href="https://xpres.com.uy/home">
                                                    xpres</a> . <br><br> El chofer
                                                antes de concurrir a tú domicilio te podrá enviar un SMS con el fin de
                                                informarte que estará pasando a la
                                                brevedad para proceder con la entrega. </p> 
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-11">
                                          <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-11" aria-expanded="true" aria-controls="collapse-11">
                                            ¿Podrá retirar el paquete de un xpres! persona que no sea
                                            el
                                            destinatario?
                                         </button>
                                        </div>
                                        <div id="collapse-11" class="collapse" aria-labelledby="heading-02" data-parent="#faq-accordion">
                                            <p class="text-black"> Si. Podrá retirar cualquier persona mayor a 18 años
                                                presentando:<br><br><b style="color:green;">&nbsp;&nbsp;&nbsp; √</b> Código de
                                                rastreo o seguimiento.<br><b style="color:green;">&nbsp;&nbsp;&nbsp; √</b>
                                                Documento
                                                de identidad de quien retira.<br><b style="color:green;">&nbsp;&nbsp;&nbsp;
                                                    √</b> Fotocopia de cédula del titular o destinatario del
                                                paquete.<br><br></p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-12">
                                          <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-12" aria-expanded="true" aria-controls="collapse-12">
                                            ¿Si el destinatario no retira el paquete del xpres! que
                                            sucede?
                                         </button>
                                        </div>
                                        <div id="collapse-12" class="collapse" aria-labelledby="heading-02" data-parent="#faq-accordion">
                                            <p class="text-black"> El paquete permanecerá allí por 7 días hábiles, en caso de
                                                que no
                                                sea retirado el mismo retornará a nuestro centro logístico permaneciendo un
                                                máximo de treinta días. El
                                                remitente deberá comunicarse con nosotros para coordinar la devolución del
                                                paquete en: <a href="https://xpres.com.uy/section-contact">
                                                    formulario</a><br><br> En caso de
                                                devolución al remitente, el paquete podrá ser retirado en:<br><br> Pick up
                                                Reducto<br> Enrique García Peña 2756 de lunes a viernes de 09:00 a 19:00 hs, 
												sábados de 09:00 a 13:00 hs.<br><br> Si lo deseas podremos enviar tu
                                                paquete a otra dirección generando un nuevo envío. Una vez creado, pagado y
                                                emitida la etiqueta. Deberás
                                                comunicarte con nosotros con el número de seguimiento para coordinar el envío
                                                del mismo, ya que el paquete
                                                se encuentra en nuestro centro logístico.<br></p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-13">
                                          <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-13" aria-expanded="true" aria-controls="collapse-13">
                                            ¿Cuáles son las condiciones para poder llevar mi paquete a
                                            una de
                                            sus sucursales?
                                        </button>
                                        </div>
                                        <div id="collapse-13" class="collapse" aria-labelledby="heading-13" data-parent="#faq-accordion">
                                            <p class="text-black"><b> Los paquetes deben pesar menos de 30 kilos
                                                y tener las siguientes dimensiones como máximo: 1m x 60 cms x 60 cms.
                                                Asimismo, deben estar
                                                correctamente cerrados, embalados, etiquetados y en buen estado. Cada bulto
                                                deberá tener una etiqueta
                                                única, se asignará un número de rastreo o seguimiento distinto por cada
                                                pieza/paquete/bulto. No se puede
                                                imprimir y pegar la misma etiqueta en distinto paquetes. Tendrás que crear
                                                un envío por cada
                                                pieza/paquete/bulto que quieras despachar. No podrán ser aceptados paquetes o cualquier 
												elemento que ponga en peligro la seguridad de los bienes de las Empresa y/o de los 
												Transportistas, y/o de las personas y/o de los envíos en general, o que se hubieran 
												obtenido en violación a normas del código aduanero y/o de cualquier otra normativa 
												vigente, como así también bienes cuyo transporte y/o circulación esté prohibida, 
												restringida o requiera tratamiento especial, como, por ejemplo:
                                            </b><br><br><br> • Armas de fuego de cualquier tipo.
                                            <br> • Explosivos (por ejemplo, TNT, bengalas, fuegos artificiales, pólvora, 
											municiones, encendedores a gas, entre otros).
                                            <br> • Gases comprimidos y aerosoles (extintores, aerosoles, butano para 
											cocinillas a gas, faroles a mantilla o a gas, aunque estén vacíos, 
											desodorantes en spray, entre otros).
                                            <br> • Líquido inflamable (gasolina, pintura, diluyente, entre otros).
                                            <br> • Materiales corrosivos (algunos líquidos de limpieza, productos contra
                                            herrumbre, ácido, soda caustica, pilas, entre otros).
                                            <br> • Sólidos inflamables, objetos y materias de combustión espontánea y 
											que al mojarse resultan peligrosos.
                                            <br> • Sustancias irritantes.
                                            <br> • Veneno (arsénico, cianuro, pesticidas, drogas, entre otros).
                                            <br> • Metal denso (mercurio).
                                            <br> • Productos alimenticios perecederos, que requieran de cadena de frio.
                                            <br> • Dinero en efectivo, monedas metálicas.
                                            <br> • Estupefacientes o cualquier otra sustancia.
                                            <br><br> El envío deberá estar cerrado y proporcionar
                                            condiciones que aseguren la protección del contenido y la integridad física de
                                            las personas que lo
                                            manipulen, con un embalaje y acondicionamiento adecuado, sin salientes que
                                            excedan el embalaje, sin
                                            etiquetas postales anteriores y debidamente acondicionados de forma tal que
                                            eviten derrames en caso de
                                            líquidos.
                                            <br> Un embalaje deficiente podría conllevar riesgos tales como: daños –
                                            deterioro
                                            total, retraso en los tiempos de tránsito, manipulaciones incorrectas o rechazo
                                            automático de cualquier
                                            reclamación de seguro.
                                            <br> Cerrado adecuado:
                                            <br> Una vez que su producto ha sido adecuadamente empacado, deberá sellarlo y
                                            colocar
                                            la Guía de servicio con los datos obligatorios de remitente, destinatario y con
                                            el código de barras visible
                                            que contiene el número de seguimiento.
                                            <br><br> Guía de servicio – etiqueta:
                                            <br><br> • Ubique la Guía de servicio en la cara superior de la
                                            caja o bien, en una cara lateral, sólo si ésta es la posición más estable.
                                            <br> • La Guía de servicio debe quedar completamente visible.
                                            <br> • Cuide que la Guía de servicio no quede arrugada.
                                            <br> • No la coloque debajo del fleje o cinta adhesiva ni en los bordes o puntos
                                            de
                                            apertura de la caja.
                                            <br> • Si envía un artículo con forma irregular coloque la Guía de servicio en
                                            la
                                            superficie más plana.
                                            <br> • Evite las etiquetas sueltas.
                                            <br><br></p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-14">
                                        <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-14" aria-expanded="true" aria-controls="collapse-14">
                                            ¿Dónde está mi paquete?
                                         </button>
                                        </div>
                                        <div id="collapse-14" class="collapse" aria-labelledby="heading-14" data-parent="#faq-accordion">
                                            <p class="text-black"> El remitente podrá conocer el estado actual de su envío en
                                                <a href="https://xpres.com.uy/home">
                                                    https://xpres.com.uy/home</a> /sección mis envíos.<br> El destinatario del paquete podrá
                                                conocer el
                                                estado actual de su envío a traves de <a href="https://xpres.com.uy/home">
                                                    https://xpres.com.uy/home</a> con el número de rastreo o seguimiento.
                                                Recibirá además
                                                notificaciones automáticas en su correo electrónico. <br></p> 
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-15">
                                        <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-15" aria-expanded="true" aria-controls="collapse-15">
                                            ¿Cuánto va a demorar mi paquete en llegar?
                                         </button>
                                        </div>
                                        <div id="collapse-15" class="collapse" aria-labelledby="heading-15" data-parent="#faq-accordion">
                                            <p class="text-black"> Podrás consultar los mismos en: <a
                                                href="https://xpres.com.uy/section-servicios">
                                                esta seccion </a></p><br>
                                        <div class="align-items-center text-center"><img alt="etiqueta"
                                                class="img-thumbnail" src="assets/images/tiempoe.png"></div>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-16">
                                        <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-16" aria-expanded="true" aria-controls="collapse-16">
                                            ¿Ubicación y horarios de nuestra red xpres!?
                                        </button>
                                        </div>
                                        <div id="collapse-16" class="collapse" aria-labelledby="heading-16" data-parent="#faq-accordion">
                                            <p class="text-black"> Ver lista <a
                                                href="https://xpres.com.uy/section-puntos-xpres">
                                                Puntos xpres!</a></p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-17">
                                        <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-17" aria-expanded="true" aria-controls="collapse-17">
                                            Vivo en el interior y veo en el rastreo que mi paquete se
                                            encuentra ingresado en agencia, ¿cuándo me llega?
                                        </button>
                                        </div>
                                        <div id="collapse-17" class="collapse" aria-labelledby="heading-17" data-parent="#faq-accordion">
                                            <p class="text-black"> ¡El paquete ha llegado a tú localidad! El distribuidor
                                                procederá
                                                con la entrega en las próximas 24 horas hábiles. </p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-18">
                                        <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-18" aria-expanded="true" aria-controls="collapse-18">
                                            ¿En mi localidad hay un xpres!? 
                                        </button>
                                        </div>
                                        <div id="collapse-18" class="collapse" aria-labelledby="heading-18" data-parent="#faq-accordion">
                                            <p class="text-black"> ¡Contamos con Pick Ups y Xpres! en varias localidades del
                                                país, las
                                                podrás conocer en el siguiente <a
                                                    href="https://xpres.com.uy/section-puntos-xpres">
                                                    link </a><br> En el caso de que en tu
                                                localidad no haya un xpres! disponible, deberás
                                                seleccionar envío a domicilio y despachar tu paquete en el xpres! de la
                                                localidad más cercana. <br></p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-19">
                                        <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-19" aria-expanded="true" aria-controls="collapse-19">
                                            La sucursal que ingresó mi paquete es muy lejos de mi
                                            domicilio,
                                            ¿puedo cambiarla?
                                        </button>
                                        </div>
                                        <div id="collapse-19" class="collapse" aria-labelledby="heading-19" data-parent="#faq-accordion">
                                            <p class="text-black"> Una vez que el paquete ingresó a uno de nuestros xpres!
                                                permanecerá
                                                allí por 7 días hábiles aguardando a ser retirado, no siendo posible Re
                                                coordinar su entrega en dicho
                                                período. </p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-20">
                                        <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-20" aria-expanded="true" aria-controls="collapse-20">

                                            ¿En qué horario puedo dejar un paquete en un xpres!? 
                                        </button>
                                        </div>
                                        <div id="collapse-20" class="collapse" aria-labelledby="heading-20" data-parent="#faq-accordion">
                                            <p class="text-black">
                                                El horario de atención para entregar o recibir paquetes es de lunes a viernes de
                                                9:00 a 19:00 horas.
                                                Quedará a criterio de cada xpres! recibir paquetes fuera del mismo. </p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-21">
                                        <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-21" aria-expanded="true" aria-controls="collapse-21">
                                            Deje un paquete en un xpres!, ¿el destinatario lo puede
                                            retirar
                                            hoy mismo en esa misma sucursal?
                                        </button>
                                        </div>
                                        <div id="collapse-21" class="collapse" aria-labelledby="heading-21" data-parent="#faq-accordion">
                                            <p class="text-black">
                                                Todos los paquetes deben concurrir a nuestro Centro Logístico para control,
                                                pesaje y clasificación
                                                correspondiente. Por lo cual, dependiendo del servicio seleccionado, será el
                                                tiempo que será enviado a la
                                                dirección o xpres! indicado </p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-22">
                                        <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-22" aria-expanded="true" aria-controls="collapse-22">
                                            Pago de envío
                                        </button>
                                        </div>
                                        <div id="collapse-22" class="collapse" aria-labelledby="heading-22" data-parent="#faq-accordion">
                                            <p class="text-black"> El mismo se hará en el momento de solicitar el envío mediante
                                                mercado pago. <br> No se cobra al destinatario en el momento de la entrega.
                                                <br><br> No se reintegrará el importe por envíos creados y no
                                                despachados, etiquetas con datos incorrectos o incompletos, entregas que no
                                                logran efectuarse luego de las
                                                dos visitas, demoras en la entrega. <br> Se visitará hasta dos veces y si no es
                                                posible la entrega el paquete quedara disponible en un xpres! para ser retirado
                                                por el destinatario.
                                                Permanecerá allí por 7 días hábiles. <br></p> 
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-23">
                                        <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-23" aria-expanded="true" aria-controls="collapse-23">
                                            Límite de responsabilidad
                                        </button>
                                        </div>
                                        <div id="collapse-23" class="collapse" aria-labelledby="heading-23" data-parent="#faq-accordion">
                                            <p class="text-black"> Ante casos de siniestros que provoquen daños parciales y/o
                                                totales
                                                sobre los productos despachados, xpres! se hará responsable por el valor del
                                                bien según factura
                                                correspondiente o comprobante de valor del bien enviado, con un tope de hasta
                                                USD 50 (dólares americanos
                                                cincuenta), previo análisis e investigación de cada caso. En caso de
                                                corresponder la responsabilidad se
                                                coordinará con el titular del envío el procedimiento establecido a seguir. </p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-24">
                                        <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-24" aria-expanded="true" aria-controls="collapse-24">
                                            Notificaciones
                                        </button>
                                        </div>
                                        <div id="collapse-24" class="collapse" aria-labelledby="heading-24" data-parent="#faq-accordion">
                                            <p class="text-black"> Recibirás notificaciones a tu mail de cada paso de tu
                                                paquete,
                                                además podrás rastrear el mismo en <a
                                                    href="https://xpres.com.uy/home?ts=k2z0r6xx#section-home">
                                                    este espacio</a></p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-25">
                                        <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-25" aria-expanded="true" aria-controls="collapse-25">
                                            Factura
                                        </button>
                                        </div>
                                        <div id="collapse-25" class="collapse" aria-labelledby="heading-25" data-parent="#faq-accordion">
                                            <p class="text-black"> Recibirás la factura correspondiente al servicio al mail que
                                                tengas
                                                registrado.<br> Ejemplo:<br><br></p><br>
                                            <div class="align-items-center text-center"><img alt="etiqueta"
                                                    class="img-thumbnail" src="assets/images/factura.png"></div>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-26">
                                        <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-26" aria-expanded="true" aria-controls="collapse-26">
                                            xpres! Business
                                        </button>
                                        </div>
                                        <div id="collapse-26" class="collapse" aria-labelledby="heading-26" data-parent="#faq-accordion">
                                            <p class="text-black">
                                                Si sos una empresa y quieres usar la red xpres!, completa el formulario con tus
                                                datos en el siguiente <a href="https://xpres.com.uy/como-funciona">
                                                    formulario</a>
                                                y un ejecutivo comercial te contactará a la brevedad para asesorarte. </p>
                                        </div>
                                    </li> 
                                    <li class="mt-3">
                                        <div id="heading-27">
                                        <button class="btn btn-block px-0 text-dark font-weight-bolder text-left collapsed rounded-0" type="button" data-toggle="collapse" data-target="#collapse-27" aria-expanded="true" aria-controls="collapse-27">
                                            ¿Si tengo alguna duda donde me puedo comunicar?
                                        </button>
                                        </div>
                                        <div id="collapse-27" class="collapse" aria-labelledby="heading-27" data-parent="#faq-accordion">
                                            <p class="text-black">
                                                Ingresando en el siguiente <a href="https://xpres.com.uy/section-contact">
                                                    link </a> podrás hacernos llegar tu
                                                consulta, un representante de
                                                servicios al cliente especializado te asistirá a la brevedad.<br><br> Xpres! es
                                                una asociación de UES y Intersiglo. </p>
                                        </div>
                                    </li> 
                                  </ol>
                                    
                                </div><br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
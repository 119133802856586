<div class="container-fluid">
  <div class="row" *ngIf="loading">
    <div class="col-md-12">
      <div class="spinner-container w-100">
        <img src="../../assets/images/loader.gif" alt="">
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!loading">
    <div class="col-md-12">
      <ng-container *ngIf="data.length === 0">
        <span>No hay datos para mostrar...</span>
      </ng-container>
      <ng-container *ngIf="data.length > 0">
        <div *ngIf="history.length === 0">
          <span>No se encontraron datos para el número especificado...</span>
        </div>
        <table class="w-100 table table-striped table-borderless" *ngIf="history.length > 0">
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Estado</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of history">
              <td>{{row.fecha}}</td>
              <td class="text-left">{{row.estado}}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </div>
  </div>
</div>

<section id="section-list">
  <div>
    <perfect-scrollbar [config]="config" class="scroller">
      <div class="list-group">
        <ng-container *ngFor="let address of addresses">
          <a href="#" class="list-group-item flex-column align-items-start" (click)="edit(address)">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{address.name}}</h5>
            </div>
            <p class="list-group-item-text">
              {{address.state.description}}, {{address.city.description}}<br>
              {{address.street}} {{address.doorNumber}} {{address.otherInfo}}
            </p>
            <small *ngIf="address.phone"><i class="fa fa-phone"></i> {{address.phone}}</small>
          </a>
        </ng-container>
      </div>
    </perfect-scrollbar>
  </div>
  <footer>
    <div class="row">
      <div class="col-md-12 text-right pt-2">
        <a class="btn btn-primary" data-toggle="collapse" href="#collapseTwo" role="button" aria-expanded="false"
          aria-controls="Guardar" (click)="addNew()" id="addAddress">
          Nuevo
        </a>
      </div>
    </div>
  </footer>
</section>

<section style="display:none" id="section-form">
  <div class="container-fluid px-0">
    <div class="row">
      <div class="col-md-12">
        <form [formGroup]="addressForm" id="addressForm" accept-charset="UTF-8" novalidate>

          <div class="row">
            <div class="col-md-6 col-sm-12">
              <div class="form-group">
                <label for="nombre" [ngClass]="{'error-message': formHasError('required', 'nombre')}">Nombre *</label>
                <input type="text" id="nombre" formControlName="nombre" class="form-control" required="required"
                  [ngClass]="{ 'is-invalid': formIsInvalid('nombre')}" maxlength="200"/>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6 col-sm-12">
              <label for="departamento" class="combo" [ngClass]="{'error-message': formHasError('required', 'state')}">
                Departamento *</label>
              <div class="form-group">
                <ng-select class="custom" [clearable]="false" (change)="onChangeDeposito($event)" formControlName="state"
                  name="departamento" required appendTo="#section-form" [ngClass]="{ 'is-invalid': formIsInvalid('state')}">
                  <ng-option *ngFor="let dep of addrDepartamentos$ | async" value="{{dep.id}}">
                    {{dep.nombre}}
                  </ng-option>
                </ng-select>
              </div>
            </div>

            <div class="col-md-6 col-sm-12">
              <label for="localidad" class="combo" [ngClass]="{'error-message': formHasError('required', 'city')}">
                Localidad *</label>
              <div class="form-group">
                <ng-select class="custom" formControlName="city" name="localidad" required appendTo="#section-form"
                  [ngClass]="{ 'is-invalid': formIsInvalid('city')}">
                  <ng-option *ngFor="let loc of addrLocalidades$ | async" value="{{loc.id}}">
                    {{loc.nombre}}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-8">
              <div class="form-group">
                <label for="calle" [ngClass]="{'error-message': formHasError('required', 'calle')}">
                  Calle *</label>
                <input type="text" id="calle" formControlName="calle" class="form-control" required="required"
                  [ngClass]="{ 'is-invalid': formIsInvalid('calle')}" maxlength="200"/>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label for="nroPuerta">Número de puerta</label>
                <input type="text" id="nroPuerta" formControlName="nroPuerta" class="form-control" maxlength="20"/>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="adicional">Datos adicionales</label>
                <input type="text" id="adicional" formControlName="adicional" class="form-control"
                  placeHolder="Apto, oficina, etc" maxlength="60"/>
              </div>
            </div>
            <div class="col-md-6">
              <div class="form-group">
                <label for="telefono">Teléfono</label>
                <input type="text" id="telefono" formControlName="telefono" class="form-control" maxlength="30"/>
              </div>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>

  <footer>
    <div class="row">
      <div class="col-md-12 text-right pt-2">
        <a class="btn btn-outline-danger mr-3" data-toggle="collapse" href="#collapseTwo" role="button"
          aria-expanded="false" aria-controls="Eliminar" (click)="delete()" id="deleteAddress">
          <i class="fa fa-trash-o"></i>
          <span>Eliminar</span>
        </a>
        <a class="btn btn-secondary mr-1" data-toggle="collapse" href="#collapseTwo" role="button" aria-expanded="false"
          aria-controls="Eliminar" (click)="cancel()" id="cancelAddress">
          <i class="fa fa-remove"></i>
          <span>Cancelar</span>
        </a>
        <a class="btn btn-primary" data-toggle="collapse" href="#collapseTwo" role="button" aria-expanded="false"
          aria-controls="Guardar" (click)="save()" id="saveAddress">
          <i class="fa fa-save"></i>
          <span>Guardar</span>
        </a>
      </div>
    </div>
  </footer>
</section>

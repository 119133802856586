import { NotificationService } from "./../../shared/services/notification.service";
import { TarifasService } from "./../tarifas/tarifas.service";
import { DOCUMENT } from "@angular/common";
import {
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { NgSelectComponent } from "@ng-select/ng-select";
import { forkJoin, Observable, Subject } from "rxjs";
import { takeUntil, switchMap, finalize } from "rxjs/operators";
import { BsEventsService } from "src/app/shared/services/bs-events.service";
import { ResponsiveService } from "src/app/shared/services/responsive.service";
import { AuthService } from "./../../shared/auth/auth.service";
import { AddressService } from "./../user/addresses/address.service";
import { EnvioService } from "./envio.service";
import { IShipment, Shipment } from "./shipment.model";
import { ModalDataService } from "src/app/shared/services/modal-data.service";
import XpresUtils from "src/app/shared/xpres.util";
import { environment } from "../../../environments/environment";

declare var $: any;

@Component({
  selector: "app-envio",
  templateUrl: "./envio.component.html",
  styleUrls: ["./envio.component.scss"],
  // encapsulation: ViewEncapsulation.None
})
export class EnvioComponent implements OnInit, OnDestroy {
  readonly TAB_SENDER = 0;
  readonly TAB_RECIPIENT = 1;
  readonly TAB_SHIPMENT = 2;
  readonly TAB_PAYMENT = 3;

  _isMobile = false;
  senderForm: FormGroup;
  recipientForm: FormGroup;
  shipForm: FormGroup;
  paymentForm: FormGroup;
  chooseXpres = false;

  departamentos$: Observable<Array<any>>;
  localidadesOrigen$: Observable<Array<any>>;
  localidadesDestino$: Observable<Array<any>>;
  categories$: Observable<Array<any>>;
  pickupCenters$: Observable<Array<any>>;
  servicios$: Observable<Array<any>>;

  addresses: any[] = [];
  callingMP = false;
  saveCurrentAddress = false;
  saveAsName = "";

  step1Invalid = false;
  step2Invalid = false;
  step3Invalid = false;
  step4Invalid = false;

  @ViewChild("service") cmbService: NgSelectComponent;
  @ViewChild("recipientDept") cmbRecipientDept: NgSelectComponent;
  @ViewChild("category") cmbCategory: NgSelectComponent;

  tarifaAplicada: any = null;

  isValidCouponCode: boolean = false;
  isCouponRedeemed: boolean = null;
  coupon: { description: string; error: string } = {
    description: "",
    error: "",
  };

  @Output()
  shipEvent = new EventEmitter<IShipment>();

  data: IShipment = null;
  currentUser: any = null;
  firstTabSelected = 1;

  typeXpres: any = null;
  typeStandard: any = null;

  paramEnvioId: string = null;
  paramStatus: string = null;

  cargandoDatos = true;
  integrando = false;
  integrationInfo: any = null;

  collectionId: string = null;
  collectionStatus: string = null;
  merchantOrderId: string = null;
  paymentType: string = null;

  // Private
  private _unsubscribeAll: Subject<any>;

  constructor(
    private _formBuilder: FormBuilder,
    private _envioService: EnvioService,
    private bsEventsService: BsEventsService,
    private _responsiveService: ResponsiveService,
    private route: ActivatedRoute,
    private router: Router,
    private _auth: AuthService,
    private _addressService: AddressService,
    private _notificationService: NotificationService,
    private _tarifasService: TarifasService,
    private _modalDataService: ModalDataService,
    @Inject(DOCUMENT) private document: any
  ) {
    this._unsubscribeAll = new Subject();

    this.data = new Shipment();

    this._responsiveService
      .getBreakpoint()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((breackpoint) => {
        this._isMobile = breackpoint === "xs";
      });

    this.departamentos$ = this._envioService.getDepartamentos();
    this.localidadesOrigen$ = this._envioService.getLocalidades("-1");
    this.localidadesDestino$ = this._envioService.getLocalidades("-1");
    this.categories$ = this._envioService.getCategories();
    this.pickupCenters$ = this._envioService.getPickupCenters("-1");
    this.servicios$ = this._envioService.getServicios("-1", "-1");
  }

  ngOnInit(): void {
    this._buildForms();
    this._initSubscribers();

    this.route.queryParamMap.subscribe((queryParams) => {
      this.route.firstChild.paramMap.subscribe((childParams) => {
        const id = this._modalDataService.getData();

        this.paramEnvioId = childParams.get("id");
        this.paramStatus = childParams.get("status");

        const params = Object.assign(
          {
            envioId: this.paramEnvioId,
            status: !isNaN(parseInt(this.paramStatus, 10))
              ? parseInt(this.paramStatus, 10)
              : null,
          },
          queryParams
        );

        // console.log('queryParams', queryParams['params']);
        if (XpresUtils.isValidUuid(params.envioId) && params.status !== 0) {
          if (environment.integrateRejectedPackages === 1) {
            params.status = 0;
          }
        }

        this.collectionId = queryParams["params"]["collection_id"] || null;
        this.collectionStatus =
          queryParams["params"]["collection_status"] || null;
        this.merchantOrderId =
          queryParams["params"]["merchant_order_id"] || null;
        this.paymentType = queryParams["params"]["payment_type"] || null;

        // Si tenemos el id del envio en la url, pero no lo recibimos como dato del modal, lo descartamos
        if (
          XpresUtils.isValidUuid(params.envioId) &&
          !XpresUtils.isValidUuid(id)
        ) {
          params.envioId = null;
          params.status = null;
        }
        if (XpresUtils.isValidUuid(params.envioId) && params.status === 0) {
          this.cargandoDatos = false;
        }

        this._envioService.cargarEnvio(params).subscribe((response) => {
          if (response) {
            this.data = response;
          }

          this._auth.currentUser.subscribe((user) => {
            const useSavedData = XpresUtils.isValidUuid(params.envioId);
            this.currentUser = user;
            this._initializeSender(useSavedData);

            this.loadValues(useSavedData);

            if (this.currentUser) {
              this._addressService
                .getAddresses((this.currentUser || {})["id"])
                .subscribe((result) => {
                  this.addresses = result.body["direcciones"];
                });
            }
          });

          if (params.status === 0) {
            this.integrando = true;
            this._envioService
              .integrarEnvio(params)
              .pipe(
                finalize(() => {
                  this.integrando = false;
                })
              )
              .subscribe((info) => {
                this.integrationInfo = info;

                // Cambio el API externa, asi que metemos un hack
                if (!info.etiquetaURL && info.envio.pdf && info.envio.pdf) {
                  this.integrationInfo.etiquetaURL = info.envio.pdf;
                }
              });
          }
        });
      });
    });
  }

  _initSubscribers(): void {
    this.recipientForm
      .get("useXpresCenter")
      .valueChanges.pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        this._useXpresCenterHandler(data);
      });

    this.bsEventsService
      .onCollapsibleHidden()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((event: any) => {
        const elementId = event.target.id;

        if (elementId === "collapseOne") {
          this.setValidationStatus(this.TAB_RECIPIENT, true);
        } else if (elementId === "collapseTwo") {
          this.setValidationStatus(this.TAB_SHIPMENT, true);
        } else if (elementId === "collapseThree") {
          this.setValidationStatus(this.TAB_PAYMENT, true);
        }
      });

    this.bsEventsService
      .onCollapsibleShown()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((event: any) => {
        const elementId = event.target.id;
        let formIndex = this.TAB_SENDER;
        const forms = [];

        if (elementId === "collapseTwo") {
          forms.push(this.senderForm);
          formIndex = this.TAB_SENDER;
        } else if (elementId === "collapseThree") {
          forms.push(this.senderForm, this.recipientForm);
          formIndex = this.TAB_RECIPIENT;
        } else if (elementId === "collapseFour") {
          forms.push(this.senderForm, this.recipientForm, this.shipForm);
          formIndex = this.TAB_SHIPMENT;
        }

        forms.forEach((form) => {
          Object.keys(form.controls).forEach((key) => {
            // form.controls[key].markAsTouched();

            form.controls[key].updateValueAndValidity();
          });
        });
        this.setValidationStatus(formIndex);
      });

    this._envioService.getTypes().subscribe((data) => {
      data.forEach((type) => {
        if (type["codigo"] === "xpres") {
          this.typeXpres = type;
        } else if (type["codigo"] === "domicilio") {
          this.typeStandard = type;
        }
      });
    });
  }

  _loadFinalInfo() {
    const serviceId = this.shipForm.get("service").value || "-1";
    const recipientDeptId = this.recipientForm.get("state").value || "-1";
    const categoryId = this.shipForm.get("category").value || "-1";

    this._tarifasService
      .obtenerTarifa(serviceId, categoryId, recipientDeptId)
      .subscribe(
        (result) => {
          this.tarifaAplicada = result;
          this.resetCouponCodeControl();
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  _buildForms(): void {
    this.senderForm = this._formBuilder.group({
      id: [""],
      name: [
        this.data.sender.name,
        [Validators.required, Validators.pattern(/.*\S.*/)]
      ],
      email: [this.data.sender.email, [Validators.required, Validators.email]],
      phone: [
        this.data.sender.phoneNumber,
        [Validators.required, Validators.pattern(/.*\S.*/)]
      ],
      state: [null, Validators.required],
      city: [null, Validators.required],
    });

    this.recipientForm = this._formBuilder.group({
      name: [
        this.data.recipient.name,
        [Validators.required, Validators.pattern(/.*\S.*/)]
      ],
      phone: [
        this.data.recipient.phoneNumber,
        [Validators.required, Validators.pattern(/.*\S.*/)]
      ],
      email: [
        this.data.recipient.email,
        [Validators.email, Validators.required, Validators.pattern(/.*\S.*/)]
      ],
      state: [null, Validators.required],
      city: [null, Validators.required],
      useXpresCenter: [{ value: true }],
      pickupCenter: [null, Validators.required],
      street: [this.data.recipient.street],
      doorNumber: [this.data.recipient.doorNumber],
      otherInfo: [this.data.recipient.otherInfo],
      comments: [this.data.recipient.comments],
      addresses: [null],
    });

    this.shipForm = this._formBuilder.group({
      category: [this.data.shipmentDetails.category, Validators.required],
      service: [this.data.shipmentDetails.service],
      reference: [this.data.shipmentDetails.reference],
      comments: [this.data.shipmentDetails.comments],
    });

    this.paymentForm = this._formBuilder.group({
      id: [],
      name: ["", [Validators.required, Validators.pattern(/.*\S.*/)]],
      surname: ["", [Validators.required, Validators.pattern(/.*\S.*/)]],
      email: ["", [Validators.required, Validators.email]],
      rut: [this.data.payment.rut],
      couponCode: ""
    });
  }

  _useXpresCenterHandler(value: any): void {
    const pickupCenterControl = this.recipientForm.get("pickupCenter");
    const streetControl = this.recipientForm.get("street");
    const doorNumberControl = this.recipientForm.get("doorNumber");

    if (!value) {
      $("#rowDireccion").show("fast");
      pickupCenterControl.setValidators([]);
      pickupCenterControl.setValue(null);
      pickupCenterControl.disable();

      streetControl.setValidators([
        Validators.required,
        Validators.pattern(/.*\S.*/)
      ]);
      doorNumberControl.setValidators([
        Validators.required,
        Validators.pattern(/.*\S.*/)
      ]);
    } else {
      $("#rowDireccion").hide("fast");
      pickupCenterControl.enable();
      pickupCenterControl.setValidators([Validators.required]);
      doorNumberControl.setValidators([]);
      streetControl.setValidators([]);
    }

    pickupCenterControl.updateValueAndValidity();
    streetControl.updateValueAndValidity();

    this._updateService()
      .then(() => {
        this._loadFinalInfo();
      })
      .catch((err) => console.log);
  }

  _updateService(): Promise<void> {
    const departamentoOrigenId = this.senderForm.get("state").value || "-1";
    const departamentoDestinoId = this.recipientForm.get("state").value || "-1";
    const useXpresCenter = this.recipientForm.get("useXpresCenter").value;

    return new Promise((resolve, reject) => {
      this.shipForm.get("service").reset();
      this._envioService
        .getServicios(departamentoOrigenId, departamentoDestinoId)
        .subscribe(
          (servicios) => {
            let currentService: any = null;
            let firstService: any = null;

            servicios.forEach((servicio) => {
              if (firstService === null) {
                firstService = servicio;
              }

              if (
                servicio.lugarDeEntrega === "xpres" &&
                useXpresCenter === true
              ) {
                currentService = servicio;
              } else {
                if (
                  servicio.lugarDeEntrega === "domicilio" &&
                  useXpresCenter === false
                ) {
                  currentService = servicio;
                }
              }
            });
            if (currentService === null && firstService !== null) {
              currentService = firstService;
            }

            if (currentService !== null) {
              if (this.shipForm.get("service").value !== currentService.id) {
                this.shipForm.get("service").setValue(currentService.id);
              }
            }
            resolve();
          },
          (err) => {
            reject(err);
          }
        );
    });
  }

  _initializeSender(useSavedData: boolean = false) {
    if (this.currentUser) {
      const fullName = (
        (this.currentUser.firstName || "").trim() +
        " " +
        (this.currentUser.lastName || "").trim()
      ).trim();
      this.data.sender["name"] = fullName;
      this.data.sender["email"] = this.currentUser.email;
      this.data.sender["phoneNumber"] = this.currentUser.phoneNumber || "";
      if (this.currentUser["state"]) {
        this.data.sender["state"] = {
          id: this.currentUser["state"]["id"],
          description: this.currentUser["state"]["description"],
        };
      }
      if (this.currentUser["city"]) {
        this.data.sender["city"] = {
          id: this.currentUser["city"]["id"],
          description: this.currentUser["city"]["description"],
        };
      }

      if (!useSavedData) {
        this.data.payment.name = this.currentUser.firstName || "";
        this.data.payment.surname = this.currentUser.lastName || "";
        this.data.payment.email = this.currentUser.email;
        if (this.currentUser["tipoFiscal"]["codigo"] == "J") {
          this.data.payment.rut = this.currentUser["documentNumber"];
        }
      }
    }
  }

  loadValues(reentry: boolean = false): Promise<void> {
    return new Promise((resolve, reject) => {
      this.departamentos$.subscribe(
        () => {
          const sender = this.data.sender;
          const recipient = this.data.recipient;
          const shipment = this.data.shipmentDetails;
          const payment = this.data.payment;

          if (sender !== null) {
            this.senderForm.controls["name"].setValue(sender.name);
            this.senderForm.controls["email"].setValue(sender.email);
            this.senderForm.controls["phone"].setValue(sender.phoneNumber);
          }

          if (recipient !== null) {
            this.recipientForm.get("name").setValue(recipient.name);
            this.recipientForm.get("email").setValue(recipient.email);
            this.recipientForm.get("phone").setValue(recipient.phoneNumber);
            if (this.chooseXpres) {
              this.recipientForm.get("street").setValue(recipient.street);
              this.recipientForm
                .get("doorNumber")
                .setValue(recipient.doorNumber);
            }
            this.recipientForm.get("otherInfo").setValue(recipient.otherInfo);
            this.recipientForm.get("comments").setValue(recipient.comments);
          }

          if (shipment !== null) {
            this.shipForm.get("reference").setValue(shipment.reference);
            this.shipForm.get("category").setValue(shipment.category);
            this.shipForm.get("service").setValue(shipment.service);
            this.shipForm.get("comments").setValue(shipment.comments);
          }

          if (payment !== null) {
            this.paymentForm.get("name").setValue(payment.name);
            this.paymentForm.get("surname").setValue(payment.surname);
            this.paymentForm.get("email").setValue(payment.email);
            if (payment.rut) this.paymentForm.get("rut").setValue(payment.rut);
          }

          const observers: Observable<any>[] = [this.categories$];
          if (this.data.sender.state !== null) {
            this.localidadesOrigen$ = this._envioService.getLocalidades(
              this.data.sender.state.id
            );
            observers.push(this.localidadesOrigen$);
          }
          if (this.data.recipient.state !== null) {
            const departamentoOrigenId = this.data.sender.state
              ? this.data.sender.state.id
              : "-1";
            const departamentoDestinoId = this.data.recipient.state
              ? this.data.recipient.state.id
              : "-1";

            this.localidadesDestino$ = this._envioService.getLocalidades(
              this.data.recipient.state.id
            );
            this.servicios$ = this._envioService.getServicios(
              departamentoOrigenId,
              departamentoDestinoId
            );
            this.pickupCenters$ = this._envioService.getPickupCenters(
              this.data.recipient.state.id
            );
            observers.push(
              this.localidadesDestino$,
              this.servicios$,
              this.pickupCenters$
            );
          }

          if (observers.length > 0) {
            forkJoin(observers).subscribe(() => {
              if (this.data.sender.state !== null) {
                this.senderForm
                  .get("state")
                  .setValue(this.data.sender.state.id);
                if (this.data.sender.city !== null) {
                  this.senderForm
                    .get("city")
                    .setValue(this.data.sender.city.id);
                }
              }

              if (this.data.recipient.state !== null) {
                this.recipientForm
                  .get("state")
                  .setValue(this.data.recipient.state.id);
                if (this.data.recipient.city !== null) {
                  this.recipientForm
                    .get("city")
                    .setValue(this.data.recipient.city.id);
                }

                if (this.data.shipmentDetails.service !== null) {
                  this.shipForm
                    .get("service")
                    .setValue(this.data.shipmentDetails.service.id);
                }
              }

              if (this.data.recipient.pickupCenter !== null) {
                this.recipientForm.get("useXpresCenter").setValue(true);
                this.recipientForm
                  .get("pickupCenter")
                  .setValue(this.data.recipient.pickupCenter.id);
                this.chooseXpres = true;
                this._useXpresCenterHandler(true);
              } else {
                this.recipientForm.get("useXpresCenter").setValue(false);
                this.chooseXpres = true;
                this._useXpresCenterHandler(false);
              }

              if (this.data.shipmentDetails.category !== null) {
                this.shipForm
                  .get("category")
                  .setValue(this.data.shipmentDetails.category.id);
              }

              if (this.senderForm.valid) {
                this.firstTabSelected = 2;
              }

              if (reentry === true) {
                this.firstTabSelected = 4;
                const forms = [
                  this.senderForm,
                  this.recipientForm,
                  this.shipForm,
                  this.paymentForm,
                ];
                forms.forEach((form) => {
                  Object.keys(form.controls).forEach((key) => {
                    form.controls[key].markAsTouched();
                    form.controls[key].updateValueAndValidity();
                  });
                });
              }

              resolve();
            }, reject);
          } else {
            resolve();
          }
        },
        (err) => reject(err)
      );
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  setValidationStatus(step: number, force: boolean = false): void {
    if (step === this.TAB_RECIPIENT) {
      this.step1Invalid =
        this.senderForm.invalid && (this.senderForm.touched || force);
      Object.keys(this.senderForm.controls).forEach((key) => {
        // this.senderForm.controls[key].markAsTouched();
        this.senderForm.controls[key].updateValueAndValidity();
      });
    } else if (step === this.TAB_SHIPMENT) {
      this.step1Invalid =
        this.senderForm.invalid && (this.senderForm.touched || force);
      this.step2Invalid =
        this.recipientForm.invalid && (this.recipientForm.touched || force);
      Object.keys(this.recipientForm.controls).forEach((key) => {
        this.recipientForm.controls[key].markAsTouched();
        this.recipientForm.controls[key].updateValueAndValidity();
      });
    } else if (step === this.TAB_PAYMENT) {
      this.step1Invalid =
        this.senderForm.invalid && (this.senderForm.touched || force);
      this.step2Invalid =
        this.recipientForm.invalid && (this.recipientForm.touched || force);
      this.step3Invalid =
        this.shipForm.invalid && (this.shipForm.touched || force);
      Object.keys(this.shipForm.controls).forEach((key) => {
        this.shipForm.controls[key].markAsTouched();
        this.shipForm.controls[key].updateValueAndValidity();
      });
    }
    this.step4Invalid =
      this.step1Invalid || this.step2Invalid || this.step3Invalid;
  }

  senderIsInvalid(name: string): boolean {
    return this._formIsInvalid(this.senderForm, name);
  }

  recipientIsInvalid(name: string): boolean {
    return this._formIsInvalid(this.recipientForm, name);
  }

  shipmentIsInvalid(name: string): boolean {
    return this._formIsInvalid(this.shipForm, name);
  }

  paymentIsInvalid(name: string): boolean {
    return this._formIsInvalid(this.paymentForm, name);
  }

  _formIsInvalid(form: FormGroup, name): boolean {
    if (form.controls && form.controls[name]) {
      if (form.controls[name]["errors"] && !form.controls[name]["untouched"]) {
        return true;
      }
    }
    return false;
  }
  senderHasRut(): boolean {
    if (this.currentUser["tipoFiscal"]["codigo"] == "J") {
      return true;
    }
  }

  paymentHasLastname(): boolean {
    if (
      this.paymentForm.controls["surname"].value.length !== 0 &&
      this.paymentForm.controls["surname"].value
    )
      return true;
  }

  senderHasError(error: string, fieldName: string): boolean {
    return this._formHasError(this.senderForm, error, fieldName);
  }
  recipientHasError(error: string, fieldName: string): boolean {
    return this._formHasError(this.recipientForm, error, fieldName);
  }

  shipmentHasError(error: string, fieldName: string): boolean {
    return this._formHasError(this.shipForm, error, fieldName);
  }

  paymentHasError(error: string, fieldName: string): boolean {
    return this._formHasError(this.paymentForm, error, fieldName);
  }

  _formHasError(form: FormGroup, error: string, fieldName: string): boolean {
    if (form.controls && form.controls[fieldName]) {
      if (
        form.controls[fieldName].hasError(error) &&
        !form.controls[fieldName].untouched
      ) {
        return true;
      }
    }
    return false;
  }

  pickupSelectorDisabled(): boolean {
    return !this.recipientForm.controls["useXpresCenter"].value;
  }

  onChangeDireccion(addrId: string): void {
    if (addrId) {
      this.addresses.forEach((address) => {
        if (address.id === addrId) {
          this.recipientForm.get("phone").setValue(address["phone"]);
          this.recipientForm.get("street").setValue(address["calle"]);
          this.recipientForm.get("doorNumber").setValue(address["doorNumber"]);
          this.recipientForm.get("otherInfo").setValue(address["otherInfo"]);

          if (
            address.localidad != null &&
            address.localidad.departamento !== null
          ) {
            const departamentoDestinoId = address.localidad.departamento.id;
            const departamentoOrigenId =
              this.senderForm.controls["state"].value;

            this.recipientForm.get("state").setValue(departamentoDestinoId);
            if (address.localidad !== null) {
              this.recipientForm.get("city").setValue(address.localidad.id);
            }
            this.localidadesDestino$ = this._envioService.getLocalidades(
              departamentoDestinoId
            );
            this.pickupCenters$ = this._envioService.getPickupCenters(
              departamentoDestinoId
            );
            this.servicios$ = this._envioService.getServicios(
              departamentoOrigenId,
              departamentoDestinoId
            );
          } else {
            this.recipientForm.get("state").reset();
            this.recipientForm.get("city").reset();
            this.recipientForm.get("pickupCenter").reset();
          }
        }
      });
    }
  }

  onChangeDepOrigen(depId: string): void {
    this.senderForm.controls["city"].reset();
    this.localidadesOrigen$ = this._envioService.getLocalidades(depId);
    this.shipForm.controls["service"].reset();

    const departamentoDestinoId = this.recipientForm.controls["state"].value;
    this.servicios$ = this._envioService.getServicios(
      depId,
      departamentoDestinoId
    );
    this._updateService()
      .then(() => {
        this._loadFinalInfo();
      })
      .catch((err) => console.log);
  }

  onChangeDepDestino(depId: string): void {
    this.recipientForm.controls["city"].reset();
    this.recipientForm.controls["pickupCenter"].reset();
    this.shipForm.controls["service"].reset();

    this.localidadesDestino$ = this._envioService.getLocalidades(depId);
    this.pickupCenters$ = this._envioService.getPickupCenters(depId);

    const departamentoOrigenId = this.senderForm.controls["state"].value;
    this.servicios$ = this._envioService.getServicios(
      departamentoOrigenId,
      depId
    );
    this._updateService()
      .then(() => {
        this._loadFinalInfo();
      })
      .catch((err) => console.log);
  }

  onChangeCategory(categoryId: string): void {
    this._loadFinalInfo();
  }

  onChangeService(serviceId: string): void {
    this._loadFinalInfo();
  }

  onRedeemCoupon() {
    const couponCode = this.paymentForm.controls["couponCode"].value;
    const userId = this.currentUser.id;

    if (!couponCode) {
      this.resetCouponCodeControl();
      return;
    }

    this._envioService.redeemCoupon(couponCode, userId).subscribe(
      (coupon) => {
        const amount = this.tarifaAplicada.importe;
        const discount = coupon.discount;

        // Set new price.
        this.tarifaAplicada.importe = this.calculatePrice(amount, discount);
        this.isValidCouponCode = true;

        // Set coupon description.
        this.coupon.description = coupon.description;

        // Disable coupon code input.
        const couponCodeInput = this.paymentForm.get("couponCode");
        couponCodeInput.disable();

        // Disable canjear button.
        this.isCouponRedeemed = true;

        // Reset error message.
        this.coupon.error = "";
      },
      (error) => {
        console.log("Hubo un error:", error.message);
        // Set error message.
        this.isValidCouponCode = false;
        this.coupon.error = error.message;
      }
    );
  }

  private resetCouponCodeControl(): void {
    // Enable coupon code input.
    const couponCodeInput = this.paymentForm.get("couponCode");
    couponCodeInput.enable();
    couponCodeInput.setValue("");

    // Enable canjear button.
    this.isCouponRedeemed = false;

    // Reset messages.
    this.coupon.description = "";
    this.coupon.error = "";
  }

  _getDataFromAllForms(): IShipment {
    const shipment = new Shipment();
    shipment.sender = {
      name: this.senderForm.controls["name"].value,
      email: this.senderForm.controls["email"].value,
      phoneNumber: this.senderForm.controls["phone"].value,
      state: {
        id: this.senderForm.controls["state"].value,
      },
      city: {
        id: this.senderForm.controls["city"].value,
      },
    };

    shipment.recipient = {
      name: this.recipientForm.controls["name"].value,
      phoneNumber: this.recipientForm.controls["phone"].value,
      email: this.recipientForm.controls["email"].value,
      state: {
        id: this.recipientForm.controls["state"].value,
      },
      city: {
        id: this.recipientForm.controls["city"].value,
      },
      comments: this.recipientForm.controls["comments"].value,
      saveCurrentAddress: this.saveCurrentAddress,
      saveAsName: this.saveAsName,
    };

    let type: any = null;
    if (this.recipientForm.controls["useXpresCenter"].value) {
      shipment.recipient.pickupCenter = {
        id: this.recipientForm.controls["pickupCenter"].value,
      };
      type = this.typeXpres;
    } else {
      shipment.recipient.street = this.recipientForm.controls["street"].value;
      shipment.recipient.doorNumber =
        this.recipientForm.controls["doorNumber"].value;
      shipment.recipient.otherInfo =
        this.recipientForm.controls["otherInfo"].value;
      type = this.typeStandard;
    }

    shipment.shipmentDetails = {
      type: type,
      reference: this.shipForm.controls["reference"].value,
      category: {
        id: this.shipForm.controls["category"].value,
      },
      comments: this.shipForm.controls["comments"].value,
      service: {
        id: this.shipForm.controls["service"].value,
      },
    };

    shipment.payment = {
      name: this.paymentForm.get("name").value,
      surname: this.paymentForm.get("surname").value,
      email: this.paymentForm.get("email").value,
      amount: this.tarifaAplicada.importe,
      couponCode: this.isValidCouponCode
        ? this.paymentForm.get("couponCode").value
        : null
    };

    return shipment;
  }

  finalizar(): void {
    this.setValidationStatus(this.TAB_PAYMENT, true);
    if (
      this.senderForm.valid &&
      this.recipientForm.valid &&
      this.shipForm.valid &&
      this.paymentForm.valid
    ) {
      const envio = this._getDataFromAllForms();
      $("#btn-finish")
        .removeClass("btn-primary")
        .addClass("btn-secondary")
        .html("cargando...");
      this.callingMP = true;

      this._envioService.guardarEnvio(envio).subscribe((data) => {
        // console.log(data)
        if (data["internal"] === true) {
          this.document.location.href = data["url"];
          // this.router.navigate([data.url], {
          //   queryParams: {
          //     refresh: new Date().getTime(),
          //     collection_id: null,
          //     collection_status: null,
          //     preference_id: 'n/a',
          //     external_reference: 'n/a',
          //     payment_type: 'credit_card',
          //     merchant_order_id: 'null'
          //   }
          // });
        } else {
          this.document.location.href = environment.production
            ? data["init_point"]
            : data["sandbox_init_point"];
        }
      });
    }
  }

  isAuthenticated(): boolean {
    return this._auth.isLoggedIn();
  }

  getPaymentMethod(): string {
    let paymentMethod = "";

    if (this.paymentType) {
      if (this.paymentType === "credit_card") {
        paymentMethod = "Tarjeta de crédito";
      } else if (this.paymentType === "ticket") {
        paymentMethod = "Abitab/Red Pagos";
      } else if (this.paymentType === "account_money") {
        paymentMethod = "Depósito bancario";
      } else {
        paymentMethod = this.paymentType || "";
      }
    }
    return paymentMethod;
  }

  toggleAddressName(enabled): void {
    $("#saveAsName").val(enabled ? this.senderForm.get("name").value : "");
  }

  calculatePrice(price, discount): number {
    if (!discount) {
      return price;
    }

    const [discountType, value] = [discount[0], discount.slice(1)];

    if (discountType !== "%" && discountType !== "$") {
      throw new Error("The only discount types allowed are % and $.");
    }

    switch (discountType) {
      case "%":
        return price - (price * (value / 100));
      case "$":
        return price - value;
      default:
        return price;
    }
  }
}

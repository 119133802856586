/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./user.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../profile/profile.component.ngfactory";
import * as i3 from "../profile/profile.component";
import * as i4 from "@angular/forms";
import * as i5 from "../../../shared/auth/auth.service";
import * as i6 from "../user.service";
import * as i7 from "../../../shared/services/notification.service";
import * as i8 from "./user.component";
import * as i9 from "ngx-bootstrap/modal";
import * as i10 from "../../../shared/services/responsive.service";
var styles_UserComponent = [i0.styles];
var RenderType_UserComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_UserComponent, data: {} });
export { RenderType_UserComponent as RenderType_UserComponent };
function View_UserComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h5", [["class", "modal-title"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Usuario"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close"], ["data-dismiss", "modal"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.bsModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "app-profile", [["class", "w-100"]], null, null, null, i2.View_ProfileComponent_0, i2.RenderType_ProfileComponent)), i1.ɵdid(8, 245760, null, 0, i3.ProfileComponent, [i4.FormBuilder, i5.AuthService, i6.UsuarioService, i7.NotificationService], null, null)], function (_ck, _v) { _ck(_v, 8, 0); }, null); }
export function View_UserComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { elementView: 0 }), (_l()(), i1.ɵand(0, [[1, 2], ["usuarioTemplate", 2]], null, 0, null, View_UserComponent_1))], null, null); }
export function View_UserComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-user", [], null, null, null, View_UserComponent_0, RenderType_UserComponent)), i1.ɵdid(1, 245760, null, 0, i8.UserComponent, [i5.AuthService, i9.BsModalService, i10.ResponsiveService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserComponentNgFactory = i1.ɵccf("app-user", i8.UserComponent, View_UserComponent_Host_0, {}, {}, []);
export { UserComponentNgFactory as UserComponentNgFactory };

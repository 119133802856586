import { animate, style, transition, trigger } from '@angular/animations';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AuthService } from '../../../shared/auth/auth.service';
import { HeaderMenuService } from '../../../shared/services/header-menu.service';
import { ResponsiveService } from '../../../shared/services/responsive.service';
import { ScrollService } from '../../../shared/services/scroll.service';
import { AsistenteService } from '../../asistente/asistente.service';
import { HomeService } from '../../home/home.service';
import { LoginService } from './../../auth/login/login.service';
import { EnvioService } from '../../envio/envio.service';
import { NotificationService } from '../../../shared/services/notification.service';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('openClose', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('200ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('200ms', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {

  _isMobile = false;
  currentUser: any = null;
  _showAfterLogin = false;

  paramEnvioId: string = null;
  paramStatus: string = null;

  // @ViewChild(LoginComponent) login: LoginComponent;
  // @ViewChild(AsistenteComponent) asistente: AsistenteComponent;
  // @ViewChild(UserComponent) user: UserComponent;
  // @ViewChild(MisPaquetesComponent) misPaquetes: MisPaquetesComponent;
  // @ViewChild(LocalidadesComponent) localidades: LocalidadesComponent;
  // @ViewChild(PickupCentersComponent) centros: PickupCentersComponent;

  private _unsubscribeAll: Subject<any> = new Subject();

  mobileMenuDisplayed: boolean;

  @Output()
  showProfile = new EventEmitter();

  @Output()
  showPackages = new EventEmitter();

  @Output()
  showAdminLocalidades = new EventEmitter();

  //TODO saco esto porque no se va a usar
  // @Output()
  // showAdminPuntos = new EventEmitter();

  @Output()
  login = new EventEmitter();
  stickyHeader: boolean;

  constructor(
    private _responsiveService: ResponsiveService,
    private _auth: AuthService,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private _loginService: LoginService,
    private _envioService: EnvioService,
    private _scroll: ScrollService,
    private _menu: HeaderMenuService,
    private _asistente: AsistenteService,
    private home: HomeService,
    private _notificationService: NotificationService
  ) {
    // this._unsubscribeAll = new Subject();


  }

  ngOnInit(): void {

    this._responsiveService.getBreakpoint()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(breakpoint => {
        this._isMobile = breakpoint !== 'xl' && breakpoint !== 'lg';
      });

    this._loginService.onLoginChanged
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(mode => {

        if (mode === 'loggedin') {
          if (this._showAfterLogin === true) {
            setTimeout(() => {
              this._asistente.show(this.paramEnvioId);
            }, 500);
          }
          this._showAfterLogin = false;

        } else if (mode === 'close') {
          this._showAfterLogin = false;
        }

      });
    this._menu.getMobileMenuDisplayed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(displayed => {
        console.log('menu displayed: ', displayed);
        this.mobileMenuDisplayed = displayed;
      });


    this._refreshMobileMenu();

    this.paramEnvioId = this.route.snapshot.params.id;
    this.paramStatus = this.route.snapshot.params.status;

    console.log('EnvioId:' + this.paramEnvioId + ', status:' + this.paramStatus);

    this._auth.currentUser
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(user => {
        this.currentUser = user;
        this.ref.detectChanges();

        this._refreshMobileMenu();
      });

    if (this._auth.isLoggedIn()) {
      if (!(this.currentUser || {})['email']) {
        this._auth.refreshUser()
          .subscribe(() => this._refreshMobileMenu);
      }
    }

    if (this.paramEnvioId) {
      setTimeout(() => {
        this.crearEnvio();
      }, 500);
    }

    this._responsiveService.check();

    fromEvent(window, 'scroll')
      .pipe(
        debounceTime(100)
      )
      .subscribe(e => {
        console.log('scroll ', e);
        this.stickyHeader = window.pageYOffset > 100;
      });
  }

  _refreshMobileMenu(): void {
    if (this._auth.isLoggedIn()) {
      $('li.public').hide();
      $('li.private').show();
    } else {
      $('li.private').hide();
      $('li.public').show();
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // @HostListener('click', ['$event'])
  // onMouseEnter(event: any): void {
  //   const targetId = (event.target.id || '');

  //   if (targetId === 'logoutLink') {
  //     this.logout();
  //   } else if (targetId === 'profileLink') {
  //     this.showProfile();
  //   } else if (targetId === 'packagesLink') {
  //     this.showPackages();
  //   } else if (targetId === 'crearEnvio') {
  //     this.crearEnvio();
  //   }
  //   // this.hideMenu();
  // }

  getUserName(): string {
    let name = 'Usuario';
    if (this.currentUser !== null) {
      name = ((this.currentUser['firstName'] || '').trim() + ' ' + (this.currentUser['lastName'] || '').trim()).trim();
      if (name.length === 0) {
        name = this.currentUser.email;
      }
    }
    return name;
  }

  isAdmin(): boolean {
    if (this.currentUser) {
      return this.currentUser.isAdmin === true;
    }
    return false;
  }

  // showProfile(): void {
  //   // this.hideMenu();
  //   if (this._auth.isLoggedIn()) {
  //     this.user.show();
  //   }
  // }

  // showPackages(): void {
  //   // this.hideMenu();
  //   if (this._auth.isLoggedIn()) {
  //     this.misPaquetes.show();
  //   }
  // }

  // showAdminLocalidades(): void {
  //   // this.hideMenu();
  //   if (this._auth.isLoggedIn()) {
  //     this.localidades.show();
  //   }
  // }

  // showAdminPuntos(): void {
  //   // this.hideMenu();
  //   if (this._auth.isLoggedIn()) {
  //     this.centros.show();
  //   }
  // }

  logout(): void {
    this._auth.logout();
  }

  crearEnvio(): void {
    if (this._auth.isLoggedIn()) {
      this._asistente.show(this.paramEnvioId);
    } else {
      this._showAfterLogin = true;

      //If user is not logged in, still load envio and integrate it, since the status should change either way
      if(this.paramEnvioId  && this.paramStatus){

        const status = !isNaN(parseInt(this.paramStatus, 10))
        ? parseInt(this.paramStatus, 10)
        : null;

        this._envioService.cargarEnvio({status: status, envioId: this.paramEnvioId}).subscribe((response) => {
  
          if (response && status === 0) {
            this._envioService
              .integrarEnvio({status: status, envioId: this.paramEnvioId})
              .subscribe((info) => {
                if(info.msg == 'OK'){
                  this._notificationService.showInfo('El pago fue procesado correctamente. Ingrese y diríjase a `Ver envios`')
                }
              });
          }
        });
      }

      this._loginService.open();
      
    }
  }

  onOpenEnvio(): void {
    this.paramEnvioId = null;
  }

  onOpenMisPaquetes(): void { }

  onOpenLocalidades(): void { }

  onOpenCentros(): void { }

  scrollTo(name: string) {
    this._scroll.goToSection(name);
  }

  toggleMenu() {
    this._menu.setMobileMenuDisplayed(!this.mobileMenuDisplayed);
  }

  hideMenu() {
    this._menu.setMobileMenuDisplayed(false);
  }

  focusSearch(): void {
    this.home.focusSearchInput();
  }
}

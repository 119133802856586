<ul class="nav nav-tabs md-tabs" id="myTabMD" role="tablist">
  <li class="nav-item">
    <a class="nav-link active" id="home-tab-md" data-toggle="tab" href="#home-md" role="tab" aria-controls="home-md"
      aria-selected="true">
      <i class="fa fa-user"></i><span>Perfil</span>
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="addresses-tab-md" data-toggle="tab" href="#addresses-md" role="tab"
      aria-controls="addresses-md" aria-selected="false">
      <i class="fa fa-map"></i> <span>Direcciones</span>
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="password-tab-md" data-toggle="tab" href="#password-md" role="tab"
      aria-controls="password-md" aria-selected="false">
      <i class="fa fa-key"></i> <span>Contraseña</span>
    </a>
  </li>
</ul>
<div class="tab-content card form-container" id="myTabContentMD">
  <div class="tab-pane fade show active" id="home-md" role="tabpanel" aria-labelledby="home-tab-md">
    <div class="row">
      <div class="col-md-12 form-content">
        <form [formGroup]="form" id="form" accept-charset="UTF-8" novalidate>
          <div class="row">

            <div class="col-md-5">
              <div class="form-group">
                <label for="senderName">Nombres o Empresa *</label>
                <input type="text" id="firstName" formControlName="firstName" class="form-control" placeholder="Nombres"
                  required="required" [ngClass]="{ 'is-invalid': formIsInvalid('firstName')}"
                  maxlength="100" />

                <div class="error-message" *ngIf="formHasError('required', 'firstName')">
                  Debe ingresar algún nombre!
                </div>
              </div>
            </div><!-- Fin firstName -->

            <div class="col-md-4">
              <div class="form-group">
                <label for="senderName">Apellidos</label>
                <input type="text" id="lastName" formControlName="lastName" class="form-control"
                 placeholder="Apellidos" maxlength="100"/>
              </div>
            </div><!-- Fin lastName -->

            <div class="col-md-3">
              <div class="form-group">
                <label for="senderPhone">Teléfono *</label>
                <input type="tel" id="senderPhone" formControlName="phone" class="form-control" placeholder="Teléfono"
                  required="required" [ngClass]="{ 'is-invalid': formIsInvalid('phone')}"
                  maxlength="30"/>

                <div class="error-message" *ngIf="formHasError('required', 'phone')">
                  El teléfono es obligatorio!
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="fiscalType">Tipo fiscal *</label>
                <ng-select class="custom" [clearable]="false" formControlName="fiscalType" name="fiscalType"
                  id="fiscalType" required dropdownPosition="top" [ngClass]="{ 'is-invalid': formIsInvalid('fiscalType')}">
                  <ng-option *ngFor="let tipo of tiposFiscales$ | async" value="{{tipo.id}}">
                    {{tipo.nombre}}
                  </ng-option>
                </ng-select>
                <div class="error-message" *ngIf="formHasError('required', 'fiscalType')">
                  El tipo fiscal es obligatorio!
                </div>
              </div>
            </div>

            <div class="col-md-3">
              <div class="form-group">
                <label for="rut">RUT o CI *</label>
                <input type="tel" id="rut" formControlName="rut" class="form-control"
                  required="required" [ngClass]="{ 'is-invalid': formIsInvalid('rut')}"
                  maxlength="32" />

                <div class="error-message" *ngIf="formHasError('required', 'rut')">
                  El RUT o CI es obligatorio!
                </div>
              </div>
            </div>


            <div class="col-md-6">
              <div class="form-group">
                <label for="senderEmail">Dirección de email *</label>
                <input type="email" id="senderEmail" formControlName="email" class="form-control" placeholder="Email"
                  required="required" [ngClass]="{ 'is-invalid': formIsInvalid('email')}"
                  maxlength="100" />

                <div class="error-message" *ngIf="formHasError('required', 'email')">
                  La dirección de email es obligatoria!
                </div>
                <div class="error-message" *ngIf="!formHasError('required', 'email') && formHasError('email', 'email')">
                  La dirección de email no es correcta!
                </div>
              </div>
            </div>
          </div><!-- Fin email y telefono -->


          <div class="row">
            <div class="col-md-9">
              <div class="form-group">
                <label for="direccion">Dirección</label>
                <input type="text" id="direccion" formControlName="street" class="form-control"
                  placeholder="Dirección" maxlength="200"/>
              </div>
            </div><!-- Direccion -->

            <!-- <div class="col-md-3">
              <div class="form-group">
                <label for="nroPuerta">Nro puerta</label>
                <input type="text" id="nroPuerta" formControlName="nroPuerta" class="form-control" maxlength="20"/>
              </div>
            </div>
 -->
            <div class="col-md-3">
              <div class="form-group">
                <label for="adicionalDireccion">Datos adicionales</label>
                <input type="text" id="adicionalDireccion" formControlName="additional" class="form-control"
                  placeholder="Apto, esquina, etc..." maxlength="60"/>
              </div>
            </div>
          </div><!-- Datos adicionales de la direccion -->


          <div class="row">
            <div class="col-md-6">
              <label for="departamento" class="combo">Departamento</label>
              <div class="form-group">
                <ng-select class="custom" [clearable]="false" (change)="onChangeDepartamento($event)"
                  formControlName="state" name="departamento" dropdownPosition="top">
                  <ng-option *ngFor="let dep of departamentos$ | async" value="{{dep.id}}">
                    {{dep.nombre}}
                  </ng-option>
                </ng-select>
              </div>
            </div>

            <div class="col-md-6">
              <label for="localidad" class="combo">Localidad</label>
              <div class="form-group">
                <ng-select class="custom" [clearable]="false" formControlName="city" name="localidad"
                  dropdownPosition="top">
                  <ng-option *ngFor="let loc of localidades$ | async" value="{{loc.id}}">
                    {{loc.nombre}}
                  </ng-option>
                </ng-select>

              </div>
            </div>
          </div>

        </form>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-right">
        <a class="btn btn-primary" data-toggle="collapse" href="#collapseTwo" role="button" aria-expanded="false"
          aria-controls="Guardar" (click)="saveUser()">
          Guardar
        </a>
      </div>
    </div>
  </div>

  <div class="tab-pane fade" id="addresses-md" role="tabpanel" aria-labelledby="addresses-tab-md">
    <div class="row">
      <div class="col-md-12 scroller">
        <app-addresses #addresses class="main-address-container"></app-addresses>
      </div>
    </div>
  </div>

  <div class="tab-pane fade" id="password-md" role="tabpanel" aria-labelledby="password-tab-md">
    <div class="row">
      <div class="col-md-12 form-content">
        <form [formGroup]="formPassword" id="formPassword" accept-charset="UTF-8" novalidate>
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="currentPass">Contraseña actual *</label>
                <input type="password" id="currentPass" formControlName="currentPassword" class="form-control"
                  required="required" [ngClass]="{ 'is-invalid': passFormIsInvalid('currentPassword')}"
                  autocomplete="off" />

                <div class="error-message" *ngIf="passFormHasError('required', 'currentPassword')">
                  Debe ingresar la contraseña actual!
                </div>
              </div>
            </div>
          </div><!-- Contraseña actual -->

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="newPassword">Nueva contraseña *</label>
                <input type="password" id="newPassword" formControlName="password" class="form-control"
                  required="required" [ngClass]="{ 'is-invalid': passFormIsInvalid('password')}" autocomplete="off" />

                <div class="error-message" *ngIf="passFormHasError('required', 'password')">
                  Debe ingresar una nueva contraseña!
                </div>
                <div class="error-message"
                  *ngIf="!passFormHasError('required', 'password') && passFormHasError('minlength', 'password')">
                  Las contraseñas debe tener al menos 8 caracteres!
                </div>
                <div class="error-message"
                  *ngIf="!passFormHasError('required', 'password') && !passFormHasError('minlength', 'password') && passFormHasError('passwordStrength', 'password')">
                  Las contraseña debe incluir letras mayúsculas y minúsculas, números y caracteres especiales.
                </div>
              </div>
            </div>
          </div><!-- Nueva contraseña -->

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="repeatPassword">Repetir contraseña *</label>
                <input type="password" id="repeatPassword" formControlName="passwordConfirm" class="form-control"
                  required="required" [ngClass]="{ 'is-invalid': passFormIsInvalid('passwordConfirm')}"
                  autocomplete="off" />

                <div class="error-message" *ngIf="passFormHasError('required', 'passwordConfirm')">
                  Debe confirmar la nueva contraseña!
                </div>
                <div class="error-message"
                  *ngIf="!passFormHasError('required', 'passwordConfirm') && passFormHasError('passwordsNotMatching', 'passwordConfirm')">
                  Las contraseñas no coinciden!
                </div>
              </div>
            </div>
          </div><!-- Contraseña actual -->
        </form>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12 text-right">
        <a class="btn btn-primary" data-toggle="collapse" href="#collapseTwo" role="button" aria-expanded="false"
          aria-controls="Actualizar" (click)="changePassword()">
          Actualizar
        </a>
      </div>
    </div>
  </div>
</div>

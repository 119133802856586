<app-terms-and-conditions #termsAndConditions></app-terms-and-conditions>

    <!-- Footer -->
    <footer class="position-relative pt-5 mt-5">
      <div class="container pt-4">
          <div class="row">
              <div class="col">
                  <a href="#" id="logo_footer"><img src="assets/img/logo-footer.svg"></a>
              </div>
          </div>
          <div class="row mt-5 mt-md-4">
              <div class="col-md-3">
                  <h4 class="font-weight-normal"><b class="text-primary text-uppercase font-weight-bold">Horario de atención</b> <br>
                      Lun. a Vier. 10:00 a 18:00 hs <br><br>
                      <b class="text-primary font-weight-bold">E-mail:</b> <a class="font-weight-bold"  href="mailto:info@ues.com.uy">info@ues.com.uy</a> <br>
                      <b class="text-primary font-weight-bold">Dirección:</b> Av. Burgues 2825</h4>
              </div>
              <div class="col-md-6 mt-5 mt-md-0">
                  <div class="row">
                      <ul class="list-unstyled col-md">
                          <li><a class="font-weight-bold" href="https://ues.com.uy/nuestra_empresa.html">Quiénes somos</a></li>
                          <li class="mt-2"><a class="font-weight-bold" routerLink="/section-puntos-xpres">Dónde estamos</a></li>
                          <li class="mt-2"><a class="font-weight-bold" href="#;" (click)="showConditions()">Términos de uso</a></li>
                      </ul>
                  </div>
              </div>
              <div class="col-md-3 social_module mt-5 mt-md-0">
                  <h4 class="text-primary text-uppercase"><b>Seguinos en:</b></h4>
                  <ul class="list-unstyled d-flex">
                      <li class="mx-1"><a href="https://web.facebook.com/uesuruguay"><img src="assets/img/facebook.svg"></a></li>
                      <li class="mx-1"><a href="http://twitter.com/uesuruguay"><img src="assets/img/twitter.svg"></a></li>
                      <li class="mx-1">
                          <a href="https://instagram.com/uesuruguay" class="d-flex justify-content-center align-items-center bg-secondary" style="width: 31px; height: 31px; border-radius: 50%">
                              <svg style="height: 22px" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" ><path fill="white" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" class=""></path></svg>
                          </a>
                      </li>
                      <li class="mx-1"><a href="https://uy.linkedin.com/company/uesuruguay"><img src="assets/img/lin_white.svg"></a></li>
                  </ul>
              </div>
          </div>
      </div>
      <h6 class="terms text-center py-4 m-0"><small>Todos los derechos reservados 2021 © UES</small></h6>
  </footer>
<!-- <footer class="site-footer">
  <div class="container">
    <div class="row">
      <div class="col-md-9">
        <div class="row">
          <div class="col-md-4 mr-auto">
            <h2 class="footer-heading mb-4">Una Asociación De</h2>
            <div class="logos-container">
              <img class="logo" src="../../../../assets/images/logos/ues.png" alt="UES" />
              <img class="logo" src="../../../../assets/images/logos/intersiglo.png" alt="Intersiglo" />
            </div>
          </div>

          <div class="col-md-4">
            <h2 class="footer-heading mb-4">Secciones</h2>
            <ul class="list-unstyled">
              <li><a href="#" (click)="goToSection($event, 'section-como-funciona')">Como Funciona</a></li>
              <li><a href="#" (click)="goToSection($event, 'section-puntos-xpres')">Puntos Xpres!</a></li>
              <li><a href="#" (click)="goToSection($event, 'section-servicios')">Servicios</a></li>
              <li><a href="#" (click)="goToSection($event, 'section-contact')">Contacto</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            &nbsp;
            

          </div>
        </div>
      </div>
      <div class="col-md-3">
        <h2 class="footer-heading mb-4">Enviar Paquete</h2>
        <button class="btn btn-primary text-white" type="button" id="button-addon2" (click)="crearEnvio()">Crear
          envío</button>
      </div>
    </div>
    <div class="row pt-5 mt-5 text-center">
      <div class="col-md-12">
        <div class="border-top pt-5">
          <p>
            <small class="block">&copy;
              <script>document.write(new Date().getFullYear());</script> Xpres! Todos los derechos reservados. <br>
            </small>
          </p>
        </div>
      </div>

    </div>
  </div>
</footer> -->
<!-- <app-login></app-login> -->
<!-- <app-asistente-envio #asistente></app-asistente-envio> -->

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./page-layout.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../header/header.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "../header/header.component";
import * as i5 from "../../../shared/services/responsive.service";
import * as i6 from "../../../shared/auth/auth.service";
import * as i7 from "@angular/router";
import * as i8 from "../../auth/login/login.service";
import * as i9 from "../../envio/envio.service";
import * as i10 from "../../../shared/services/scroll.service";
import * as i11 from "../../../shared/services/header-menu.service";
import * as i12 from "../../asistente/asistente.service";
import * as i13 from "../../home/home.service";
import * as i14 from "../../../shared/services/notification.service";
import * as i15 from "../footer/footer.component.ngfactory";
import * as i16 from "../footer/footer.component";
import * as i17 from "../../auth/login/login.component.ngfactory";
import * as i18 from "../../auth/login/login.component";
import * as i19 from "@angular/forms";
import * as i20 from "../../user/user.service";
import * as i21 from "../../asistente/asistente.component.ngfactory";
import * as i22 from "../../asistente/asistente.component";
import * as i23 from "ngx-bootstrap/modal";
import * as i24 from "../../../shared/services/modal-data.service";
import * as i25 from "../../user/user/user.component.ngfactory";
import * as i26 from "../../user/user/user.component";
import * as i27 from "../../mis-paquetes/mis-paquetes.component.ngfactory";
import * as i28 from "../../mis-paquetes/mis-paquetes.component";
import * as i29 from "../../admin/localidades/localidades.component.ngfactory";
import * as i30 from "../../admin/localidades/localidades.component";
import * as i31 from "../../admin/pickup-centers/pickup-centers.component.ngfactory";
import * as i32 from "../../admin/pickup-centers/pickup-centers.component";
import * as i33 from "./page-layout.component";
var styles_PageLayoutComponent = [i0.styles];
var RenderType_PageLayoutComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PageLayoutComponent, data: {} });
export { RenderType_PageLayoutComponent as RenderType_PageLayoutComponent };
export function View_PageLayoutComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { resetPassword: 0 }), i1.ɵqud(402653184, 2, { login: 0 }), i1.ɵqud(402653184, 3, { asistente: 0 }), i1.ɵqud(402653184, 4, { user: 0 }), i1.ɵqud(402653184, 5, { misPaquetes: 0 }), i1.ɵqud(402653184, 6, { localidades: 0 }), i1.ɵqud(402653184, 7, { centros: 0 }), (_l()(), i1.ɵeld(7, 0, null, null, 3, "app-header", [], null, [[null, "showProfile"], [null, "showPackages"], [null, "showAdminLocalidades"], [null, "login"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("showProfile" === en)) {
        var pd_0 = (_co.showProfile() !== false);
        ad = (pd_0 && ad);
    } if (("showPackages" === en)) {
        var pd_1 = (_co.showPackages() !== false);
        ad = (pd_1 && ad);
    } if (("showAdminLocalidades" === en)) {
        var pd_2 = (_co.showAdminLocalidades() !== false);
        ad = (pd_2 && ad);
    } if (("login" === en)) {
        var pd_3 = (i1.ɵnov(_v, 16).show() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_HeaderComponent_0, i2.RenderType_HeaderComponent)), i1.ɵdid(8, 278528, null, 0, i3.NgClass, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(9, { authenticated: 0 }), i1.ɵdid(10, 245760, null, 0, i4.HeaderComponent, [i5.ResponsiveService, i6.AuthService, i1.ChangeDetectorRef, i7.ActivatedRoute, i8.LoginService, i9.EnvioService, i10.ScrollService, i11.HeaderMenuService, i12.AsistenteService, i13.HomeService, i14.NotificationService], null, { showProfile: "showProfile", showPackages: "showPackages", showAdminLocalidades: "showAdminLocalidades", login: "login" }), (_l()(), i1.ɵeld(11, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(12, 212992, null, 0, i7.RouterOutlet, [i7.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(13, 0, null, null, 1, "app-footer", [], null, null, null, i15.View_FooterComponent_0, i15.RenderType_FooterComponent)), i1.ɵdid(14, 245760, null, 0, i16.FooterComponent, [i5.ResponsiveService, i6.AuthService, i1.ChangeDetectorRef, i8.LoginService, i10.ScrollService, i12.AsistenteService], null, null), (_l()(), i1.ɵeld(15, 0, null, null, 1, "app-login", [], null, null, null, i17.View_LoginComponent_0, i17.RenderType_LoginComponent)), i1.ɵdid(16, 245760, [[2, 4], ["login", 4]], 0, i18.LoginComponent, [i19.FormBuilder, i6.AuthService, i14.NotificationService, i20.UsuarioService, i8.LoginService, i9.EnvioService], null, null), (_l()(), i1.ɵeld(17, 0, null, null, 1, "app-asistente-envio", [], null, [[null, "open"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("open" === en)) {
        var pd_0 = (_co.onOpenEnvio($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i21.View_AsistenteComponent_0, i21.RenderType_AsistenteComponent)), i1.ɵdid(18, 245760, [[3, 4], ["asistente", 4]], 0, i22.AsistenteComponent, [i6.AuthService, i23.BsModalService, i24.ModalDataService, i9.EnvioService, i7.Router, i5.ResponsiveService, i12.AsistenteService, i8.LoginService], null, { open: "open" }), (_l()(), i1.ɵeld(19, 0, null, null, 1, "app-user", [], null, null, null, i25.View_UserComponent_0, i25.RenderType_UserComponent)), i1.ɵdid(20, 245760, [[4, 4], ["user", 4]], 0, i26.UserComponent, [i6.AuthService, i23.BsModalService, i5.ResponsiveService], null, null), (_l()(), i1.ɵeld(21, 0, null, null, 1, "app-mis-paquetes", [], null, [[null, "open"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("open" === en)) {
        var pd_0 = (_co.onOpenMisPaquetes($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i27.View_MisPaquetesComponent_0, i27.RenderType_MisPaquetesComponent)), i1.ɵdid(22, 245760, [[5, 4], ["misPaquetes", 4]], 0, i28.MisPaquetesComponent, [i6.AuthService, i23.BsModalService, i5.ResponsiveService], null, { open: "open" }), (_l()(), i1.ɵeld(23, 0, null, null, 1, "app-localidades", [], null, [[null, "open"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("open" === en)) {
        var pd_0 = (_co.onOpenLocalidades($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i29.View_LocalidadesComponent_0, i29.RenderType_LocalidadesComponent)), i1.ɵdid(24, 245760, [[6, 4], ["localidades", 4]], 0, i30.LocalidadesComponent, [i6.AuthService, i23.BsModalService, i5.ResponsiveService], null, { open: "open" }), (_l()(), i1.ɵeld(25, 0, null, null, 1, "app-pickup-centers", [], null, [[null, "open"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("open" === en)) {
        var pd_0 = (_co.onOpenCentros($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i31.View_PickupCentersComponent_0, i31.RenderType_PickupCentersComponent)), i1.ɵdid(26, 245760, [[7, 4], ["centros", 4]], 0, i32.PickupCentersComponent, [i6.AuthService, i23.BsModalService, i5.ResponsiveService], null, { open: "open" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 9, 0, _co.isAuthenticated()); _ck(_v, 8, 0, currVal_0); _ck(_v, 10, 0); _ck(_v, 12, 0); _ck(_v, 14, 0); _ck(_v, 16, 0); _ck(_v, 18, 0); _ck(_v, 20, 0); _ck(_v, 22, 0); _ck(_v, 24, 0); _ck(_v, 26, 0); }, null); }
export function View_PageLayoutComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-page-layout", [], null, null, null, View_PageLayoutComponent_0, RenderType_PageLayoutComponent)), i1.ɵdid(1, 245760, null, 0, i33.PageLayoutComponent, [i6.AuthService, i7.Router, i14.NotificationService, i7.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PageLayoutComponentNgFactory = i1.ɵccf("app-page-layout", i33.PageLayoutComponent, View_PageLayoutComponent_Host_0, {}, {}, []);
export { PageLayoutComponentNgFactory as PageLayoutComponentNgFactory };

<div id="main-container">
    <div class="envio-status" *ngIf="!cargandoDatos">
        <div class="row">
            <div class="col-md-8">
                <div class="row info-container" *ngIf="data.sender.state">
                    <div class="col">
                        <h5>
                            <span class="section-title">Remitente: </span>
                            <span class="name">{{data.sender.name}}</span>
                        </h5>
                        <div class="text-container pl-5">
                            <i class="fa fa-envelope"></i>
                            <div>{{data.sender.email}}</div>
                        </div>
                        <div class="text-container pl-5">
                            <i class="fa fa-phone"></i>
                            <div>{{data.sender.phoneNumber}}</div>
                        </div>
                        <div class="text-container pl-5">
                            <i class="fa fa-map-marker"></i>
                            <div>{{data.sender.state.description}} - {{data.sender.city.description}}</div>
                        </div>
                    </div>
                </div>
                <div class="row info-container pt-3" *ngIf="data.recipient.state">
                    <div class="col">
                        <h5>
                            <span class="section-title">Destinatario: </span>
                            <span class="name">{{data.recipient.name}}</span>
                        </h5>
                        <div class="text-container pl-5">
                            <i class="fa fa-envelope"></i>
                            <div>{{data.recipient.email}}</div>
                        </div>
                        <div class="text-container pl-5">
                            <i class="fa fa-phone"></i>
                            <div>{{data.recipient.phoneNumber}}</div>
                        </div>
                        <div class="text-container pl-5">
                            <i class="fa fa-map-marker"></i>
                            <div>
                                <ng-container *ngIf="data.recipient.pickupCenter">
                                    {{data.recipient.pickupCenter.description}}<br>
                                </ng-container>
                                <ng-container *ngIf="!data.recipient.pickupCenter">
                                    {{data.recipient.street}} {{data.recipient.doorNumber}}
                                    {{data.recipient.otherInfo}}<br>
                                </ng-container>
                                {{data.recipient.state.description}} - {{data.recipient.city.description}}
                                <ng-container *ngIf="!data.recipient.comments">
                                    <br>{{data.recipient.comments}}
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row info-container pt-3" *ngIf="data.shipmentDetails.service">
                    <div class="col">
                        <h5>
                            <span class="section-title">Envío </span>
                        </h5>
                        <div class="text-container pl-5">
                            <i class="fa fa-archive"></i>
                            <div>
                                {{data.shipmentDetails.category.nombre}}<br> {{data.shipmentDetails.service.nombre}}
                                <ng-container *ngIf="data.shipmentDetails.comments">
                                    <br>{{data.shipmentDetails.comments}}
                                </ng-container>
                                <ng-container *ngIf="data.shipmentDetails.reference">
                                    <br>Referencia: {{data.shipmentDetails.reference}}
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row info-container pt-3" *ngIf="data.payment">
                    <div class="col">
                        <h5>
                            <span class="section-title pago_mobile">Pago: </span>
                            <span class="section-title pago_desktop">Información de pago: </span>
                            <span class="name">{{data.payment.name}} {{data.payment.surname}}</span>
                        </h5>
                        <div class="text-container pl-5">
                            <i class="fa fa-envelope"></i>
                            <div>
                                {{data.payment.email}}
                            </div>
                        </div>
                        <div class="text-container pl-5">
                            <i class="fa fa-credit-card"></i>
                            <div>
                                <span class="importe">$U {{data.payment.amount | number:'1.2-2'}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 pb-4 d-flex flex-column">
                <div class="row flex-grow-1">
                    <div class="col text-center loader-container" *ngIf="integrando">
                        <div class="fa fa-circle-o-notch fa-spin"></div>
                        <span style="font-size: small"><br>Registrando paquete.<br>Espere por favor . . .</span>
                    </div>

                    <div class="col text-center printer-container" *ngIf="!integrando">
                        <div class="fa fa-barcode"></div>
                        <a *ngIf="integrationInfo" class="btn btn-primary btn-sm" [href]="integrationInfo.etiquetaURL"
                            target="_new">
                            Imprimir etiqueta
                        </a>
                    </div>
                </div>
                <div class="row flex-grow-1 align-items-end">
                    <div class="col text-center">
                        <ng-container *ngIf="paramStatus==='0' && collectionStatus==='approved'">
                            <span>Pago efectuado exitosamente!</span><br>
                            <span>Tipo: {{getPaymentMethod()}}</span><br>
                            <span>Número de orden: {{merchantOrderId}}</span>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="accordion" id="accordionExample" *ngIf="cargandoDatos">
        <div class="card">
            <div class="card-header" id="headingOne">
                <div class="row" [ngClass]="{'invalid-card': step1Invalid}">
                    <div class="col text-left">
                        <h5 class="mb-0">
                            <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne"
                                aria-expanded="true" aria-controls="collapseOne">
                                Remitente
                            </button>
                        </h5>
                    </div>
                    <div class="col text-right d-none d-sm-block">
                        <span class="error-header">
                            Datos incorrectos o incompletos <i class="fa fa-exclamation-triangle"></i>
                        </span>
                    </div>
                </div>
            </div>

            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample"
                [ngClass]="{show: firstTabSelected === 1}">
                <div class="card-body">

                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <form [formGroup]="senderForm" id="senderForm" accept-charset="UTF-8" novalidate>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="senderName">Nombre completo *</label>
                                                <input type="text" id="senderName" formControlName="name"
                                                    class="form-control" placeholder="Nombre" required="required"
                                                    maxlength="200"
                                                    [ngClass]="{ 'is-invalid': senderIsInvalid('name')}" />

                                                <div class="error-message" *ngIf="senderHasError('required', 'name')">
                                                    El nombre es obligatorio!
                                                </div>

                                                <div class="error-message"
                                                    *ngIf="!senderHasError('required', 'name') && senderHasError('pattern', 'name')">
                                                    El nombre no puede ser solo espacios en blanco
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Fin name -->

                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label for="senderEmail">Dirección de email *</label>
                                                <input type="email" id="senderEmail" formControlName="email"
                                                    class="form-control" placeholder="Email" required="required"
                                                    maxlength="100"
                                                    [ngClass]="{ 'is-invalid': senderIsInvalid('email')}" />

                                                <div class="error-message" *ngIf="senderHasError('required', 'email')">
                                                    La dirección de email es obligatoria!
                                                </div>
                                                <div class="error-message"
                                                    *ngIf="!senderHasError('required', 'email') && senderHasError('email', 'email')">
                                                    La dirección de email no es correcta!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="senderPhone">Número de teléfono *</label>
                                                <input type="tel" id="senderPhone" formControlName="phone"
                                                    class="form-control" placeholder="Teléfono" required="required"
                                                    maxlength="30"
                                                    [ngClass]="{ 'is-invalid': senderIsInvalid('phone')}" />

                                                <div class="error-message" *ngIf="senderHasError('required', 'phone')">
                                                    El teléfono es obligatorio!
                                                </div>

                                                <div class="error-message"
                                                    *ngIf="!senderHasError('required', 'phone') && senderHasError('pattern', 'phone')">
                                                    El teléfono no puede ser solo espacios en blanco
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Fin email y telefono -->

                                    <div class="row">
                                        <div class="col-md-6">
                                            <label for="senderDep" class="combo">Departamento *</label>
                                            <div class="form-group">
                                                <ng-select class="custom" [clearable]="false"
                                                    (change)="onChangeDepOrigen($event)" formControlName="state"
                                                    name="senderDep" dropdownPosition="top" required
                                                    [ngClass]="{ 'is-invalid': senderIsInvalid('state')}">
                                                    <ng-option *ngFor="let dep of departamentos$ | async"
                                                        value="{{dep.id}}">
                                                        {{dep.nombre}}
                                                    </ng-option>
                                                </ng-select>
                                                <div class="error-message" *ngIf="senderHasError('required', 'state')">
                                                    El departamento es obligatorio!
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-6">
                                            <label for="senderLoc" class="combo">Localidad *</label>
                                            <div class="form-group">
                                                <ng-select class="custom" [clearable]="false" formControlName="city"
                                                    name="senderLoc" dropdownPosition="top" required
                                                    [ngClass]="{ 'is-invalid': senderIsInvalid('city')}">
                                                    <ng-option *ngFor="let loc of localidadesOrigen$ | async"
                                                        value="{{loc.id}}">
                                                        {{loc.nombre}}
                                                    </ng-option>
                                                </ng-select>
                                                <div class="error-message" *ngIf="senderHasError('required', 'city')">
                                                    La localidad es obligatoria!
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-12 text-right controller">
                                <a class="btn btn-secondary btn-sm" data-toggle="collapse" href="#collapseTwo"
                                    role="button" aria-expanded="false" aria-controls="siguiente">
                                    <span>Siguiente</span>
                                    <i class="fa fa-chevron-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header" id="headingTwo">
                <div class="row" [ngClass]="{'invalid-card': step2Invalid}">
                    <div class="col text-left">
                        <h5 class="mb-0">
                            <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseTwo"
                                aria-expanded="false" aria-controls="collapseTwo">
                                Destinatario
                            </button>
                        </h5>
                    </div>
                    <div class="col text-right d-none d-sm-block">
                        <span class="error-header">
                            Datos incorrectos o incompletos <i class="fa fa-exclamation-triangle"></i>
                        </span>
                    </div>
                </div>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample"
                [ngClass]="{show: firstTabSelected === 2}">
                <div class="card-body">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <form [formGroup]="recipientForm" id="recipientForm" accept-charset="UTF-8" novalidate>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="recipientName">Nombre completo *</label>
                                                <input type="text" id="recipientName" formControlName="name"
                                                    class="form-control" placeholder="Nombre" required="required"
                                                    maxlength="200"
                                                    [ngClass]="{ 'is-invalid': recipientIsInvalid('name')}" />

                                                <div class="error-message"
                                                    *ngIf="recipientHasError('required', 'name')">
                                                    El nombre es obligatorio!
                                                </div>

                                                <div class="error-message"
                                                    *ngIf="!recipientHasError('required', 'name') && recipientHasError('pattern', 'name')">
                                                    El nombre no puede ser solo espacios en blanco
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Fin name -->

                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label for="recipientEmail">Dirección de email</label>
                                                <input type="email" id="recipientEmail" formControlName="email"
                                                    class="form-control" placeholder="Email" maxlength="100"
                                                    [ngClass]="{ 'is-invalid': recipientIsInvalid('email')}" />

                                                <div class="error-message"
                                                    *ngIf="!recipientHasError('required', 'email') && recipientHasError('email', 'email')">
                                                    La dirección de email no es correcta!
                                                </div>
                                                <div class="error-message"
                                                    *ngIf="recipientHasError('required', 'email')">
                                                    El email es obligatorio!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label for="recipientPhone">Número de teléfono</label>
                                                <input type="tel" id="recipientPhone" formControlName="phone"
                                                    class="form-control" placeholder="Teléfono" maxlength="30" />

                                                <div class="error-message"
                                                    *ngIf="!recipientHasError('required', 'phone') && recipientHasError('pattern', 'phone')">
                                                    El teléfono no puede ser solo espacios en blanco
                                                </div>
                                                <div class="error-message"
                                                    *ngIf="recipientHasError('required', 'phone')">
                                                    El teléfono es obligatorio!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Fin email y telefono -->

                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="recipientAddr" class="combo">Direcciones frecuentes</label>
                                            <div class="form-group">
                                                <ng-select class="custom" (change)="onChangeDireccion($event)"
                                                    formControlName="addresses" name="recipientAddr"
                                                    dropdownPosition="top">
                                                    <ng-option *ngFor="let addr of addresses" value="{{addr.id}}">
                                                        {{addr.nombre}}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="recipientDep" class="combo">Departamento *</label>
                                            <div class="form-group">
                                                <ng-select class="custom" [clearable]="false"
                                                    (change)="onChangeDepDestino($event)" formControlName="state"
                                                    name="recipientDep" dropdownPosition="top" required #recipientDept
                                                    [ngClass]="{ 'is-invalid': recipientIsInvalid('state')}">
                                                    <ng-option *ngFor="let dep of departamentos$ | async"
                                                        value="{{dep.id}}">
                                                        {{dep.nombre}}
                                                    </ng-option>
                                                </ng-select>
                                                <div class="error-message"
                                                    *ngIf="recipientHasError('required', 'state')">
                                                    El departamento es obligatorio!
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <label for="recipientLoc" class="combo">Localidad *</label>
                                            <div class="form-group">
                                                <ng-select class="custom" [clearable]="false" formControlName="city"
                                                    name="recipientLoc" dropdownPosition="top" required
                                                    [ngClass]="{ 'is-invalid': recipientIsInvalid('city')}">
                                                    <ng-option *ngFor="let loc of localidadesDestino$ | async"
                                                        value="{{loc.id}}">
                                                        {{loc.nombre}}
                                                    </ng-option>
                                                </ng-select>
                                                <div class="error-message"
                                                    *ngIf="recipientHasError('required', 'city')">
                                                    La localidad es obligatoria!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Fin de departamento y localidad -->

                                    <div class="row">
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <div class="check-container">
                                                    <label class="form-check-label">
                                                        <input type="checkbox" class="form-check-input"
                                                            formControlName="useXpresCenter">
                                                        <span id="spnCheckXpres">Entregar en centro Xpres!</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <ng-select class="custom" [clearable]="false"
                                                    formControlName="pickupCenter" name="senderXpres"
                                                    dropdownPosition="top"
                                                    [ngClass]="{ 'is-invalid': recipientIsInvalid('pickupCenter')}">
                                                    <ng-option *ngFor="let x of pickupCenters$ | async"
                                                        value="{{x.id}}">
                                                        {{x.nombre}}
                                                    </ng-option>
                                                </ng-select>
                                                <div class="error-message"
                                                    *ngIf="recipientHasError('required', 'pickupCenter')">
                                                    El centro es obligatorio!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Centro Xpres! -->

                                    <div class="row" id="rowDireccion" [ngStyle]="{'display': 'none'}">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label for="recipientAddress">Dirección de entrega *</label>
                                                <input type="text" id="recipientAddress" formControlName="street"
                                                    class="form-control" placeholder="Dirección" maxlength="200"
                                                    [ngClass]="{ 'is-invalid': recipientIsInvalid('street')}" />


                                                <div class="error-message"
                                                    *ngIf="!recipientHasError('required', 'street') && recipientHasError('pattern', 'street')">
                                                    La dirección no puede ser solo espacios en blanco
                                                </div>

                                                <div class="error-message"
                                                    *ngIf="recipientHasError('required', 'street')">
                                                    La dirección es obligatoria!
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="nroPuerta">Nro puerta</label>
                                                <input type="text" id="nroPuerta" formControlName="doorNumber"
                                                    class="form-control" maxlength="20" />

                                                <div class="error-message"
                                                    *ngIf="!recipientHasError('required', 'doorNumber') && recipientHasError('pattern', 'doorNumber')">
                                                    El número de puerta no puede ser solo espacios en blanco
                                                </div>

                                                <div class="error-message"
                                                    *ngIf="recipientHasError('required', 'doorNumber')">
                                                    El número de puerta es obligatorio!
                                                </div>

                                            </div>
                                        </div>

                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label for="adicionalDireccion">Nro apto</label>
                                                <input type="text" id="adicionalDireccion" formControlName="otherInfo"
                                                    class="form-control" maxlength="10" />
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Datos adicionales de la direccion -->


                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="form-group">
                                                <label for="recipientObs">Observaciones</label>
                                                <textarea type="tel" id="recipientObs" formControlName="comments"
                                                    class="form-control" placeholder="Observaciones, esquina, etc"
                                                    rows="1"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- Direccion de entrega -->

                                </form>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-4 text-right">
                                <div class="save-address-container">
                                    <label class="check-save-address">
                                        <input type="checkbox" class="check-save-address"
                                            [(ngModel)]="saveCurrentAddress"
                                            (change)="toggleAddressName(saveCurrentAddress)">
                                        <span class="chk-address-desktop">Guardar como dirección frecuente</span>
                                        <span class="chk-address-mobile">Guardar dirección...</span>
                                    </label>
                                </div>
                            </div>

                            <div class="col-md-4 text-left">
                                <div class="form-group">
                                    <input type="text" id="saveAsName" [disabled]="!saveCurrentAddress"
                                        [(ngModel)]="saveAsName" class="form-control" maxlength="60"
                                        placeholder="Guardar como..." />
                                </div>
                            </div>

                            <div class="col-md-4 text-right controller">
                                <a class="btn btn-secondary btn-sm" data-toggle="collapse" href="#collapseOne"
                                    role="button" aria-expanded="false" aria-controls="anterior">
                                    <i class="fa fa-chevron-left"></i>
                                    <span>Anterior</span>
                                </a>
                                <a class="btn btn-secondary btn-sm" data-toggle="collapse" href="#collapseThree"
                                    role="button" aria-expanded="false" aria-controls="siguiente">
                                    <span>Siguiente</span>
                                    <i class="fa fa-chevron-right"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card">
                <div class="card-header" id="headingThree">
                    <div class="row" [ngClass]="{'invalid-card': step3Invalid}">
                        <div class="col text-left">
                            <h5 class="mb-0">
                                <button class="btn btn-link" type="button" data-toggle="collapse"
                                    data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Envío
                                </button>
                            </h5>
                        </div>
                        <div class="col text-right d-none d-sm-block">
                            <span class="error-header">
                                Datos incorrectos o incompletos <i class="fa fa-exclamation-triangle"></i>
                            </span>
                        </div>
                    </div>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample"
                    [ngClass]="{show: firstTabSelected === 3}">
                    <div class="card-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-12">
                                    <form [formGroup]="shipForm" id="shipForm" accept-charset="UTF-8" novalidate>

                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="shipType" class="combo">Categoría *</label>
                                                    <div class="form-group">
                                                        <ng-select class="custom" [clearable]="false"
                                                            formControlName="category" name="shipCagetory"
                                                            dropdownPosition="top" required #category
                                                            (change)="onChangeCategory($event)"
                                                            [ngClass]="{ 'is-invalid': shipmentIsInvalid('category')}">
                                                            <ng-option *ngFor="let cat of categories$ | async"
                                                                value="{{cat.id}}">
                                                                {{cat.nombre}}
                                                            </ng-option>
                                                        </ng-select>
                                                        <div class="error-message"
                                                            *ngIf="shipmentHasError('required', 'category')">
                                                            La categoría es obligatoria!
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label for="shipType" class="combo">Servicio *</label>
                                                    <div class="form-group">
                                                        <ng-select class="custom disabled" [clearable]="false"
                                                            formControlName="service" [searchable]="false"
                                                            [clearable]="false" name="shipService" #service
                                                            dropdownPosition="top" required
                                                            (change)="onChangeService($event)"
                                                            [ngClass]="{ 'is-invalid': shipmentIsInvalid('service')}">
                                                            <ng-option *ngFor="let ser of servicios$ | async"
                                                                value="{{ser.id}}">
                                                                {{ser.nombre}}
                                                            </ng-option>
                                                        </ng-select>
                                                        <div class="error-message"
                                                            *ngIf="shipmentHasError('required', 'service')">
                                                            El servicio es obligatorio!
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Fin de categoria y servicio -->
                                        </div>

                                        <!-- <div class="row">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label for="shipReference">Referencia *</label>
                          <textarea type="tel" id="shipReference" formControlName="reference" class="form-control"
                            placeholder="Referencia..." required="required" rows="1"
                            [ngClass]="{ 'is-invalid': shipmentIsInvalid('reference')}"></textarea>

                          <div class="error-message" *ngIf="shipmentHasError('required', 'reference')">
                            Debe ingresar alguna referencia
                          </div>
                        </div>
                      </div>
                    </div> -->
                                        <!-- Direccion de entrega -->

                                        <div class="row">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label for="shipObs">Obsevaciones</label>
                                                    <textarea type="tel" id="shipObs" formControlName="comments"
                                                        class="form-control" placeholder="Observaciones"
                                                        rows="1"></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Direccion de entrega -->

                                    </form>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 text-right controller">
                                    <a class="btn btn-secondary btn-sm" data-toggle="collapse" href="#collapseTwo"
                                        role="button" aria-expanded="false" aria-controls="anterior">
                                        <i class="fa fa-chevron-left"></i>
                                        <span>Anterior</span>
                                    </a>
                                    <a class="btn btn-secondary btn-sm" data-toggle="collapse" href="#collapseFour"
                                        role="button" aria-expanded="false" aria-controls="siguiente">
                                        <span>Siguiente</span>
                                        <i class="fa fa-chevron-right"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="headingFour">
                        <div class="row" [ngClass]="{'invalid-card': step4Invalid}">
                            <div class="col text-left">
                                <h5 class="mb-0">
                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                        data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                        Información de pago
                                    </button>
                                </h5>
                            </div>
                            <div class="col text-right d-none d-sm-block">
                                <span class="error-header">
                                    Datos incorrectos o incompletos <i class="fa fa-exclamation-triangle"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour"
                        data-parent="#accordionExample" [ngClass]="{show: firstTabSelected === 4}">
                        <div class="card-body">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-md-12">
                                        <form [formGroup]="paymentForm" id="paymentForm" accept-charset="UTF-8"
                                            novalidate>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="paymentName">Nombre *</label>
                                                        <input type="text" id="paymentName" formControlName="name"
                                                            class="form-control" placeholder="Nombre"
                                                            required="required" maxlength="200"
                                                            [ngClass]="{ 'is-invalid': paymentIsInvalid('name')}" />

                                                        <div class="error-message"
                                                            *ngIf="paymentHasError('required', 'name')">
                                                            El nombre es obligatorio!
                                                        </div>

                                                        <div class="error-message"
                                                            *ngIf="!paymentHasError('required', 'name') && paymentHasError('pattern', 'name')">
                                                            El nombre no puede ser solo espacios en blanco
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label for="paymentSurname">Apellido *</label>
                                                        <input type="text" id="paymentSurname" formControlName="surname"
                                                            class="form-control" placeholder="Apellido"
                                                            required="required" maxlength="200"
                                                            [ngClass]="{ 'is-invalid': paymentIsInvalid('surname')}" />
                                                        <div class="error-message"
                                                            *ngIf="paymentHasError('required', 'surname')">
                                                            El apellido es obligatorio!
                                                        </div>
                                                        <div class="error-message"
                                                            *ngIf="!paymentHasError('required', 'surname') && paymentHasError('pattern', 'surname')">
                                                            El apellido no puede ser solo espacios en blanco
                                                        </div>
                                                        <div
                                                            *ngIf="!paymentHasError('required', 'surname') && !paymentHasError('pattern', 'surname') && senderHasRut() && !paymentHasLastname()">
                                                            Es necesario ingresar un apellido
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group" *ngIf="senderHasRut()">
                                                        <label for="rut">RUT</label>
                                                        <input type="text" id="rut" formControlName="rut"
                                                            class="form-control" placeholder="RUT" required="required"
                                                            maxlength="200"
                                                            [ngClass]="{ 'is-invalid': paymentIsInvalid('rut')}" />
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Fin email y telefono -->

                                            <div class="row">
                                                <div class="col-md-12">
                                                    <div class="form-group">
                                                        <label for="senderEmail">Dirección de email *</label>
                                                        <input type="email" id="senderEmail" formControlName="email"
                                                            class="form-control" placeholder="Email" required="required"
                                                            maxlength="100"
                                                            [ngClass]="{ 'is-invalid': recipientIsInvalid('email')}" />

                                                        <div class="error-message"
                                                            *ngIf="paymentHasError('required', 'email')">
                                                            La dirección de email es obligatoria!
                                                        </div>
                                                        <div class="error-message"
                                                            *ngIf="!paymentHasError('required', 'email') && paymentHasError('email', 'email')">
                                                            La dirección de email no es correcta!
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- Fin email -->

                                            <!-- summary -->
                                            <div *ngIf="tarifaAplicada">
                                                <div class="form-group row mb-0">
                                                    <div class="col-lg-2 text-lg-right summary-label">Servicio:</div>
                                                    <div class="col summary-value">{{tarifaAplicada.servicio}}</div>
                                                </div>
                                                <div class="form-group row mb-0">
                                                    <div class="col-lg-2 text-lg-right summary-label">Destino:</div>
                                                    <div class="col summary-value">{{tarifaAplicada.destino}}</div>
                                                </div>
                                                <div class="form-group row mb-0">
                                                    <div class="col-lg-2 text-lg-right summary-label">Categoría:</div>
                                                    <div class="col summary-value">{{tarifaAplicada.categoria.nombre}}</div>
                                                </div>
                                                <div class="form-group row mb-0">
                                                    <div class="col-lg-2 text-lg-right summary-label">Importe:</div>
                                                    <div class="col summary-value">$U {{tarifaAplicada.importe}}</div>
                                                </div>
                                                <div class="form-group row mb-0">
                                                    <div class="col-lg-2">
                                                        <label class="text-lg-right col-form-label summary-label">Código cupón:</label>
                                                    </div>
                                                    <div class="col-md-7 col-lg-5">
                                                        <div class="input-group input-group-sm">
                                                            <input class="form-control" formControlName="couponCode">
                                                            <div class="input-group-append">
                                                                <button
                                                                    class="btn btn-primary"
                                                                    [disabled]="isCouponRedeemed"
                                                                    (click)="onRedeemCoupon()"
                                                                >
                                                                    Canjear
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div class="error-message" *ngIf="!isValidCouponCode">
                                                            {{coupon.error}}
                                                        </div>
                                                        <small class="form-text text-muted text-left">
                                                            {{coupon.description}}
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- end of summary -->
                                            <div class="col-md-6 text-right">
                                                <div *ngIf="callingMP" class="col text" style="display:inline-block;">
                                                    <div class="fa fa-circle-o-notch fa-spin" style="font-size: 2rem;color:#ccc;"></div>
                                                    <span style="font-size: small">
                                                        <br>Invocando a Mercado Pago
                                                        <br>para terminar la transacción...
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row" *ngIf="integrando">
                                                <div class="col">Integrando...</div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-12 text-right controller">
                                        <a class="btn btn-secondary btn-sm" data-toggle="collapse" href="#collapseThree"
                                            role="button" aria-expanded="false" aria-controls="anterior">
                                            <i class="fa fa-chevron-left"></i>
                                            <span>Anterior</span>
                                        </a>
                                        <a id="btn-finish" class="btn btn-primary btn-sm" (click)="finalizar()"
                                            disabled>
                                            Finalizar
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="login-container" id="login-container" *ngIf="!isAuthenticated()">
        <div class="inner">
            <span class="h5">Es necesario iniciar sesión para utilizar esta funcionalidad.</span><br>
            <!-- <span class="h5">Haga <a href="javascript:void(0)" onclick="openLoginModal();">clic aquí</a> para iniciar
        sesión...</span> -->
        </div>
    </div>
</div>
import { Routes } from '@angular/router';
import { FaqComponent } from './components/faq/faq.component';
import { HomeComponent } from './components/home/home.component';
import { PageLayoutComponent } from './components/layout/page-layout/page-layout.component';
import { Page404Component } from './components/page404/page404.component';
import { Dialogs } from './shared/dialogs.enum';
var ɵ0 = {
    section: 'welcome-page-xpres'
}, ɵ1 = {
    section: 'welcome-page-xpres'
}, ɵ2 = {
    section: 'como-funciona'
}, ɵ3 = {
    section: 'section-puntos-xpres'
}, ɵ4 = {
    section: 'section-servicios'
}, ɵ5 = {
    section: 'contact-form'
}, ɵ6 = {
    dialog: Dialogs.CREAR_ENVIO
};
var routes = [
    {
        path: '',
        component: PageLayoutComponent,
        children: [
            {
                path: '',
                component: HomeComponent,
                data: ɵ0
            },
            {
                path: 'home',
                component: HomeComponent,
                data: ɵ1
            },
            {
                path: 'home/:id/:status',
                component: HomeComponent
            },
            {
                path: 'verify/:verificationId',
                component: HomeComponent
            },
            {
                path: 'forgot/:resetToken',
                component: HomeComponent
            },
            {
                path: 'como-funciona',
                component: HomeComponent,
                data: ɵ2
            },
            {
                path: 'section-puntos-xpres',
                component: HomeComponent,
                data: ɵ3
            },
            {
                path: 'section-servicios',
                component: HomeComponent,
                data: ɵ4
            },
            {
                path: 'section-contact',
                component: HomeComponent,
                data: ɵ5
            },
            {
                path: 'crear-envio',
                component: HomeComponent,
                data: ɵ6
            },
            {
                path: 'faq',
                component: FaqComponent
            }
        ]
    },
    { path: '**', component: Page404Component }
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };

<div class="list-container">
  <div class="destktop-view">
    <div class="containerrrrrr">
      <div class="row">
        <div class="col-md-12">
          <form [formGroup]="searchForm" id="searchForm" accept-charset="UTF-8" novalidate>
            <div class="row">
              <div class="col-md-6">
                <div style="text-align: center;padding-bottom: 15px;">
                  <div style="display: inline-block;padding-right: 10px;">
                    Filtrar:
                  </div>
                  <div style="display: inline-block;">
                    <input type="text" class="form-control serch-box" formControlName="search" name="search"
                      (keyup)="onSearchType($event.target.value)" />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <ng-select class="custom" (change)="onChangeDepartamento($event)"
                    formControlName="state" name="departamento">
                    <ng-option *ngFor="let dep of departamentos$ | async" value="{{dep.id}}">
                      {{dep.nombre}}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="col-md-2 text-right pt-1">
                <a class="btn btn-primary btn-sm" href="#" role="button" (click)="addNew()">
                  Agregar...
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>

      <table class="table table-sm">
        <thead>
          <tr>
            <th scope="col">Código</th>
            <th scope="col">Nombre</th>
            <th scope="col">Departamento</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let localidad of localidades$ | async | paginate: { id: 'server', itemsPerPage: size, currentPage: p, totalItems: total }">
            <td class="codigo" (click)="showDetail(localidad.id)">{{localidad.codigo}}</td>
            <td class="nombre" (click)="showDetail(localidad.id)">{{localidad.nombre}}</td>
            <td class="depto" (click)="showDetail(localidad.id)">{{localidad.departamento.nombre}}</td>
          </tr>
        </tbody>
      </table>

      <pagination-controls class="text-right" (pageChange)="getPage($event)" id="server" maxSize="5"
        directionLinks="false" autoHide="true" responsive="true" previousLabel="Anterior" nextLabel="Siguiente"
        screenReaderPaginationLabel="Paginación" screenReaderPageLabel="página"
        screenReaderCurrentLabel="Está en la página"></pagination-controls>
    </div>
  </div>
  <div class="slider">
    <div class="row">
      <div class="col-md-12 form-content">
        <form [formGroup]="form" id="form" accept-charset="UTF-8" novalidate>
          <div class="row">
            <div class="col-md-3">
              <div class="form-group">
                <label for="codigo">Código</label>
                <input type="text" id="codigo" formControlName="codigo" class="form-control"
                  placeholder="Código" maxlength="10"/>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="nombre">Nombre *</label>
                <input type="text" id="nombre" formControlName="nombre" class="form-control" placeholder="Nombre"
                  required="required" [ngClass]="{ 'is-invalid': formIsInvalid('nombre')}"
                  maxlength="60" />

                <div class="error-message" *ngIf="formHasError('required', 'nombre')">
                  Debe ingresar algún nombre!
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6">
              <label for="departamento" class="combo">Departamento *</label>
              <div class="form-group">
                <ng-select class="custom" [clearable]="false" required
                  formControlName="departamento" name="departamento" id="departamento"
                  [ngClass]="{ 'is-invalid': formIsInvalid('departamento')}">
                  <ng-option *ngFor="let dep of departamentos$ | async" value="{{dep.id}}">
                    {{dep.nombre}}
                  </ng-option>
                </ng-select>

                <div class="error-message" *ngIf="formHasError('required', 'departamento')">
                  El departamento es obligatorio!
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 text-right">
              <a style="color:#fff;" class="btn btn-danger btn-sm mr-5" role="button" aria-controls="Eliminar"
                (click)="deleteLocalidad()" *ngIf="!_isNew">
                Eliminar
              </a>
              <a style="color:#fff;" class="btn btn-secondary btn-sm mr-2" role="button" aria-controls="Cancelar" (click)="hideDetail()">
                Cancelar
              </a>
              <a class="btn btn-primary btn-sm" role="button" aria-controls="Guardar" (click)="saveLocalidad()">
                Guardar
              </a>
            </div>
          </div>

        </form>
      </div>
    </div>
  </div>
</div>

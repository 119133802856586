<app-header [ngClass]="{authenticated: isAuthenticated()}" (showProfile)="showProfile()" (showPackages)="showPackages()" (showAdminLocalidades)="showAdminLocalidades()" (login)="login.show()"></app-header>

<!--
TODO recorte esto
 (showAdminPuntos)="showAdminPuntos()" -->


<router-outlet></router-outlet>

<app-footer></app-footer>

<app-login #login></app-login>
<app-asistente-envio #asistente (open)="onOpenEnvio($event)"></app-asistente-envio>
<app-user #user></app-user>
<app-mis-paquetes #misPaquetes (open)="onOpenMisPaquetes($event)"></app-mis-paquetes>
<app-localidades #localidades (open)="onOpenLocalidades($event)"></app-localidades>
<app-pickup-centers #centros (open)="onOpenCentros($event)"></app-pickup-centers>
<!-- <app-reset-password #resetPassword></app-reset-password> -->
<app-header [ngClass]="{authenticated: isAuthenticated()}" (showProfile)="showProfile()" (showPackages)="showPackages()" (showAdminLocalidades)="showAdminLocalidades()" (login)="login.show()"></app-header>
<!-- 
TODO recorto eso
(showAdminPuntos)="showAdminPuntos()" -->
<app-login #login></app-login>
<app-user #user></app-user>
<app-mis-paquetes #misPaquetes (open)="onOpenMisPaquetes($event)"></app-mis-paquetes>
<app-localidades #localidades (open)="onOpenLocalidades($event)"></app-localidades>
<app-pickup-centers #centros (open)="onOpenCentros($event)"></app-pickup-centers>
<!-- <app-reset-password #resetPassword></app-reset-password> -->



<!-- Welcome -->

<section class="welcome-page-xpres position-relative d-flex justify-content-center flex-column overflow-hidden">
    <div class="container-xl" [class.invisible]="mobileMenuDisplayed" data-wow-delay="1s" data-wow-duration="1s">
        <div class="row">
            <div class="col-12 text-center">
                <h2 class="font-weight-bold text-uppercase mb-0 text-white text-center my-5">
                    tus paquetes dónde y cuándo quieras
                </h2>
            </div>
        </div>
        <div class="d-flex align-items-sm-baseline justify-content-center align-items-center flex-column flex-sm-row">
            <app-tracking class="mx-2"></app-tracking>

            <button class="btn btn-primary btn-lg px-md-5 mx-2 text-nowrap" (click)="crearEnvio()">
        Crear Envío
      </button>
        </div>
    </div>
</section>

<section id="como-funciona" [class.invisible]="mobileMenuDisplayed" class="xpres-how-it-works bg-dark position-relative">
    <h3 class="title text-center text-uppercase text-primary font-weight-bold">
        Cómo funciona
    </h3>

    <div class="container">
        <div class="row">
            <div class="col-md-4">
                <h4 class="font-weight-bold text-white d-flex align-items-center">
                    <span class="number-circle bg-white text-primary d-flex align-items-center justify-content-center shadow mr-2">1</span
          >Crea tu Envío
        </h4>
        <p class="text-white mt-4 mb-5">
          Creá tu envío en el sistema y pagá online. Imprimí la etiqueta y
          pegala en el sobre o paquete.
        </p>
        <ul class="text-white">
          <li>Cómodo</li>
          <li class="mt-3">Seguro</li>
          <li class="mt-3">Flexible</li>
        </ul>
      </div>
      <div class="col-md-4 mt-5 mt-md-0">
        <h4 class="font-weight-bold text-white d-flex align-items-center">
          <span
            class="number-circle bg-white text-primary d-flex align-items-center justify-content-center shadow mr-2"
            >2</span
          >Despacha tu paquete
        </h4>
        <p class="text-white mt-4 mb-5">
          Despachá tu paquete en cualquier punto UESxpres! del país. Hay más de
          90 puntos.
        </p>
        <ul class="text-white">
          <li>Amplia cobertura horaria.</li>
          <li class="mt-3">Disponibilidad de estacionamiento.</li>
          <li class="mt-3">Fácil accesibilidad en todo el país.</li>
          <li class="mt-3">Sin demoras y sin colas.</li>
        </ul>
      </div>
      <div class="col-md-4 mt-5 mt-md-0">
        <h4 class="font-weight-bold text-white d-flex align-items-center">
          <span
            class="number-circle bg-white text-primary d-flex align-items-center justify-content-center shadow mr-2"
            >3</span
          >Rastrea tu paquete
        </h4>
        <p class="text-white mt-4 mb-5">
          Conocé el estado de tu envío en tiempo real realizando un seguimiento
          online de tu paquete.
        </p>
        <ul class="text-white">
          <li>Información en tiempo real.</li>
          <li class="mt-3">Notificaciones en cada paso al destinatario.</li>
          <li class="mt-3">Ubicación geográfica de entrega.</li>
          <li class="mt-3">Accede fácilmente desde tu celular.</li>
        </ul>
      </div>
    </div>
  </div>
</section>

<app-puntos></app-puntos>

<app-tarifas></app-tarifas>

<section id="section-contact" class="xpres-contact container py-5">
  <app-contact></app-contact>
</section>
<app-contact-floating-button
  *ngIf="!mobileMenuDisplayed"
></app-contact-floating-button>

<app-reset-password #resetPassword [token]="resetToken"></app-reset-password>

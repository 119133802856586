<section id="section-puntos-xpres" class="xpres-location location-page">
    <div class="container">
        <div class="row">
            <div class="col-10 mx-auto mx-md-0 col-md-6 col-lg-5 col-xl-4">
                <div class="card shadow-lg overflow-hidden border-0">
                    <h3 class="title text-center text-uppercase text-primary font-weight-bold mb-5 pt-4">DÓNDE ESTAMOS</h3>
                    <div id="list_cities" class="list_cities">
                        <div class="place" *ngFor="let c of centros">
                            <h3>{{c.nombre}}</h3>
                            <h4>{{c.calle}} {{c.doorNumber}}</h4>
                            <div *ngIf="c.autogestion === 1">
                              <div class="totem">
                                <img alt="" src="/assets/img/kiosco_icon.svg">
                                <h4>Tótem/Autogestión</h4>
                                <span class="tooltiptext">Ahora podés despachar tus paquetes y pagar en el momento con débito/crédito en nuestros Tótems de Autogestión.</span>
                              </div>
                            </div>
                            <div *ngIf="c.autogestion === 2">
                              <div class="totem">
                                <img alt="" src="/assets/img/mostrador_icon.svg">
                                <h4>Mostrador</h4>
                                <span class="tooltiptext">Ahora podés despachar tus paquetes y pagar en el momento con débito/crédito a través de nuestro Agente de Atención al Cliente.</span>
                              </div>
                            </div>
                            <a (click)="verCentro(c)">VER INFORMACIÓN</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="position-relative">
        <!-- <div id="map"></div> -->
        <agm-map [fitBounds]="true" class="w-100-p" [scrollwheel]="false" [latitude]="lat" [longitude]="long" [zoom]="zoom">
            <agm-marker *ngFor="let centro of centros" (markerClick)="openWindow(centro.id)" [latitude]="centro.latitude" [longitude]="centro.longitude" [agmFitBounds]="true">
                <agm-snazzy-info-window [maxWidth]="500" [maxHeight]="300" [isOpen]="isInfoWindowOpen(centro.id)" [closeWhenOthersOpen]="true">
                    <ng-template>

                        <div class="popup">
                            <p class="popup-title" style="font-size: 24px; font-weight: 491">{{centro.nombre}}</p>
                            <p class="demo-2" style="font-size: 18px; font-weight: 450">{{centro.calle}} {{centro.doorNumber}} {{centro.comentario}}<br>{{centro.otherInfo}}</p>

                            <div style="width:100%;display: flex;align-items: center;">
                                <table style="width: 100%;">
                                    <thead>
                                        <tr>
                                            <!-- <th colspan="2" class="popup-horario">Horario</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- TODO es aca que tine que cambiar  -->
                                        <tr style="font-size: 16px;font-weight: 400">
                                            {{centro.openHours}}
                                        </tr>
                                        <tr *ngIf="isFarmashop(centro)">
                                          <br>Únicamente se aceptan paquetes de medidas: <b>hasta</b> 1 metro x 60 cm x 60 cm y <b>hasta 25 Kg</b>.
                                        </tr>
                                        <!-- <tr *ngFor=" let day of horario(centro.openHours) ">
                                            <td class="col1 ">{{day[0]}}:</td>
                                            <td class="col2 ">{{day[1]}}</td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </ng-template>
                </agm-snazzy-info-window>
            </agm-marker>
        </agm-map>
        <form [formGroup]="pickerForm ">

            <select class="form-control shadow-sm border-0 position-absolute " formControlName="state" (change)="selectDepartamento($event) ">
          <option *ngFor="let d of departamentos " [value]="d.id ">{{d.nombre}}</option>
  
        </select>
        </form>
    </div>
</section>


<!-- <div class="container " id="puntos-container ">
  <div class="row mb-5 ">

    <div class="col-md-8 ml-auto mb-5 order-md-2 puntos-map pr-0 " data-aos="fade-up " data-aos-delay="100 ">

      <div class="text-center pb-1 border-primary mb-4 list-header " *ngIf="_isMobile ">
        <h2 class="text-primary ">Dónde estamos</h2>
      </div>

      <agm-map [fitBounds]="true " class="w-100-p " style="height:100%; ">
        <agm-marker *ngFor="let centro of centros " (markerClick)="openWindow(centro.id) " [latitude]="centro.latitude "
          [longitude]="centro.longitude " [agmFitBounds]="true ">
          <agm-snazzy-info-window [maxWidth]="500 " [maxHeight]="300 " [isOpen]="isInfoWindowOpen(centro.id) "
            [closeWhenOthersOpen]="true ">
            <ng-template>

              <div class="popup ">
                <p class="popup-title ">{{centro.localidad.nombre}}</p>
                <p class="demo-2 ">{{centro.calle}} {{centro.doorNumber}}<br>{{centro.otherInfo}}</p>

                <div style="width:100%;display: flex;align-items: center; ">
                  <table style="width: 100%; ">
                    <thead>
                      <tr>
                        <th colspan="2 " class="popup-horario ">Horario</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let day of horario(centro.openHours) ">
                        <td class="col1 ">{{day[0]}}:</td>
                        <td class="col2 ">{{day[1]}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-template>
          </agm-snazzy-info-window>
        </agm-marker>
      </agm-map>

      <div class="sliderBox ">
        <button type="button " class="btn btn-info " data-toggle="modal " data-target="#exampleModal4 ">
          <i class="fa fa-chevron-right "></i>
        </button>
      </div>

      <div class="selectorBox ">
        <form [formGroup]="pickerForm " id="pickerForm " accept-charset="UTF-8 " novalidate>
          <div class="form-group ">
            <ng-select class="custom " [clearable]="false " (change)="onChangeXpresDep($event) " formControlName="state "
              name="pickerDep ">
              <ng-option *ngFor="let dep of departamentos " value="{{dep.id}} ">
                {{dep.nombre}}
              </ng-option>
            </ng-select>
          </div>
        </form>
      </div>
    </div>

    <div class="col-md-4 order-md-1 puntos-list " data-aos="fade-up " data-aos-once="true " *ngIf="!_isMobile ">
      <div class="text-center pb-1 border-primary mb-4 list-header ">
        <h2 class="text-primary ">Dónde estamos</h2>
      </div>

      <div class="list-body ">
        <perfect-scrollbar [config]="config " class="list-container ">
          <div class="list-group ">
            <ng-container *ngFor="let centro of centros ">
              <a href="javascript:void(0); " (click)="openWindow(centro.id) " class="list-group-item "
                [ngClass]="{ 'current': isInfoWindowOpen(centro.id)} ">
                <p class="list-group-item-text ">
                  <span class="h4 ">{{centro.localidad.nombre}}</span>
                  {{centro.nombre}}<br>
                  {{centro.calle}} {{centro.doorNumber}}<br>{{centro.otherInfo}}
                </p>
              </a>
            </ng-container>
          </div>
        </perfect-scrollbar>
      </div>
    </div>

  </div>
</div> -->

<div class="modal fade " id="exampleModal4 " tabindex="-1 " role="dialog " aria-labelledby="exampleModalLabel4 " aria-hidden="true ">
    <div class="modal-dialog modal-dialog-slideout modal-lg " role="document ">
        <div class="modal-content ">
            <div class="modal-header ">
                <h5 class="modal-title " id="exampleModalLabel ">Puntos Xpres! {{initialValue.nombre}}</h5>
                <button type="button " class="close " data-dismiss="modal " aria-label="Close ">
          <span aria-hidden="true ">×</span>
        </button>
            </div>
            <div class="modal-body ">
                <perfect-scrollbar [config]="config " class="list-container ">
                    <div class="list-group ">
                        <ng-container *ngFor="let centro of centros ">
                            <a href="javascript:void(0); " (click)="openWindow(centro.id) " class="list-group-item " [ngClass]="{ 'current': isInfoWindowOpen(centro.id)} " data-dismiss="modal ">
                                <h4 class="list-group-item-heading ">{{centro.localidad.nombre}}</h4>
                                <p class="list-group-item-text ">
                                    {{centro.calle}} {{centro.doorNumber}}<br>{{centro.otherInfo}}
                                </p>
                            </a>
                        </ng-container>
                    </div>
                </perfect-scrollbar>
            </div>
            <div class="modal-footer ">
                <button type="button " class="btn btn-primary " data-dismiss="modal ">Cerrar</button>
            </div>
        </div>
    </div>
</div>
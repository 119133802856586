<div class="modal fade login" id="loginModal">
    <div class="modal-dialog login animated">
        <div class="modal-content">
            <div class="modal-header container">
                <div style="width:100%">
                    <div style="width:75%;display: inline-block">
                        <h4 class="modal-title">Ingresar</h4>
                    </div>
                    <div style="width:25%;text-align: right;display:inline-block">
                        <button #close type="button" class="close" data-dismiss="modal" (click)="onClose()" aria-hidden="true">&times;</button>
                    </div>
                </div>
            </div>
            <div class="modal-body">

                <div class="box">
                    <div class="content loginBox">
                        <div class="error"></div>
                        <div class="form loginBox">
                            <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()" accept-charset="UTF-8">

                                <div class="form-group">
                                    <input type="text" formControlName="email" class="form-control" placeholder="Email" [ngClass]="{ 'is-invalid': loginSubmitted && lf.email.errors }" maxlength="100" id="login-email" />
                                    <div *ngIf="lf.email.invalid && (lf.email.dirty || lf.email.touched) && lf.password.touched" class="error-message">

                                        <div class="error-message" *ngIf="loginHasError('required', 'email')">
                                            Es obligatorio ingresar un email
                                        </div>
                                        <div class="error-message" *ngIf="loginHasError('email', 'email') && !loginHasError('required', 'email')">
                                            El formato de email no es correcto
                                        </div>

                                    </div>
                                </div>

                                <div class="form-group">
                                    <input type="password" formControlName="password" class="form-control" placeholder="Contraseña" [ngClass]="{ 'is-invalid': loginSubmitted && lf.password.errors }" />
                                    <div *ngIf="lf.password.invalid && (lf.password.dirty)" class="error-message">

                                        <div class="error-message" *ngIf="loginHasError('required', 'password')">
                                            Es obligatorio ingresar una contraseña
                                        </div>

                                    </div>
                                </div>

                                <div class="form-group">
                                    <!-- .btn-ingresar-form -->
                                    <!-- btn btn-primary btn-login -->
                                    <button [disabled]="loading" class="btn btn-primary btn-ingresar-form">Ingresar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="box">
                    <div class="content registerBox" style="display:none;">
                        <div class="form">
                            <form [formGroup]="registerForm" (ngSubmit)="onRegisterSubmit()" accept-charset="UTF-8">

                                <div class="form-group">
                                    <input type="text" formControlName="name" class="form-control" placeholder="Nombre o empresa" [ngClass]="{ 'is-invalid': registerSubmitted && rf.name.errors }" required maxlength="100" />
                                    <div class="error-message" *ngIf="registerHasError('required', 'name')">
                                        Es obligatorio ingresar un nombre
                                    </div>
                                </div>

                                <div class="form-group">
                                    <input type="text" formControlName="lastName" class="form-control" placeholder="Apellido" maxlength="100" />
                                </div>

                                <div class="form-group">
                                    <input type="email" formControlName="email" class="form-control" placeholder="Email" maxlength="100" [ngClass]="{ 'is-invalid': registerSubmitted && rf.email.errors }" required autocomplete="off" />
                                    <div class="error-message" *ngIf="registerHasError('required', 'email')">
                                        Es obligatorio ingresar un email
                                    </div>
                                    <div class="error-message" *ngIf="registerHasError('email', 'email') && !registerHasError('required', 'email')">
                                        El formato de email no es correcto
                                    </div>
                                </div>

                                <div class="form-group">
                                    <input type="password" formControlName="password" class="form-control" placeholder="Contraseña" [ngClass]="{ 'is-invalid':isInvalid('password') }" required autocomplete="off" />

                                    <div *ngIf="rf.password.invalid && (rf.password.dirty || rf.password.touched)" class="error-message">
                                        <div *ngIf="rf.password.errors?.required">
                                            Debe ingresar una contraseña.
                                        </div>
                                        <div *ngIf="rf.password.errors?.pattern">
                                            La contraseña debe tener un mínimo de 8 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula. Puede tener otros símbolos.
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <input type="password" formControlName="passwordConfirm" class="form-control" placeholder="Repetir contraseña" [ngClass]="{ 'is-invalid': registerSubmitted && rf.passwordConfirm.errors }" />
                                    <div *ngIf="rf.passwordConfirm.invalid && rf.passwordConfirm.touched" class="error-message">
                                        <div *ngIf="rf.passwordConfirm.errors?.required">
                                            Debe repetir la contraseña
                                        </div>
                                        <div *ngIf="rf.passwordConfirm.errors?.passwordsNotMatching">
                                            Las contraseñas ingresadas no coinciden
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <input type="tel" formControlName="phone" class="form-control" placeholder="Teléfono" [ngClass]="{ 'is-invalid': registerSubmitted && rf.phone.errors }" required maxlength="30" />
                                    <div class="error-message" *ngIf="registerHasError('required', 'phone')">
                                        Es obligatorio ingresar un teléfono
                                    </div>
                                </div>

                                <div class="form-group">
                                    <input type="text" formControlName="rut" class="form-control" placeholder="Cédula o RUT" [ngClass]="{ 'is-invalid': registerSubmitted && rf.rut.errors }" required maxlength="32" />
                                    <div class="error-message" *ngIf="registerHasError('required', 'rut')">
                                        Es obligatorio ingresar un número de RUT o CI
                                    </div>
                                </div>

                                <div class="form-group">
                                    <ng-select class="custom" [clearable]="false" formControlName="fiscalType" name="fiscalType" dropdownPosition="top" required placeholder="Tipo fiscal">
                                        <ng-option *ngFor="let tipo of tiposFiscales$ | async" value="{{tipo.id}}">
                                            {{tipo.nombre}}
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="rf.fiscalType.invalid && (rf.fiscalType.dirty || rf.fiscalType.touched)" class="error-message">
                                        <div *ngIf="rf.fiscalType.errors.required">El tipo fiscal es obligatorio</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <ng-select class="custom" [clearable]="false" (change)="onChangeDep($event)" formControlName="state" name="senderDep" dropdownPosition="top" required placeholder="Departamento">
                                        <ng-option *ngFor="let dep of departamentos$ | async" value="{{dep.id}}">
                                            {{dep.nombre}}
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="rf.state.invalid && (rf.state.dirty || rf.state.touched)" class="error-message">
                                        <div *ngIf="rf.state.errors.required">El departamento es obligatorio</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <ng-select class="custom" [clearable]="false" formControlName="city" name="senderLoc" dropdownPosition="top" required placeholder="Localidad">
                                        <ng-option *ngFor="let loc of localidades$ | async" value="{{loc.id}}">
                                            {{loc.nombre}}
                                        </ng-option>
                                    </ng-select>
                                    <div *ngIf="rf.city.invalid && (rf.city.dirty || rf.city.touched)" class="error-message">
                                        <div *ngIf="rf.city.errors.required">La localidad es obligatoria</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <input type="text" formControlName="address" class="form-control" placeholder="Dirección" [ngClass]="{ 'is-invalid': registerSubmitted && rf.address.errors }" required maxlength="1000" />
                                    <div *ngIf="rf.address.invalid && (rf.address.dirty || rf.address.touched)" class="error-message">
                                        <div *ngIf="rf.address.errors.required">La dirección es obligatoria</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <re-captcha formControlName="recaptchaReactive"></re-captcha>
                                    <!-- <re-captcha #captchaRef="reCaptcha" [siteKey]="XPRES_SITE_KEY" required>
                  </re-captcha> -->
                                </div>

                                <div class="form-group pb-0 my-0">
                                    <button [disabled]="loading || registerForm.invalid" class="btn btn-primary btn-crear-cuenta">Crear cuenta</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="box">
                    <div class="content forgotBox" style="display:none;">
                        <div class="form">
                            <form [formGroup]="forgotForm" (ngSubmit)="onForgotSubmit()" accept-charset="UTF-8">

                                <div class="form-group">
                                    <input type="text" formControlName="email" class="form-control" placeholder="Email" [ngClass]="{ 'is-invalid': forgotSubmitted && ff.email.errors }" />
                                    <div *ngIf="forgotSubmitted && ff['email'].errors" class="invalid-feedback">
                                        <div *ngIf="ff['email'].errors.required">El email es obligatorio</div>
                                        <div *ngIf="ff['email'].errors.email">Por favor, ingresa tu Email</div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <!-- btn-forgot TODO CAMBIO ACA -->
                                    <button [disabled]="loading" class="btn btn-primary btn-new-password">Solicitar nueva</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <div class="forgot login-footer">
                    <span>¿Olvidó su contraseña? <a href="javascript: showForgotForm();">Restaurar</a></span><br>
                    <span>¿Desea <a href="javascript: showRegisterForm();">registrarse</a>?</span>
                </div>
                <div class="forgot register-footer" style="display:none">
                    <span>¿Ya posee una cuenta? </span>
                    <a href="javascript: showLoginForm();">Ingresar</a>
                </div>
                <div class="forgot forgot-footer" style="display:none">
                    <span><br>¿Recuerda su contraseña? </span>
                    <a href="javascript: showLoginForm();">Ingresar</a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade show" [class.d-block]="mostrarDialogoRegistroCompleto">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Registro de usuario</h4>
                <button type="button" class="close" (click)="mostrarDialogoRegistroCompleto=false" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">
                <p>Se ha enviado un email de verificación a la dirección de email proporcionada.</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" (click)="mostrarDialogoRegistroCompleto=false">Cerrar</button>
            </div>
        </div>
        <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import XpresUtils from '../xpres.util';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ContactService = /** @class */ (function () {
    function ContactService(http) {
        this.http = http;
        this.backendUrl = (!XpresUtils.isPrivateIP(location.host) ? environment.endpoint : environment.privateEndpoint);
    }
    ContactService.prototype.contactUs = function (m) {
        var url = this.backendUrl + "/contactMessage";
        return this.http.post(url, m);
    };
    ContactService.ngInjectableDef = i0.defineInjectable({ factory: function ContactService_Factory() { return new ContactService(i0.inject(i1.HttpClient)); }, token: ContactService, providedIn: "root" });
    return ContactService;
}());
export { ContactService };

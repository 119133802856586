import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
var AsistenteService = /** @class */ (function () {
    function AsistenteService() {
        this.onShow = new Subject();
        this.onClose = new Subject();
    }
    AsistenteService.prototype.observeShow = function () {
        return this.onShow.asObservable();
    };
    AsistenteService.prototype.observeClose = function () {
        return this.onClose.asObservable();
    };
    AsistenteService.prototype.show = function (envioId) {
        if (envioId === void 0) { envioId = null; }
        this.onShow.next(envioId);
    };
    AsistenteService.prototype.close = function () {
        this.onClose.next();
    };
    AsistenteService.ngInjectableDef = i0.defineInjectable({ factory: function AsistenteService_Factory() { return new AsistenteService(); }, token: AsistenteService, providedIn: "root" });
    return AsistenteService;
}());
export { AsistenteService };

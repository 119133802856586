
<app-terms-and-conditions #termsAndConditions></app-terms-and-conditions>

<section id="section-servicios" class="xpres-prices position-relative">
  <h3 class="title text-center text-uppercase text-primary font-weight-bold mb-4">Tarifas</h3>
  <p class="text-center mb-5">Tarifas impuestos incluidos</p>

  <div class="container" [class.invisible]="mobileMenuDisplayed">
      <div class="row mt-lg-5 justify-content-center" *ngIf="tarifasLoaded">
          <div class="mt-5 mt-lg-0">
              <div class="bg-white shadow-lg rounded py-4 px-2 text-center h-100">
                <h4 class="text-primary font-weight-bold h5">{{tablasTarifas[0].title}}</h4>
        
                  <table class="table table-sm table-borderless mt-5">
                      <thead class="text-secondary">
                          <tr>
                            <th class="align-top" scope="col"></th>
                            <th *ngFor="let col of tablasTarifas[0].rows[0].tipos" class="align-top">
                              <svg *ngIf="col.tipo=='Sobre'" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="" style="width: 24px;"><path fill="#262477" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z" class=""></path></svg>
                              <svg *ngIf="col.tipo!='Sobre'" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="" style="width: 21px;"><path fill="#262477" d="M447.9 176c0-10.6-2.6-21-7.6-30.3l-49.1-91.9c-4.3-13-16.5-21.8-30.3-21.8H87.1c-13.8 0-26 8.8-30.4 21.9L7.6 145.8c-5 9.3-7.6 19.7-7.6 30.3C.1 236.6 0 448 0 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32 0 0-.1-211.4-.1-272zm-97.1-96l42.8 80H278.4l-24-80h96.4zM97.2 80h96.4l-24 80H54.4l42.8-80zM48 432c0-42.3.1-157.9.1-224H160v64c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16v-64h111.9c0 66.1 0 181.8.1 224H48z" class=""></path></svg>
                              <small style="display: block;">{{col.tipo}}</small>
                            </th>
                              <!-- <th class="align-top" scope="col">
                                  <svg focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="" style="width: 24px;"><path fill="#262477" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z" class=""></path></svg>
                              </th>
                              <th class="align-top" scope="col">
                                  <svg focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="" style="width: 21px;"><path fill="#262477" d="M447.9 176c0-10.6-2.6-21-7.6-30.3l-49.1-91.9c-4.3-13-16.5-21.8-30.3-21.8H87.1c-13.8 0-26 8.8-30.4 21.9L7.6 145.8c-5 9.3-7.6 19.7-7.6 30.3C.1 236.6 0 448 0 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32 0 0-.1-211.4-.1-272zm-97.1-96l42.8 80H278.4l-24-80h96.4zM97.2 80h96.4l-24 80H54.4l42.8-80zM48 432c0-42.3.1-157.9.1-224H160v64c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16v-64h111.9c0 66.1 0 181.8.1 224H48z" class=""></path></svg>
                                  <small class="font-weight-bold d-block">(hasta 5KG)</small>
                              </th>
                              <th class="align-top" scope="col">
                                  <svg focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="" style="width: 21px;"><path fill="#262477" d="M447.9 176c0-10.6-2.6-21-7.6-30.3l-49.1-91.9c-4.3-13-16.5-21.8-30.3-21.8H87.1c-13.8 0-26 8.8-30.4 21.9L7.6 145.8c-5 9.3-7.6 19.7-7.6 30.3C.1 236.6 0 448 0 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32 0 0-.1-211.4-.1-272zm-97.1-96l42.8 80H278.4l-24-80h96.4zM97.2 80h96.4l-24 80H54.4l42.8-80zM48 432c0-42.3.1-157.9.1-224H160v64c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16v-64h111.9c0 66.1 0 181.8.1 224H48z" class=""></path></svg>
                                  <small class="font-weight-bold d-block">(hasta 20KG)</small>
                              </th> -->
                          </tr>
                      </thead>
                      <tbody class="text-dark">
                        <tr *ngFor="let row of tablasTarifas[0].rows">
                          <th class="align-top text-right" scope="row">{{row.destino}}</th>
                          <td *ngFor="let col of row.tipos">$U {{col.import}}</td>
                        </tr>

                          <!-- <tr>
                              <th class="text-right" scope="row">Montevideo</th>
                              <td>$U 132</td>
                              <td>$U 180</td>
                              <td>$U 285</td>
                          </tr>
                          <tr>
                              <th class="text-right" scope="row">Interior</th>
                              <td>$U 132</td>
                              <td>$U 180</td>
                              <td>$U 285</td>
                          </tr> -->
                      </tbody>
                  </table>

                  <a (click)="showConditions()" class="text-secondary"><small>Términos y condiciones</small></a>
              </div>
          </div>
      </div>
      <div class="row mt-lg-5 justify-content-center" *ngIf="tarifasLoaded">
        <div class="mt-5 mt-lg-0">
            <div class="bg-white shadow-lg rounded py-3 px-2 text-center h-100">
                <h4 class="text-primary font-weight-bold h5">{{tablasTarifas[1].title}}</h4>
    
                <table class="table table-sm table-borderless mt-5">
                    <thead class="text-secondary">
                    <tr>
                    <th class="align-top" scope="col"></th>
                    <th class="align-top" *ngFor="let col of tablasTarifas[1].rows[0].tipos">
                        <svg *ngIf="col.tipo=='Sobre'" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="" style="width: 24px;"><path fill="#262477" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z" class=""></path></svg>
                        <svg *ngIf="col.tipo!='Sobre'" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="" style="width: 21px;"><path fill="#262477" d="M447.9 176c0-10.6-2.6-21-7.6-30.3l-49.1-91.9c-4.3-13-16.5-21.8-30.3-21.8H87.1c-13.8 0-26 8.8-30.4 21.9L7.6 145.8c-5 9.3-7.6 19.7-7.6 30.3C.1 236.6 0 448 0 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32 0 0-.1-211.4-.1-272zm-97.1-96l42.8 80H278.4l-24-80h96.4zM97.2 80h96.4l-24 80H54.4l42.8-80zM48 432c0-42.3.1-157.9.1-224H160v64c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16v-64h111.9c0 66.1 0 181.8.1 224H48z" class=""></path></svg>
                        <small style="display: block;">{{col.tipo}}</small>
                    </th>
                    <!-- <th class="align-top" scope="col"></th>
                        <th class="align-top" scope="col">
                            <svg focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="" style="width: 24px;"><path fill="#262477" d="M464 64H48C21.49 64 0 85.49 0 112v288c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V112c0-26.51-21.49-48-48-48zm0 48v40.805c-22.422 18.259-58.168 46.651-134.587 106.49-16.841 13.247-50.201 45.072-73.413 44.701-23.208.375-56.579-31.459-73.413-44.701C106.18 199.465 70.425 171.067 48 152.805V112h416zM48 400V214.398c22.914 18.251 55.409 43.862 104.938 82.646 21.857 17.205 60.134 55.186 103.062 54.955 42.717.231 80.509-37.199 103.053-54.947 49.528-38.783 82.032-64.401 104.947-82.653V400H48z" class=""></path></svg>
                        </th>
                        <th class="align-top" scope="col">
                            <svg focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="" style="width: 21px;"><path fill="#262477" d="M447.9 176c0-10.6-2.6-21-7.6-30.3l-49.1-91.9c-4.3-13-16.5-21.8-30.3-21.8H87.1c-13.8 0-26 8.8-30.4 21.9L7.6 145.8c-5 9.3-7.6 19.7-7.6 30.3C.1 236.6 0 448 0 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32 0 0-.1-211.4-.1-272zm-97.1-96l42.8 80H278.4l-24-80h96.4zM97.2 80h96.4l-24 80H54.4l42.8-80zM48 432c0-42.3.1-157.9.1-224H160v64c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16v-64h111.9c0 66.1 0 181.8.1 224H48z" class=""></path></svg>
                            <small class="font-weight-bold d-block">(hasta 5KG)</small>
                        </th>
                        <th class="align-top" scope="col">
                            <svg focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="" style="width: 21px;"><path fill="#262477" d="M447.9 176c0-10.6-2.6-21-7.6-30.3l-49.1-91.9c-4.3-13-16.5-21.8-30.3-21.8H87.1c-13.8 0-26 8.8-30.4 21.9L7.6 145.8c-5 9.3-7.6 19.7-7.6 30.3C.1 236.6 0 448 0 448c0 17.7 14.3 32 32 32h384c17.7 0 32-14.3 32-32 0 0-.1-211.4-.1-272zm-97.1-96l42.8 80H278.4l-24-80h96.4zM97.2 80h96.4l-24 80H54.4l42.8-80zM48 432c0-42.3.1-157.9.1-224H160v64c0 8.8 7.2 16 16 16h96c8.8 0 16-7.2 16-16v-64h111.9c0 66.1 0 181.8.1 224H48z" class=""></path></svg>
                            <small class="font-weight-bold d-block">(hasta 20KG)</small>
                        </th> -->
                    </tr>
                    </thead>
                    <tbody class="text-dark">
                    <tr *ngFor="let row of tablasTarifas[1].rows">
                        <th class="text-right" scope="row">{{row.destino}}</th>
                        <td *ngFor="let col of row.tipos">$U {{col.import}}</td>
                    </tr>
                        <!-- <tr>
                            <th class="text-right" scope="row">Montevideo</th>
                            <td>$U 110</td>
                            <td>$U 150</td>
                            <td>$U 195</td>
                        </tr>
                        <tr>
                            <th class="text-right" scope="row">Interior</th>
                            <td>$U 110</td>
                            <td>$U 150</td>
                            <td>$U 195</td>
                        </tr> -->
                    </tbody>
                </table>
                <a (click)="showConditions()" class="text-secondary"><small>Términos y condiciones</small></a>
            </div>
        </div>
      </div>
      <div class="row mt-lg-5 justify-content-center" *ngIf="tarifasLoaded">
        <div class="mt-5 mt-lg-0">
              <div class="bg-white shadow-lg rounded py-3 px-2 text-center h-100">
                  <h4 class="text-primary font-weight-bold h5">Tiempos de entrega</h4>
                  <table class="table table-sm table-borderless mt-5">
                      <thead class="text-secondary">
                      <tr>
                          <th class="align-top" scope="col"></th>
                          <th *ngFor="let col of tablaTiempos[0].columns">{{col.destino}}</th>
                          <!-- <th class="align-top" scope="col">
                              <svg focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" style="width: 24px;"><path fill="#262477" d="M441.37 192c8.49 0 16.62-4.21 22.63-11.72l43.31-54.14c6.25-7.81 6.25-20.47 0-28.29L464 43.71C458 36.21 449.86 32 441.37 32H280V16c0-8.84-7.16-16-16-16h-16c-8.84 0-16 7.16-16 16v16H56c-13.25 0-24 13.43-24 30v100c0 16.57 10.75 30 24 30h176v32H70.63C62.14 224 54 228.21 48 235.71L4.69 289.86c-6.25 7.81-6.25 20.47 0 28.29L48 372.28c6 7.5 14.14 11.72 22.63 11.72H232v112c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16V384h176c13.25 0 24-13.43 24-30V254c0-16.57-10.75-30-24-30H280v-32h161.37zM432 336H80.44l-25.6-32 25.6-32H432v64zM80 80h351.56l25.6 32-25.6 32H80V80z" class=""></path></svg>
                          </th>
                          <th class="align-top" scope="col">
                              <svg focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" class="" style="width: 24px;"><path fill="#262477" d="M570.24 215.42L323.87 13a56 56 0 0 0-71.75 0L5.76 215.42a16 16 0 0 0-2 22.54L14 250.26a16 16 0 0 0 22.53 2L64 229.71V288h-.31v208a16.13 16.13 0 0 0 16.1 16H496a16 16 0 0 0 16-16V229.71l27.5 22.59a16 16 0 0 0 22.53-2l10.26-12.3a16 16 0 0 0-2.05-22.58zM464 224h-.21v240H352V320a32 32 0 0 0-32-32h-64a32 32 0 0 0-32 32v144H111.69V194.48l.31-.25v-4L288 45.65l176 144.62z" class=""></path></svg>
                          </th> -->
                      </tr>
                      </thead>
                      <tbody class="text-dark">
                        <tr *ngFor="let row of tablaTiempos">
                          <th class="text-right" scope="row">{{row.origen}}</th>
                          <td *ngFor="let col of row.columns">{{col.tiempo}}</td>
                        </tr>
                          <!-- <tr>
                              <th class="text-right" scope="row">Montevideo</th>
                              <td>24 hs</td>
                              <td>24/72 hs</td>
                          </tr>
                          <tr>
                              <th class="text-right" scope="row">Interior</th>
                              <td>72 hs</td>
                              <td>96 hs</td>
                          </tr> -->
                      </tbody>
                  </table>
              </div>
        </div>
      </div>
  </div>

  <p class="text-center text-dark font-weight-bold mt-5"><i>Nota: Aplica impuestos 22% IVA + 10% TP</i></p>
</section>

